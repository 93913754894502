<script>
import _ from 'lodash';

import CliDropdown from '@aws/components/accounts/CliDropdown.vue';
import CodePreview from '@shared/design/CodePreview.vue';
import Panel from '@shared/design/panels/Panel.vue';
import Tabs from '@shared/design/Tabs.vue';

export default {
  components: {
    CliDropdown,
    CodePreview,
    Panel,
    Tabs,
  },
  props: {
    iamDetails: {
      type: Object,
      required: true,
    },
    awsAccountNumber: {
      type: String,
      required: true,
    },
    savingsAnalysisRole: {
      type: Boolean,
      required: false,
      default: false,
    },
    updateRole: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      items: [
        { key: 'aws_console', label: 'AWS Console' },
        { key: 'aws_cli', label: 'AWS CLI' },
        { key: 'terraform', label: 'Terraform' },
        { key: 'cloudformation', label: 'CloudFormation' },
      ],
      activeKey: 'aws_console',
      selectedCli: 'LinuxMacUnix',
    };
  },
  computed: {
    consoleLinkUrl() {
      return _.get(this.iamDetails, 'aws_console_add_role_url');
    },
    consoleUpdateLinkUrl() {
      return _.get(this.iamDetails, 'aws_console_update_role_url');
    },
    selectedResourceText() {
      const mapToVariant = {
        aws_console: 'AWSConsole',
        terraform: 'Terraform',
        cloudformation: 'CloudFormation',
      };
      const filter = { configuration_variant: mapToVariant[this.activeKey] };
      return _.get(_.find(this.variants, filter), 'configuration');
    },
    selectedCommandText() {
      const filter = { configuration_variant: this.selectedCli };
      return _.get(_.find(this.variants, filter), 'configuration');
    },
    variants() {
      if (this.savingsAnalysisRole) {
        return this.iamDetails.initial_configuration_variants;
      }
      return this.iamDetails.configuration_variants;
    },
    headerText() {
      if (this.savingsAnalysisRole) {
        return 'ProsperOps Limited Savings Analysis Access';
      }
      return 'ProsperOps Access';
    },
    createRoleHelpArticleUrl() {
      if (this.savingsAnalysisRole) {
        return 'https://help.prosperops.com/using-the-aws-console-to-configure-prosperops-access';
      }
      return 'https://help.prosperops.com/using-the-aws-console-to-configure-prosperops-access-on-member-accounts';
    },
  },
  methods: {
    onTabChange(key) {
      this.activeKey = key;
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col">
        <Tabs :items="items" :active-key="activeKey" @changed="onTabChange" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 pt-2">
        <Panel>
          <div v-if="activeKey === 'aws_console'">
            <ol v-if="updateRole" class="orderedList">
              <li>Login to the AWS Console for AWS account {{ awsAccountNumber }}</li>
              <li>
                Click
                <BaseButton
                  size="sm"
                  variant="success"
                  class="ml-2 rounded-sm"
                  target="_blank"
                  :disabled="disabled"
                  :href="consoleUpdateLinkUrl"
                >
                  Update IAM Policy
                </BaseButton>
              </li>
              <li>
                Follow this
                <a
                  target="_blank"
                  href="https://help.prosperops.com/using-the-aws-console-to-update-management-account-permissions"
                >
                  help article
                </a>
                to update permissions using this IAM role policy document
              </li>
            </ol>
            <ol v-else class="orderedList">
              <li>Login to the AWS Console for AWS account {{ awsAccountNumber }}</li>
              <li>
                Click
                <BaseButton
                  variant="success"
                  class="ml-2 rounded-sm"
                  target="_blank"
                  :disabled="disabled"
                  :href="consoleLinkUrl"
                >
                  Create IAM Role
                </BaseButton>
              </li>
              <li>
                Follow this
                <a target="_blank" :href="createRoleHelpArticleUrl"> help article </a>
                to complete setup using this IAM role policy document
              </li>
            </ol>
          </div>
          <div v-if="activeKey === 'aws_cli'">
            <p>
              Select your operating system / terminal and then copy, paste, and execute the corresponding AWS CLI
              command(s)
            </p>
          </div>
          <div v-if="activeKey === 'terraform'">
            <p>Add this Terraform to your deployment pipeline</p>
          </div>
          <div v-if="activeKey === 'cloudformation'">
            <p>Add this CloudFormation to your deployment pipeline</p>
          </div>
        </Panel>
      </div>
      <div class="col-lg-9 pt-2">
        <CodePreview
          v-if="activeKey === 'aws_cli'"
          :code-source="activeKey + selectedCli"
          :code-as-text="selectedCommandText"
          :header-text="headerText"
        >
          <template v-slot:filter>
            <CliDropdown v-model="selectedCli" />
          </template>
        </CodePreview>
        <CodePreview v-else :code-source="activeKey" :code-as-text="selectedResourceText" :header-text="headerText">
          <template v-slot:filter> </template>
        </CodePreview>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.orderedList {
  padding-left: 0;
  list-style-position: inside;
}

.orderedList li {
  padding-bottom: 0.5rem;
}
</style>
