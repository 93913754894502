import { UnheadPlugin } from '@unhead/vue/vue2';
import {
  CollapsePlugin,
  DropdownPlugin,
  FormInputPlugin,
  ModalPlugin,
  PaginationPlugin,
  PopoverPlugin,
  TooltipPlugin,
} from 'bootstrap-vue';
import Vue from 'vue';
import LoadScript from 'vue-plugin-load-script';

export function useMiddleware() {
  // Load individual Bootstrap modules still being used, so they can be removed as they are replaced
  Vue.use(CollapsePlugin);
  Vue.use(DropdownPlugin);
  Vue.use(FormInputPlugin);
  Vue.use(ModalPlugin);
  Vue.use(PaginationPlugin);
  Vue.use(PopoverPlugin);
  Vue.use(TooltipPlugin);

  Vue.use(LoadScript);
  Vue.use(UnheadPlugin);
}
