<script lang="ts" setup>
import _ from 'lodash';
import { computed, ref, watch } from 'vue';

export interface Timeframe {
  key: string;
  friendly_name: string;
}
const props = defineProps<{
  selected: Timeframe;
  timeframes: Timeframe[];
}>();

const emits = defineEmits<{
  (e: 'change', newTimeframe: Timeframe): void;
}>();

const current = ref<Timeframe | null>(null);
const startIndex = ref(0);

const pageSize = 6;

const currentPage = computed(() => props.timeframes.slice(startIndex.value, startIndex.value + pageSize));
const canNextPage = computed(() => startIndex.value + pageSize < props.timeframes.length);
const canPrevPage = computed(() => startIndex.value - pageSize >= 0);
const showPagination = computed(() => props.timeframes.length > pageSize);
const spacers = computed(() => (showPagination.value ? _.range(0, currentPage.value.length - pageSize) : []));

// When either the selected timeframe or the timeframes change, recalculate current and startIndex
watch(props, () => setStartIndex(), { immediate: true, deep: true });

function setStartIndex() {
  const index = props.timeframes.findIndex(t => t.key === props.selected.key);
  current.value = props.timeframes[index];
  startIndex.value = Math.floor(index / pageSize) * pageSize;
}

function change(newKey: string) {
  if (newKey !== current.value?.key) {
    const found = props.timeframes.find(t => t.key === newKey)!;
    emits('change', found);
  }
}

function prevPage() {
  startIndex.value = startIndex.value - pageSize;
}
function nextPage() {
  startIndex.value = startIndex.value + pageSize;
}
</script>

<template>
  <b-dropdown
    variant="primary"
    menu-class="rounded-0 mt-1 timeframeMenu"
    toggle-class="btn-no-focus-box-shadow rounded-sm"
    right
    no-caret
    :offset="10"
  >
    <template #button-content>
      <div v-if="current" class="d-flex align-items-center justify-content-center">
        <div>
          {{ current.friendly_name }}
        </div>
        <div class="ml-2">
          <BaseIcon name="angle-down" />
        </div>
      </div>
    </template>
    <b-dropdown-item v-for="timeframe in currentPage" :key="timeframe.key" @click.prevent="change(timeframe.key)">
      <div class="d-flex align-items-center justify-content-end">
        <div>
          <div :class="{ timeframeActive: timeframe.key === selected.key }">
            {{ timeframe.friendly_name }}
          </div>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-text v-for="spacer in spacers" :key="'spacer-' + spacer"> </b-dropdown-text>
    <b-dropdown-form v-if="showPagination" class="border-top paginator mt-3 pt-2">
      <div class="d-flex align-items-center justify-content-between">
        <div class="timeframePaginator">
          <BaseButton
            v-show="canNextPage"
            variant="transparent"
            class="p-0 btn-no-focus-box-shadow timeframePaginatorButton"
            @click="nextPage"
          >
            <small class="text-muted"><BaseIcon name="arrow-left" /></small>
          </BaseButton>
        </div>
        <div class="timeframePaginator">
          <BaseButton
            v-show="canPrevPage"
            variant="transparent"
            class="p-0 btn-no-focus-box-shadow timeframePaginatorButton"
            @click="prevPage"
          >
            <small class="text-muted"><BaseIcon name="arrow-right" /></small>
          </BaseButton>
        </div>
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.timeframePaginator {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25px;
}

.timeframePaginatorButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: $gray-600;
  background-color: $gray-300;
  border-radius: 50%;
}

.timeframeActive {
  border-bottom: 3px solid map-get($theme-colors, 'primary');
}
</style>
