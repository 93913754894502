<script>
import _ from 'lodash';

import ShowbackTableAggregateRow from '@aws/components/adm/showback/ShowbackTableAggregateRow.vue';
import ShowbackTableServiceRow from '@aws/components/adm/showback/ShowbackTableServiceRow.vue';

export default {
  components: {
    ShowbackTableAggregateRow,
    ShowbackTableServiceRow,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    sortKeyLookup() {
      const priority = [
        'EC2',
        'EC2 Unused Capacity Reservations',
        'EC2 Dedicated Hosts',
        'Fargate',
        'Lambda',
        'EC2 Convertible Reserved Instances',
        'EC2 Standard Reserved Instances',
        'Compute Savings Plans',
        'EC2 Instance Savings Plans',
      ];
      const kvByIndex = (acc, curr, i) => ({ ...acc, [curr]: i });
      return _.reduce(priority, kvByIndex, {});
    },
    selectedRows() {
      const defaultSortKey = _.size(this.sortKeyLookup);
      const sortKey = key => _.get(this.sortKeyLookup, key, defaultSortKey);
      const rowBuilder = (rows, key) => ({ key, rows, sort_key: sortKey(key) });
      const rows = _.get(this.row, 'rows', []);
      const unsorted = _.map(_.groupBy(rows, 'service'), rowBuilder);
      return _.orderBy(unsorted, ['sort_key'], ['asc']);
    },
  },
  methods: {
    onClick() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<template>
  <div>
    <BaseButton variant="transparent" class="btn-no-focus-box-shadow w-100 p-0" @click="onClick">
      <ShowbackTableAggregateRow :rows="row.rows" :level="0" :expanded="expanded">
        <template v-slot:label>
          <div :title="row.friendly_name">
            <div class="text-truncate">{{ row.friendly_name }}</div>
            <div>
              <small class="text-muted"> #{{ row.aws_account_number }} </small>
            </div>
          </div>
        </template>
      </ShowbackTableAggregateRow>
    </BaseButton>
    <b-collapse v-model="expanded">
      <ShowbackTableServiceRow v-for="curr in selectedRows" :key="curr.key" :rows="curr.rows">
        <template v-slot:label>
          <div class="text-truncate">
            {{ curr.key }}
          </div>
        </template>
      </ShowbackTableServiceRow>
    </b-collapse>
  </div>
</template>
