<script setup lang="ts">
import { dateFormat, dateUtc } from '@shared/utilities/filters';

const props = withDefaults(
  defineProps<{ value: Date | string | null | undefined; small: boolean; color: 'dark' | 'light' }>(),
  {
    value: null,
    small: true,
    color: 'light',
  }
);

function formatDate(date: Date | string | null | undefined, format = 'MMMM D, YYYY') {
  return dateFormat(dateUtc(date), format);
}
</script>

<template>
  <div
    v-if="props.value"
    class="lastUpdated"
    :class="{ small: props.small, dark: props.color === 'dark', light: props.color === 'light' }"
  >
    Last Updated: {{ formatDate(props.value) }}
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.lastUpdated {
  font-size: 0.95rem;
  font-weight: 400;
  vertical-align: top;
}

.dark {
  color: $gray-800;
}

.light {
  color: $gray-500;
}

.lastUpdated.small {
  font-size: 0.85rem;
  font-weight: 300;
}
</style>
