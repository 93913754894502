<script>
import _ from 'lodash';
import moment from 'moment';

import * as chartUtilities from '@console/components/charts/utility';
import NumberHelpers from '@shared/utilities/number_helpers';
import { isTruthy } from '@shared/utilities/typescript_helpers';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

const seriesColors = {
  compute: {
    AwsComputeBaseFlex: {
      spot_savings: '#A7A3FF',
      flex_boost_savings: '#8fffdf',
      flex_savings: '#00c58c',
      base_savings: '#a5a4bf',
      unbilled_savings: '#dee2e6',
      other_private_rate_savings: '#EBEAFF',
    },
    AwsComputeInheritedBaseSmart: {
      spot_savings: '#A7A3FF',
      smart_savings: '#00c58c',
      base_savings: '#8fffdf',
      unbilled_savings: '#dee2e6',
      inherited_savings: '#a5a4bf',
      other_private_rate_savings: '#EBEAFF',
    },
  },
  nonCompute: {
    inherited_savings: '#a5a4bf',
    smart_savings: '#00c58c',
  },
};

export default {
  components: {
    ChartLegend,
    SeriesChart,
  },
  props: {
    dailySavings: {
      type: Array,
      required: true,
    },
    dashboardVariant: {
      type: String,
      required: false,
      default: undefined,
    },
    monthStart: {
      type: String,
      required: true,
    },
    service: {
      type: String,
      required: true,
      default: 'compute',
    },
    allDiscounts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      series: [],
    };
  },
  computed: {
    isProductCompute() {
      return this.service === 'compute';
    },
    xAxis() {
      const month = moment.utc(this.monthStart).format('MMM');
      const monthAndDay = ({ day }) => `${month} ${day}`;
      const categories = _.map(this.dailySavings, monthAndDay);
      return { categories };
    },
    yAxis() {
      const maxValue = this.dailySavings?.reduce((prev, current) => {
        var totalSavings = this.isProductCompute
          ? current.unbilled_savings + current.flex_boost_savings + current.flex_savings + current.base_saving
          : current.smart_savings + current.inherited_savings;
        return prev > totalSavings ? prev : totalSavings;
      }, 0);
      const decimalPlaces = chartUtilities.decimalPlacesNeededForChartMax(maxValue);

      return [
        {
          min: 0,
          title: {
            text: null,
          },
          labels: {
            format: '<span style="font-size:12px">${value:,.' + decimalPlaces + 'f}</span>', // eslint-disable-line
          },
        },
      ];
    },
    filteredSeries() {
      return _.filter(this.series, s => s.legendSelected);
    },
    tooltip() {
      return chartUtilities.tooltipWithTotal({
        totalLabel: 'Total Savings',
        valueFormatter: v => NumberHelpers.formatDollars(v),
      });
    },
  },
  mounted() {
    // This is the order the items will be stacked in the chart (the first will be on the top)
    const seriesDefs = [
      // These will be filtered based on what colors are defined for the billing offering and varaint
      { name: 'Spot Savings', field: 'spot_savings' },
      { name: 'Smart Savings', field: 'smart_savings' },
      { name: 'Flex Boost Savings', field: 'flex_boost_savings' },
      { name: 'Flex Savings', field: 'flex_savings' },
      { name: 'Base Savings', field: 'base_savings' },
      { name: 'Unbilled Savings', field: 'unbilled_savings' },
      { name: 'Inherited Savings', field: 'inherited_savings' },
      this.allDiscounts && { name: 'Other Private Rate Savings', field: 'other_private_rate_savings' },
    ].filter(isTruthy);

    // Add in the series properties that are the same for all series
    this.series = seriesDefs
      .map((s, index) => ({
        ...s,
        label: s.name,
        type: 'column',
        stacking: 'normal',
        color: this.getSeriesColor(s.field),
        // Use null as the default value to ensure that days without values are still shown on the x axis
        data: this.dailySavings?.map(d => d[s.field] ?? null),
        // Reverse the order of the legend so that the last item (bottom) is the leftmost
        legendOrder: seriesDefs.length - index,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
      }))
      // Don't include any series without a color (i.e. series belongs to another service)
      .filter(s => !!s.color)
      // Remove any series without any data points
      .filter(s => s.data.some(d => !!d));
  },
  methods: {
    getSeriesColor(field) {
      const colors = this.isProductCompute
        ? seriesColors.compute[this.dashboardVariant] ?? {}
        : seriesColors.nonCompute;

      return colors[field];
    },
  },
};
</script>

<template>
  <div>
    <ChartLegend v-model="series" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" :tooltip="tooltip" />
  </div>
</template>
