<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';

import customerService from '@console/services/customerService';

import BaseTarget from '@aws/components/adm/settings/BaseTarget.vue';
import ComputeShrinkAllowancePercentage from '@aws/components/adm/settings/ComputeShrinkAllowancePercentage.vue';
import PrepaymentTable from '@aws/components/adm/settings/PrepaymentTable.vue';
import RequiresRole from '@console/components/RequiresRole.vue';
import SettingRow from '@console/components/settings/SettingRow.vue';
import CalendlyButton from '@console/components/widgets/CalendlyButton.vue';
import Layout from '@console/Layout.vue';
import BadgeSwitch from '@shared/design/BadgeSwitch.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import LastUpdated from '@shared/design/panels/LastUpdated.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';
import StatusPill from '@shared/design/StatusPill.vue';

export default {
  components: {
    BadgeSwitch,
    Layout,
    PageHeader,
    RequiresRole,
    PanelSection,
    CalendlyButton,
    LastUpdated,
    BaseTarget,
    ComputeShrinkAllowancePercentage,
    PrepaymentTable,
    SettingRow,
    StatusPill,
  },
  data() {
    return {
      settings: null,
      budget: null,
      loading: true,
    };
  },
  head: {
    title: 'Compute Settings',
  },
  computed: {
    ...mapGetters('aws', ['selectedOrganization', 'selectedOrganizationRegionDisplayNames']),
    ...mapGetters('customer', ['isReseller']),
    enabledFlexAllUpfront() {
      return !!this.settings.flex_all_upfront_override_days;
    },
    enabledAcceleratedDiscountShift() {
      return !!this.settings.flex_accelerated_discount_shift_months;
    },
    flexAllUpfrontDays() {
      return _.get(this.settings, 'flex_all_upfront_override_days', 40);
    },
    edpExpirationAlignmentDays() {
      const expirationDays = _.get(this.settings, 'base_edp_expiration_alignment_days', 90);
      const unit = expirationDays === 1 ? 'day' : 'days';
      return `${expirationDays} ${unit}`;
    },
    edpExpirationDate() {
      const expirationDate = _.get(this.settings, 'enterprise_discount_program_expiration_date');
      return expirationDate ? ` of ${moment(expirationDate).format('MMMM D, YYYY')}` : '';
    },
    enabledEDPExpirationAlignment() {
      return !!this.settings.base_edp_expiration_alignment_days;
    },
    baseTargetAutoIncreasePercentage() {
      return _.get(this.settings, 'base_target_auto_increase_percentage', 40);
    },
    enabledBaseTargetAutoIncrease() {
      return !!this.settings.base_target_auto_increase_percentage;
    },
    acceleratedDiscountShiftMonths() {
      return _.get(this.settings, 'flex_accelerated_discount_shift_months', 18);
    },
    hasFlexRegionOverride() {
      const key = 'flex_pre_payment_region_preference';
      const preferences = _.get(this.settings, key, []);
      const isOverride = setting => setting.preference !== 'No Upfront';
      return _.some(preferences, isOverride);
    },
    showPrePayment() {
      const block = _.get(this.settings, 'hide_budget', false);

      if (block) {
        return false;
      }

      const base = _.get(this.settings, 'base_pre_payment_preference');
      const flex = _.get(this.settings, 'flex_pre_payment_preference');
      return this.hasFlexRegionOverride || flex !== 'No Upfront' || base !== 'No Upfront';
    },
    regionsOverrides() {
      if (!this.hasFlexRegionOverride) {
        return [];
      }
      const key = 'flex_pre_payment_region_preference';
      const preferences = _.get(this.settings, key, []);
      const displayNames = this.selectedOrganizationRegionDisplayNames;
      return _.map(preferences, p => [displayNames[p.region], p.preference]);
    },
    flexBoostEnabled() {
      return _.get(this.settings, 'enable_flex_boost', false);
    },
  },
  async mounted() {
    const awsOrganization = this.selectedOrganization;
    const responses = await Promise.all([
      customerService.getSettingsSummary(awsOrganization.id),
      customerService.getBudget(awsOrganization.id),
    ]);
    this.settings = responses[0].data;
    this.budget = responses[1].data;
    this.loading = false;
  },
};
</script>

<template>
  <Layout :loading="loading">
    <template #default>
      <div v-if="settings">
        <PageHeader>
          <h1>Compute Settings</h1>
        </PageHeader>
        <div class="row sectional">
          <div class="col">
            <PanelSection header="Status" rounded new-header class="settingsConstrainedWidth">
              <SettingRow class="settingRow">
                <template v-slot:label> Seeding </template>
                <template v-slot:description>
                  Deploy periodic Flex microcommitments in regions with material EC2 spend
                </template>
                <template v-slot:value>
                  <StatusPill :enabled="settings.primary_function_enable_seeding">
                    <span v-if="settings.primary_function_enable_seeding">Enabled</span>
                    <span v-else>Disabled</span>
                  </StatusPill>
                </template>
              </SettingRow>
              <SettingRow class="settingRow">
                <template v-slot:label> Optimizing Existing Commitment </template>
                <template v-slot:description>
                  Optimization of existing commitment and removal of unutilized commitment
                </template>
                <template v-slot:value>
                  <StatusPill :enabled="settings.primary_function_enable_optimizing_existing_commitment">
                    <span v-if="settings.primary_function_enable_optimizing_existing_commitment">Enabled</span>
                    <span v-else>Disabled</span>
                  </StatusPill>
                </template>
              </SettingRow>
              <SettingRow class="settingRow">
                <template v-slot:label> Deploying New Commitment </template>
                <template v-slot:description> Add additional commitment and replace expiring commitment </template>
                <template v-slot:value>
                  <StatusPill :enabled="settings.primary_function_enable_deploying_new_commitment">
                    <span v-if="settings.primary_function_enable_deploying_new_commitment">Enabled</span>
                    <span v-else>Disabled</span>
                  </StatusPill>
                </template>
              </SettingRow>
            </PanelSection>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <PanelSection header="Base" rounded new-header class="settingsConstrainedWidth">
              <template v-slot:utility>
                <LastUpdated :value="settings.base_last_updated" />
              </template>
              <SettingRow class="settingRow">
                <template v-slot:label> Base Target </template>
                <template v-slot:description>
                  Desired amount of Compute Savings Plan spend coverage to maintain (in on-demand equivalent dollars for
                  a 30 day month)
                </template>
                <template v-slot:value>
                  <BaseTarget :settings="settings" />
                </template>
              </SettingRow>
              <SettingRow class="settingRow">
                <template v-slot:label> Base Prepayment Preference </template>
                <template v-slot:description>
                  Upfront prepayment preference for newly deployed Compute Savings Plans
                </template>
                <template v-slot:value>
                  <span v-if="settings.base_pre_payment_preference" class="font-weight-bold">
                    {{ settings.base_pre_payment_preference }}
                  </span>
                  <span v-else> - </span>
                </template>
              </SettingRow>
              <div class="mt-4">
                <span class="text-uppercase font-weight-bold">Advanced</span>
              </div>
              <SettingRow class="settingRow">
                <template v-slot:label>EDP Expiration Alignment</template>
                <template v-slot:description>
                  Do not make new Compute Savings Plan purchases if they extend commitment more than
                  {{ edpExpirationAlignmentDays }} past your EDP expiration date{{ edpExpirationDate }}, and instead
                  cover with term-optimized Flex
                </template>
                <template v-slot:value>
                  <BadgeSwitch :enabled="enabledEDPExpirationAlignment" primary />
                </template>
              </SettingRow>
              <SettingRow class="settingRow">
                <template v-slot:label>Base Target Auto Increase</template>
                <template v-slot:description>
                  When the amount of compute usage that can
                  <strong>only</strong> be covered by Compute Savings Plans increases above the Base Target,
                  automatically increase the Base Target to cover as long as the new target amount is less than
                  {{ baseTargetAutoIncreasePercentage }}% of compute usage
                </template>
                <template v-slot:value>
                  <BadgeSwitch :enabled="enabledBaseTargetAutoIncrease" primary />
                </template>
              </SettingRow>
            </PanelSection>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <PanelSection header="Flex" rounded new-header class="settingsConstrainedWidth">
              <template v-slot:utility>
                <LastUpdated :value="settings.flex_last_updated" />
              </template>
              <SettingRow class="settingRow">
                <template v-slot:label> Compute Shrink Allowance Target </template>
                <template v-slot:description>
                  <template v-if="isReseller">
                    Desired amount of arbitrageable compute usage decline our service should absorb without commitment
                    becoming unutilized
                  </template>
                  <template v-else>
                    Desired amount of compute usage decline our service should absorb without commitment becoming
                    unutilized
                  </template>
                </template>
                <template v-slot:value>
                  <ComputeShrinkAllowancePercentage :settings="settings" class="font-weight-bold" />
                </template>
              </SettingRow>
              <SettingRow class="settingRow">
                <template v-slot:label> Flex Prepayment Preference </template>
                <template v-slot:description>
                  Upfront prepayment preference for newly deployed Convertible RIs
                </template>
                <template v-slot:value>
                  <div v-if="!hasFlexRegionOverride" class="settingValue">
                    <div v-if="settings.flex_pre_payment_preference" class="font-weight-bold">
                      {{ settings.flex_pre_payment_preference }}
                    </div>
                    <div v-else>-</div>
                  </div>
                </template>
              </SettingRow>
              <SettingRow v-for="[region, value] in regionsOverrides" :key="region" class="regionSettingRow">
                <template v-slot:label>
                  <div class="pl-5">
                    {{ region }}
                  </div>
                </template>
                <template v-slot:value>
                  <span class="font-weight-bold">{{ value }}</span>
                </template>
              </SettingRow>
              <div class="mt-4">
                <span class="text-uppercase font-weight-bold">Advanced</span>
              </div>
              <SettingRow v-if="flexBoostEnabled" class="settingRow">
                <template v-slot:label> Flex Boost </template>
                <template v-slot:description>
                  Automatically deploy a layer of 3 year No Upfront Standard RIs to boost Flex savings
                </template>
                <template v-slot:value>
                  <BadgeSwitch :enabled="flexBoostEnabled" primary />
                </template>
              </SettingRow>
              <SettingRow class="settingRow">
                <template v-slot:label> Monthly All Upfront Flex </template>
                <template v-slot:description>
                  Use All Upfront prepayment for new Flex Convertible RI commitment when the remaining term is
                  {{ flexAllUpfrontDays }} days or less (i.e. prepayment is monthly OPEX)
                </template>
                <template v-slot:value>
                  <BadgeSwitch :enabled="enabledFlexAllUpfront" primary />
                </template>
              </SettingRow>
              <SettingRow class="settingRow">
                <template v-slot:label> Accelerated Discount Shift </template>
                <template v-slot:description>
                  Progressively shift 1 year Flex Convertible RI commitment to the closest 3 year discount expiration
                  point (still honoring the Compute Shrink Allowance Target) when the remaining term is
                  {{ acceleratedDiscountShiftMonths }} months or less
                </template>
                <template v-slot:value>
                  <BadgeSwitch :enabled="enabledAcceleratedDiscountShift" primary />
                </template>
              </SettingRow>
            </PanelSection>
          </div>
        </div>
        <div v-if="showPrePayment" class="row sectional">
          <div class="col">
            <PanelSection header="Prepayment" rounded new-header class="settingsConstrainedWidth">
              <p class="text-muted description">
                An All or Partial Upfront Prepayment Preference requires a prepay budget. This budget is established and
                approved quarterly and constrains the maximium amount of new commitment prepayment our service can
                deploy.
              </p>
              <PrepaymentTable :budget="budget" wide />
            </PanelSection>
          </div>
        </div>
        <RequiresRole atleast="Editor">
          <div class="row pt-3">
            <div class="col">
              <div class="updateSettingsConstrainedWidth">
                <p>
                  <strong>Want to update your settings?</strong> We personally review setting adjustments with you to
                  ensure you are safely saving the most possible. Please click the button below to schedule a time to
                  discuss. For urgent questions or updates, please chat with us.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="updateSettingsConstrainedWidth updateButton">
                <CalendlyButton routing-form-id="ckd2-c3j-yqq">
                  <span>Update Settings</span>
                </CalendlyButton>
              </div>
            </div>
          </div>
        </RequiresRole>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.settingsConstrainedWidth {
  max-width: 850px;

  :deep(section) {
    padding: 0.5rem 1.5rem 2rem;
  }
}

.updateSettingsConstrainedWidth {
  max-width: 850px;
}

.updateButton {
  min-height: 500px;
}

.calendly {
  margin-top: -32px;
}

.subheader {
  position: relative;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 500;
}

.subheader::after {
  display: block;
  height: 3px;
  margin-top: 4px;
  content: '';
  background-color: #5c54ff;
  border-radius: 2px;
}

.description {
  font-size: 0.85rem;
}

.regionSettingRow {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.regionSettingRow:first-child {
  padding-top: 0.75rem;
}
</style>
