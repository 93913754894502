<script setup lang="ts">
import confetti from 'canvas-confetti';
import { onBeforeUnmount, onMounted, ref } from 'vue';

import FocusContent from '@console/components/FocusContent.vue';

const confettiDuration = 4;
const confettiInstance = ref();

const displayConfetti = (durationInSeconds: number) => {
  const colors = ['#5c54ff', '#a7a3ff', '#d4d1ff', '#00c58c', '#8fffdf', '#d9fff4', '#fc5454', '#fecbcb', '#fcbe2c'];

  const duration = durationInSeconds * 1000;
  const animationEnd = Date.now() + duration;
  let skew = 1;
  let count = 0;

  const randomInRange = (min: number, max: number) => Math.random() * (max - min) + min;

  confettiInstance.value = setInterval(() => {
    const color = colors[count++ % colors.length];
    const timeLeft = animationEnd - Date.now();
    const ticks = Math.max(200, 500 * (timeLeft / duration));
    skew = Math.max(0.8, skew - 0.001);

    if (timeLeft <= 0) {
      return clearInterval(confettiInstance.value);
    }

    confetti({
      particleCount: 1,
      startVelocity: 0,
      ticks: ticks,
      origin: {
        x: Math.random(),
        y: Math.random() * skew - 0.2,
      },
      colors: [color],
      shapes: ['circle', 'square'],
      gravity: 1.9,
      scalar: randomInRange(0.6, 1.5),
      drift: randomInRange(-1, 1),
    });
  }, 15);
};

onMounted(() => displayConfetti(confettiDuration));

onBeforeUnmount(() => {
  if (confettiInstance.value) {
    clearInterval(confettiInstance.value);
  }
});
</script>

<template>
  <FocusContent>
    <div>
      <h4 class="display-4">You're Subscribed!</h4>
      <p class="lead">
        We'll begin optimizing your savings shortly. If you have any questions at any time, please do not hesitate to
        <router-link :to="{ name: 'help' }">contact us</router-link>.
      </p>
      <BaseButton variant="primary" :to="{ name: 'azure_compute_savings' }" class="rounded-sm">
        Continue to Dashboard
      </BaseButton>
    </div>
  </FocusContent>
</template>
