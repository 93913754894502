<script lang="ts" setup>
import type { ReallocationMethodType } from '@aws/components/adm/showback/ReallocationMethod.vue';
import type { SavingsShareType } from '@aws/components/adm/showback/SavingsShare.vue';

import { defineProps, defineEmits } from 'vue';

import ReallocationMethod from '@aws/components/adm/showback/ReallocationMethod.vue';
import SavingsShare from '@aws/components/adm/showback/SavingsShare.vue';
import DownloadCsvIcon from '@shared/design/icons/DownloadCsvIcon.vue';

interface ShowbackFilters {
  reallocation_method: ReallocationMethodType;
  savings_share: SavingsShareType;
}
const props = defineProps<{
  filters: ShowbackFilters;
  demo?: boolean;
}>();

const emits = defineEmits<{
  (e: 'change', newValue: ShowbackFilters): void;
  (e: 'download-csv'): void;
}>();

const downloadCSV = () => emits('download-csv');

function savingsShareChanged(newValue: SavingsShareType) {
  if (props.demo) throw new Error('Cannot change savings share filter in demo mode');

  emits('change', { ...props.filters, savings_share: newValue });
}
</script>

<template>
  <div class="pb-1 d-flex justify-content-between">
    <div class="actions">
      <div>
        <strong>Savings Reallocation Method</strong>
        <ReallocationMethod :value="filters.reallocation_method" :disabled="demo" />
      </div>
      <div>
        <strong>ProsperOps Savings Share</strong>
        <SavingsShare :value="filters.savings_share" :disabled="demo" @change="savingsShareChanged" />
      </div>
    </div>
    <div>
      <BaseButton
        title="Download in CSV format"
        variant="transparent"
        class="csvIcon btn-no-focus-box-shadow"
        @click.prevent="downloadCSV"
      >
        <DownloadCsvIcon />
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.actions {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > div:nth-child(1) {
    margin-right: 0;

    @include media-breakpoint-up(lg) {
      margin-right: 2rem;
    }
  }
}

.csvIcon > svg {
  width: 24px;
  height: 24px;
}
</style>
