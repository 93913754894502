<script setup lang="ts">
import type { SchedulerLogPowerActionData } from '@console/services/aws/arm/api.models';

import { computed } from 'vue';
const props = defineProps<{ eventLog: SchedulerLogPowerActionData }>();

const tooltipTextForPowerAction = computed(() => {
  return props.eventLog.action_outcome === 'Failed' ? 'Error message received' : 'Unspecified Error';
});
</script>

<template>
  <div>
    <p><strong>Power state change failure:</strong></p>
    <ul>
      <li>{{ tooltipTextForPowerAction }}</li>
    </ul>
  </div>
</template>
