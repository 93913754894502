<script setup lang="ts">
import { useHead } from '@unhead/vue';

import PageLayout from '@console/PageLayout.vue';

useHead({
  title: 'Costs Avoided',
});
</script>

<template>
  <PageLayout>
    <template #default>
      <div>Placeholder for ARM Scheduler Costs Avoided</div>
    </template>
  </PageLayout>
</template>
