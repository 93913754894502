<script>
import Clipboard from 'clipboard';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      clipboard: undefined,
      handle: undefined,
      buttonText: 'Copy',
    };
  },
  created() {
    this.clipboard = new Clipboard('.copyBtn');
  },
  unmounted() {
    this.clipboard.destroy();
    if (this.handle) {
      clearTimeout(this.handle);
      this.handle = undefined;
    }
  },
  methods: {
    clicked() {
      this.buttonText = 'Copied!';
      this.handle = setTimeout(() => {
        this.buttonText = 'Copy';
      }, 3000);
    },
  },
};
</script>

<template>
  <BaseButton
    id="copyBtn"
    variant="transparent"
    class="copyBtn btn-no-focus-box-shadow"
    :data-clipboard-text="text"
    @click="clicked"
  >
    <div class="d-flex align-items-center justify-content-center text-white">
      <small>{{ buttonText }}</small>
      <small class="ml-1">
        <BaseIcon name="copy" />
      </small>
    </div>
  </BaseButton>
</template>
