export const colors = [
  '#8fffdf', // success-secondary
  '#fcbe2c', // warning
  '#fc5454', // danger
  '#00c58c', // success
  '#05004d', // info
  '#a7a3ff', // secondary
  '#5c54ff', // primary
];

const fixedColors: Record<string, string> = {
  Overall: '#ced4da', // gray-400
  // Using the same color is fine, Others and Inherited are never visible at the same time
  Inherited: '#6c757d', // dark
  Others: '#6c757d', // dark
  'Compute Flex CUDs': '#5c54ff', // primary
};

export const getColor = (label: string, index = 0) => {
  return fixedColors[label] ?? colors[index % colors.length];
};

export const getGradientBreakpoints = (min: number, max: number) => {
  const delta = max - min;
  // Gauge will be 54% green, 23% yellow, 23% red
  const bandSize = (max - min) * 0.23;
  return {
    yellow: max - bandSize * 2,
    red: max - bandSize,
    // Used to hide the rounded end of the plot bands
    radiusOffset: delta * 0.05,
    // A value to subtract/add to yellow and red
    // gradient width will be 2 * gradientOffset
    gradientOffset: delta * 0.05,
  };
};
