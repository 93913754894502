<script setup>
defineProps({
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: false,
    default: null,
  },
  value: {
    type: String,
    required: false,
    default: null,
  },
  showLabel: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(['shown']);
</script>

<template>
  <div>
    <b-dropdown class="filter" :class="{ [`filter--${type}`]: type }" @shown="emit('shown')">
      <template #button-content>
        <div class="d-inline-flex align-items-center gap-2">
          <span v-if="showLabel" class="text-body d-inline-block mr-2">{{ label }}:</span>
          <slot name="selected-value">{{ value }}</slot>
        </div>
      </template>
      <slot />
    </b-dropdown>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

:deep(.filter) {
  /* stylelint-disable selector-class-pattern */ // These classes come from bootstrap
  .dropdown-toggle,
  &.show .dropdown-toggle {
    color: $gray-600;
    background-color: $white;

    &:not(:disabled):active,
    &:not(:disabled):focus,
    &:not(:disabled):hover {
      color: $gray-900;
      background-color: $white;
      border: 1px solid $gray-500;
    }
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 300px;
    text-align: left;
    border: 1px solid $gray-400;

    &::after {
      margin-left: 0.5rem;
    }
  }

  &.show .dropdown-toggle {
    border: 1px solid $gray-500;
  }

  .dropdown-menu {
    max-height: 50vh;
    overflow-y: scroll;
  }

  .dropdown-item {
    padding-right: 1rem;
    padding-left: 1rem;

    &:active {
      color: $gray-900;
      background-color: $gray-200;
    }
  }

  .dropdown-header {
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 75%;
    font-weight: bold;
    text-transform: uppercase;
  }
  /* stylelint-enable selector-class-pattern */

  .filterSearch {
    position: relative;
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    background-color: $white;

    /* stylelint-disable selector-class-pattern */ // These classes come from bootstrap
    .form-control {
      padding-left: calc(1em + 1.25rem);
    }

    .svg-inline--fa {
      position: absolute;
      top: calc((1.5em + 0.75rem + 2px) / 2);
      left: 1.25rem;
      margin-top: 1px;
      color: $gray-600;
    }
    /* stylelint-enable selector-class-pattern */
  }
}
</style>
