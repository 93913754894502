<script setup lang="ts">
import type { CustomerDataExportStatusResponse } from '@console/services/api.models';

import { computed, onMounted, ref } from 'vue';

import awsService from '@aws/services/awsService';
import { useVuexStore } from '@console/state/vuex/store';
import { formatDate } from '@shared/utilities/date_helpers';

import DataExportConfigureModal from './DataExportConfigureModal.vue';
import SettingRow from '@console/components/settings/SettingRow.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import ModalV2 from '@shared/design/ModalV2.vue';
import LastUpdated from '@shared/design/panels/LastUpdated.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';
import StatusPill from '@shared/design/StatusPill.vue';

const vuexStore = useVuexStore();

const s3BucketName = computed(
  () => vuexStore.getters['aws/selectedOrganizationSettings']?.customer_data_export_s3_bucket_name ?? ''
);

const isLoading = ref(false);
const showDisableConfirmationModal = ref(false);
const showConfigureModal = ref(false);
const isCustomerDataExportEnabled = ref<boolean>(false);
const settingLastUpdated = ref<string | null>(null);
const lastExportDate = ref<string | null>(null);
const iamValidationError = ref<{ message: string; date: string } | null>(null);

onMounted(async () => {
  isLoading.value = true;

  const status = await awsService.getCustomerDataExportStatus(vuexStore.getters['aws/selectedOrganizationId']);
  setStatusState(status);

  isLoading.value = false;
});

function setStatusState(status: CustomerDataExportStatusResponse) {
  isCustomerDataExportEnabled.value = status.is_enabled;
  settingLastUpdated.value = status.setting_last_updated_date;
  lastExportDate.value = status.last_export_date;
  iamValidationError.value = status.iam_validation_error_message
    ? { message: status.iam_validation_error_message, date: status.iam_validation_error_date ?? '' }
    : null;
}

async function disable() {
  isLoading.value = true;

  const status = await awsService.disableCustomerDataExport(vuexStore.getters['aws/selectedOrganizationId']);

  setStatusState(status);
  await vuexStore.dispatch('aws/initializeAwsOrganizationSettings');

  isLoading.value = false;
  showDisableConfirmationModal.value = false;
}

async function onCloseModal(shouldRefresh: boolean) {
  if (shouldRefresh) {
    const status = await awsService.getCustomerDataExportStatus(vuexStore.getters['aws/selectedOrganizationId']);
    setStatusState(status);
  }
  showConfigureModal.value = false;
}
</script>

<template>
  <div>
    <BoxMessage v-if="iamValidationError" type="error" class="mb-4">
      <div>
        Data export to {{ s3BucketName }} failed at {{ formatDate(iamValidationError.date) }} due to
        {{ iamValidationError.message }}.
      </div>
      <div class="mt-3">
        Please configure Data Export again. If the problem persists,
        <router-link :to="{ name: 'help' }">contact us</router-link> for assistance.
      </div>
    </BoxMessage>
    <PanelSection header="" rounded new-header class="settingsConstrainedWidth">
      <template #header>
        <div class="container">
          <div class="row">
            <div class="col headerTitle">Data Export</div>
            <LastUpdated :value="settingLastUpdated" />
          </div>
        </div>
      </template>
      <div class="mb-4 mt-2">
        Periodically export ProsperOps data to an S3 bucket in your AWS Organization. This enables programmatic
        integration and/or ad hoc data analysis.
        <a href="https://help.prosperops.com/data-export-intro" target="_blank">Learn more</a>
      </div>
      <SettingRow class="settingRow">
        <template v-slot:label> Status </template>
        <template v-slot:value>
          <StatusPill :enabled="isCustomerDataExportEnabled">
            <span v-if="isCustomerDataExportEnabled">Enabled</span>
            <span v-else>Disabled</span>
          </StatusPill>
        </template>
      </SettingRow>
      <SettingRow v-if="isCustomerDataExportEnabled" class="settingRow">
        <template v-slot:label> S3 Bucket Name </template>
        <template v-slot:value>
          <strong>{{ s3BucketName }}</strong>
        </template>
      </SettingRow>
      <SettingRow v-if="isCustomerDataExportEnabled" class="settingRow">
        <template v-slot:label> Last Export </template>
        <template v-slot:value>
          {{ lastExportDate ? formatDate(lastExportDate) : '(none)' }}
        </template>
      </SettingRow>
      <div v-if="isCustomerDataExportEnabled" class="mt-4">
        <button
          class="btn btn-outline-info rounded-sm disableButton"
          @click="() => (showDisableConfirmationModal = true)"
        >
          Disable
        </button>
      </div>
      <div v-if="!isCustomerDataExportEnabled" class="mt-4">
        <button class="btn btn-primary rounded-sm enableButton" @click="() => (showConfigureModal = true)">
          Configure
        </button>
      </div>
    </PanelSection>
    <ModalV2 v-model="showDisableConfirmationModal" size="lg" title="Disable Data Export">
      <div class="mb-4">Are you sure you want to disable?</div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-outline-info rounded-sm mr-2" @click="() => (showDisableConfirmationModal = false)">
          Keep Enabled
        </button>
        <button class="btn btn-primary rounded-sm" @click="disable">Disable</button>
      </div>
    </ModalV2>
    <DataExportConfigureModal
      v-if="showConfigureModal"
      :show-modal="showConfigureModal"
      :s3-bucket-name="s3BucketName"
      @on-close-modal="onCloseModal"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.settingsConstrainedWidth {
  max-width: 850px;

  :deep(section) {
    padding: 0.5rem 1.5rem 2rem;
  }
}

@include media-breakpoint-up(md) {
  :deep(.setting) {
    > div:first-child {
      width: 35%;
    }
  }
}
.headerTitle {
  padding: 0;
  font-weight: 500;
}
</style>
