<script setup lang="ts">
import type { SchedulerLogPowerActionData, SchedulerLogEventData } from '@console/services/aws/arm/api.models.ts';

import { computed } from 'vue';

import PowerChangedTooltip from '@aws/components/arm/event-logs/PowerChangedTooltip.vue';
import ScheduleUpdatedTooltip from '@aws/components/arm/event-logs/ScheduleUpdatedTooltip.vue';

const props = defineProps<{ params: { value: SchedulerLogPowerActionData | SchedulerLogEventData } }>();
const eventLog = computed(() => props.params.value);
</script>

<template>
  <div class="eventLogStatusTooltip">
    <ScheduleUpdatedTooltip v-if="eventLog.event_type === 'ScheduleUpdated'" :event-log="eventLog" />
    <PowerChangedTooltip v-if="eventLog.event_type === 'PowerAction'" :event-log="eventLog" />
  </div>
</template>

<style lang="scss">
@use '@shared/scss/fonts.scss';
@use '@shared/scss/colors.scss';

.eventLogStatusTooltip {
  top: 5px;
  max-width: 500px;
  padding: 0.5rem 0.5rem 0.5rem;
  font-family: fonts.$font-family-sans-serif;
  background-color: colors.$gray-100;
  border: 1px solid colors.$gray-300;
  border-radius: 0;
}
</style>
