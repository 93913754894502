import type { Ec2InventoryItem, EventLogItem, Ec2InventoryMetrics } from '@console/services/aws/arm/api.models';
import type { AwsOrganizationId } from '@shared/services/namedIds';

import { core } from '@shared/services/api';

export async function getEc2Inventory(awsOrganizationId: AwsOrganizationId): Promise<Array<Ec2InventoryItem>> {
  const res = await core.get<Array<Ec2InventoryItem>>(`/v1/aws/organizations/${awsOrganizationId}/arm/ec2/inventory`);
  return res.data;
}

export async function getEventLogs(awsOrganizationId: AwsOrganizationId): Promise<Array<EventLogItem>> {
  const res = await core.get<Array<EventLogItem>>(`/v1/aws/organizations/${awsOrganizationId}/arm/ec2/event_logs`);
  return res.data;
}

export async function getEc2InventoryMetrics(awsOrganizationId: AwsOrganizationId): Promise<Ec2InventoryMetrics> {
  const res = await core.get<Ec2InventoryMetrics>(
    `/v1/aws/organizations/${awsOrganizationId}/arm/ec2/inventory/metrics`
  );
  return res.data;
}
