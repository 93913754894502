<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';

import { getCurrencyFormat, percentFormat } from '@console/components/charts/utility';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

export default {
  components: {
    ChartLegend,
    SeriesChart,
  },
  props: {
    netSavingsTrend: {
      type: Array,
      required: true,
    },
    service: {
      type: String,
      required: true,
      default: 'compute',
    },
    allDiscounts: {
      type: Boolean,
      required: false,
      default: false,
    },
    emptyYAxisMax: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      series: [],
      noData: false,
    };
  },
  computed: {
    ...mapGetters('customer', ['isReseller']),
    xAxis() {
      const formatCategory = item => moment.utc(item.month_start).format('MMM YYYY');
      const categories = _.map(this.netSavingsTrend, formatCategory);
      return {
        categories,
      };
    },
    yAxis() {
      const data = this.netSavingsTrend;
      const esr = _.compact(_.map(data, 'effective_savings_rate_percentage'));
      const base = _.compact(_.map(data, 'baseline_effective_savings_rate_percentage'));
      const smallestValue = _.min([...esr, ...base]);
      const min = smallestValue >= 0 ? 0 : _.floor(smallestValue, -1);
      const rightTickPositions = _.range(min, 61, 10);
      return [
        {
          min: 0,
          max: this.noData ? this.emptyYAxisMax : undefined,
          tickAmount: rightTickPositions.length,
          title: {
            text: null,
          },
          labels: {
            format: getCurrencyFormat(),
          },
        },
        {
          tickPositions: rightTickPositions,
          title: {
            text: null,
          },
          labels: {
            format: percentFormat,
          },
          opposite: true,
        },
      ];
    },
    filteredSeries() {
      return _.filter(this.series, s => s.legendSelected);
    },
    isProductCompute() {
      return this.service === 'compute';
    },
    serviceDisplayName() {
      return AwsServiceHelpers.getDisplayName(this.service);
    },
  },
  mounted() {
    const safeMap = (arr, key) => _.map(arr, a => _.get(a, key, null));
    const hasAnyProps = (o, props) => _.some(props, prop => _.has(o, prop));
    const firstMonths = _.initial(this.netSavingsTrend);
    const lastMonth = _.last(this.netSavingsTrend);
    const includeLastMonth = hasAnyProps(lastMonth, [
      'compute_usage',
      'service_usage',
      'net_savings',
      'effective_savings_rate_percentage',
      'baseline_effective_savings_rate_percentage',
    ]);
    const data = includeLastMonth ? [...firstMonths, lastMonth] : firstMonths;
    // compute
    const computeUsage = safeMap(data, 'compute_usage');
    // non-compute
    const serviceUsage = safeMap(data, 'service_usage');
    // shared
    const netSavings = safeMap(data, 'net_savings');
    const esr = safeMap(data, 'effective_savings_rate_percentage');
    const base = safeMap(data, 'baseline_effective_savings_rate_percentage');

    this.series = [
      this.isProductCompute
        ? {
            label: this.prefixForReseller('Compute Usage'),
            name: this.prefixForReseller('Compute Usage'),
            data: computeUsage,
            color: '#a7a3ff',
            dashStyle: 'solid',
            type: 'column',
            yAxis: 0,
            tooltip: {
              valuePrefix: '$',
              valueSuffix: '',
              valueDecimals: 2,
            },
            legendOrder: 0,
            legendSelected: true,
          }
        : // non-compute
          {
            label: `${this.serviceDisplayName} Usage`,
            name: `${this.serviceDisplayName} Usage`,
            data: serviceUsage,
            color: '#a7a3ff',
            dashStyle: 'solid',
            type: 'column',
            yAxis: 0,
            tooltip: {
              valuePrefix: '$',
              valueSuffix: '',
              valueDecimals: 2,
            },
            legendOrder: 0,
            legendSelected: true,
          },
      {
        label: 'Net Savings',
        name: 'Net Savings',
        data: netSavings,
        color: '#00c58c',
        dashStyle: 'solid',
        type: 'column',
        yAxis: 0,
        tooltip: {
          valuePrefix: '$',
          valueSuffix: '',
          valueDecimals: 2,
        },
        legendOrder: 1,
        legendSelected: true,
      },
      {
        label: 'Effective Savings Rate with ProsperOps',
        name: 'Effective Savings Rate with ProsperOps',
        data: esr,
        type: 'line',
        color: '#fcbe2c',
        dashStyle: 'solid',
        yAxis: 1,
        marker: {
          enabled: false,
        },
        tooltip: {
          valuePrefix: '',
          valueSuffix: '%',
          valueDecimals: 1,
        },
        legendOrder: 2,
        legendSelected: true,
      },
      {
        label: 'Effective Savings Rate before ProsperOps (baseline)',
        name: 'Effective Savings Rate before ProsperOps (baseline)',
        data: base,
        type: 'line',
        color: '#495057',
        dashStyle: 'dash',
        yAxis: 1,
        marker: {
          enabled: false,
        },
        tooltip: {
          valuePrefix: '',
          valueSuffix: '%',
          valueDecimals: 1,
        },
        legendOrder: 3,
        legendSelected: true,
      },
    ].filter(Boolean);

    // We don't re-capture historical stats for the all discounts view, force the chart to draw
    if (this.emptyYAxisMax && this.series.every(s => s.data.every(d => d == null))) {
      this.noData = true;
    }
  },
  methods: {
    prefixForReseller(label) {
      if (this.isReseller) {
        return `Arbitrageable ${label}`;
      }
      return label;
    },
  },
};
</script>

<template>
  <div>
    <ChartLegend v-model="series" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" />
    <div class="d-flex flex-row-reverse">
      <small>(normalized)</small>
    </div>
  </div>
</template>
