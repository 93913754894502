<script setup>
import { computed, ref } from 'vue';

import CopyButton from '@shared/design/CopyButtonV2.vue';
import FormSubmitButton from '@shared/design/FormSubmitButton.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const props = defineProps({
  iamRole: {
    type: Object,
    required: true,
  },
  organizationId: {
    type: String,
    required: true,
  },
  permissions: {
    type: Array,
    required: true,
  },
  onCreated: {
    type: Function,
    required: true,
  },
});

const showRoleCreated = ref(true);
const roleIamUrl = `https://console.cloud.google.com/iam-admin/roles?organizationId=${props.organizationId}`;
const sortedPermissions = computed(() => props.permissions.toSorted());

const onClick = () => {
  showRoleCreated.value = false;
  props.onCreated();
};
</script>

<template>
  <PanelSection header="Custom ProsperOps Role" class="mb-4">
    <div>
      <ul>
        <li>Navigate to <a target="_blank" rel="noopener noreferrer" :href="roleIamUrl">Google Cloud IAM Roles</a></li>
        <li>Click <b>Create Role</b> and add the following role configuration:</li>
      </ul>
      <div class="pl-4 pr-4">
        <div>
          Title:<br />
          <span class="text-monospace bg-light p-1">{{ iamRole.title }}</span>
          <CopyButton :key="iamRole.title" class="ml-2" :text="iamRole.title" />
        </div>
        <div class="mt-2">
          Description:<br />
          <span class="text-monospace bg-light p-1">{{ iamRole.description }}</span>
          <CopyButton key="roleDescription" class="ml-2" :text="iamRole.description" />
        </div>
        <div class="mt-2">
          ID:<br />
          <span class="text-monospace bg-light p-1">{{ iamRole.id }}</span>
          <CopyButton :key="iamRole.id" class="ml-2" :text="iamRole.id" />
        </div>
        <div class="mt-2">
          Role Launch Stage:<br />
          <span class="text-monospace bg-light p-1">{{ iamRole.role_launch_stage }}</span>
        </div>
        <div class="mt-2">
          Permissions:<br />
          <div v-for="permission in sortedPermissions" :key="permission" class="mt-1">
            <span class="text-monospace bg-light p-1">{{ permission }}</span>
            <CopyButton :key="permission" class="ml-2" :text="permission" /><br />
          </div>
        </div>
      </div>
      <div class="mt-3 d-flex flex-row-reverse">
        <FormSubmitButton v-show="showRoleCreated" type="button" variant="primary" @click="onClick">
          Role Created
        </FormSubmitButton>
      </div>
    </div>
  </PanelSection>
</template>
