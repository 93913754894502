<script>
import _ from 'lodash';

import ShowbackTableRow from '@aws/components/adm/showback/ShowbackTableRow.vue';

export default {
  components: {
    ShowbackTableRow,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      required: false,
      default: 0,
    },
    expanded: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  computed: {
    row() {
      // Note: the aggregate is calculated with the unrounded values,
      // then the sum is rounded once.
      const cols = ['original_cost', 'undiscounted_usage', 'reallocated_savings', 'adjusted_cost', 'net_adjustment'];
      return _.reduce(
        cols,
        (acc, prop) => {
          return _.assign({}, acc, {
            [`${prop}_rounded`]: this.sumAndRound(this.rows, prop),
          });
        },
        {}
      );
    },
  },
  methods: {
    sumAndRound(rows, col) {
      const val = _(rows)
        .map(v => _.get(v, col, 0) || 0)
        .sum()
        .toFixed(2);
      return Number(val);
    },
  },
};
</script>

<template>
  <ShowbackTableRow :row="row" :level="level" :expanded="expanded">
    <template v-slot:label>
      <slot name="label" />
    </template>
  </ShowbackTableRow>
</template>
