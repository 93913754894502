<script setup lang="ts">
import type { CustomerId } from '@shared/services/namedIds';

import { useHead } from '@unhead/vue';
import { computed, ref } from 'vue';

import { BillingScopeStatus } from '@azure/services/billingScope';
import { useAzureStore } from '@azure/state';
import { useVuexStore } from '@console/state/vuex/store';
import { useAuthStore } from '@shared/state/auth.store';

import Done from '@azure/components/onboarding/Done.vue';
import ManagementPermissions from '@azure/components/onboarding/ManagementPermissions.vue';
import PrelaunchPlaceholder from '@azure/components/onboarding/PrelaunchPlaceholder.vue';
import SavingsAnalysisPermissions from '@azure/components/onboarding/SavingsAnalysisPermissions.vue';
import Start from '@azure/components/onboarding/Start.vue';
import OnboardingProgress from '@console/components/onboarding/OnboardingProgress.vue';
import Subscribe from '@console/components/onboarding/Subscribe.vue';
import Layout from '@console/Layout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';

useHead({
  title: 'Azure Onboarding',
});

type OnboardingStep =
  | 'start'
  | 'savings-analysis-permissions'
  | 'schedule-savings-analysis'
  | 'management-permissions'
  | 'subscribe'
  | 'done';

const azureState = useAzureStore();
const vuexStore = useVuexStore();

const currentUserIsViewer = computed(() => {
  const vuexStore = useVuexStore();
  const authStore = useAuthStore();
  const customerId: CustomerId = vuexStore.getters['customer/selectedCompanyId'];

  return authStore.role(customerId) === 'Viewer';
});

const showPostSavingsAnalysisSteps = computed(() => {
  const context = vuexStore.getters['nav/context'];
  const scope = azureState.getBillingScopeById(context.id);
  const settings = azureState.getGlobalSettingsById(context.id);

  return scope && settings?.savings_analysis_completed;
});

const showSubscriptionStep = computed(
  () => !vuexStore.getters['customer/isSubscribed'] && showPostSavingsAnalysisSteps.value
);

const currentStep = ref<OnboardingStep>(
  (() => {
    const context = vuexStore.getters['nav/context'];
    const scope = azureState.getBillingScopeById(context.id);

    if (showSubscriptionStep.value && scope?.status === BillingScopeStatus.Active) {
      return 'subscribe';
    }

    if (showPostSavingsAnalysisSteps.value) {
      return 'management-permissions';
    }

    if (scope) {
      return 'schedule-savings-analysis';
    }

    return 'start';
  })()
);

const preSavingsAnalysisSteps: { text: string; key: OnboardingStep; number: number }[] = [
  {
    text: 'Azure Details',
    key: 'start',
    number: 1,
  },
  {
    text: 'Initial Azure Access',
    key: 'savings-analysis-permissions',
    number: 2,
  },
  {
    text: 'Free Savings Analysis',
    key: 'schedule-savings-analysis',
    number: 3,
  },
];

const postSavingsAnalysisSteps = [
  ...preSavingsAnalysisSteps,
  {
    text: 'Additional Azure Access',
    key: 'management-permissions',
    number: 4,
  },
];

const subscribeStep = {
  text: 'Review and Subscribe',
  key: 'subscribe',
  number: 5,
};

const progressTrackerSteps = computed(() => {
  let stepsToShow = showPostSavingsAnalysisSteps.value ? postSavingsAnalysisSteps : preSavingsAnalysisSteps;

  if (showSubscriptionStep.value) {
    stepsToShow = [...stepsToShow, subscribeStep];
  }

  return stepsToShow.map(step => ({ ...step, isActive: step.key === currentStep.value }));
});

const subscribeOrDone = () => {
  if (showSubscriptionStep.value) {
    currentStep.value = 'subscribe';
  } else {
    currentStep.value = 'done';
  }
};
</script>

<template>
  <Layout :with-announcement-banner="false">
    <template #default>
      <BoxMessage v-if="currentUserIsViewer" type="error" class="pt-2 pb-2">
        <strong> You do not have the necessary permission to add Azure Billing Scopes</strong>
        <p class="mb-0">
          In order to configure ProsperOps, you must be an
          <strong>Owner</strong> or <strong>Editor</strong> role user. Please contact your account's administrator for
          further assistance.
        </p>
      </BoxMessage>
      <div v-else>
        <OnboardingProgress v-if="currentStep !== 'done'" :steps="progressTrackerSteps" class="mb-2" />
        <Start v-if="currentStep === 'start'" :next="() => (currentStep = 'savings-analysis-permissions')" />
        <SavingsAnalysisPermissions
          v-if="currentStep === 'savings-analysis-permissions'"
          :next="() => (currentStep = 'schedule-savings-analysis')"
          :prev="() => (currentStep = 'start')"
        />
        <PrelaunchPlaceholder v-if="currentStep === 'schedule-savings-analysis'" />
        <ManagementPermissions v-if="currentStep === 'management-permissions'" :next="subscribeOrDone" />
        <Subscribe v-if="currentStep === 'subscribe'" :next="() => (currentStep = 'done')" />
        <Done v-if="currentStep === 'done'" />
      </div>
    </template>
  </Layout>
</template>
