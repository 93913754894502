<script setup lang="ts">
import type { ChartLegendOptions } from '@console/components/charts/ChartLegendV2.vue';
import type {
  AwsOrganizationNonComputeWeightedAverageDurationTrend,
  AwsOrganizationCommitmentLockInRiskTrendSeries,
} from '@console/services/api.models';
import type { SeriesAreaOptions, SeriesLineOptions, TooltipOptions, XAxisOptions, YAxisOptions } from 'highcharts';

import { computed, onMounted, ref, watch } from 'vue';

import { getColor } from './commitment_utility';
import * as chartUtilities from '@console/components/charts/utility';
import { getMonthYAxis } from '@console/components/charts/utility';
import { daysToMonths } from '@shared/utilities/date_helpers';
import { isDefined } from '@shared/utilities/typescript_helpers';

import ChartLegendV2 from '@console/components/charts/ChartLegendV2.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

const props = defineProps<{
  trend: AwsOrganizationNonComputeWeightedAverageDurationTrend;
  dataThroughDate: string;
}>();

const filteredTrendData = computed(() => {
  const series: AwsOrganizationCommitmentLockInRiskTrendSeries[] = [
    ...props.trend.series_data,
    {
      label: 'Overall',
      days: props.trend.overall_days,
    },
  ];

  return (
    series
      .map(s => {
        return {
          ...s,
          months: s.days.map(d => (d == null ? d : daysToMonths(d))),
        };
      })
      // Remove series with no data
      .filter(s => s.months.some(d => d))
  );
});

const MS_IN_DAY = 86400000;
const msStart = computed(() => {
  const seriesDayCounts = filteredTrendData.value.map(s => s.months.length);
  const dayCount = Math.max(...seriesDayCounts) - 1;
  const dataThroughDate = props.dataThroughDate;
  return +new Date(dataThroughDate) - MS_IN_DAY * dayCount;
});

type SeriesDataPoint = [number, number | null];
type SeriesOptions = Omit<SeriesLineOptions | SeriesAreaOptions, 'name' | 'data' | 'color'> & {
  name: string;
  data: Array<SeriesDataPoint>;
  color: string;
};

const series = computed<Array<SeriesOptions>>(() => {
  return filteredTrendData.value.map((s, index) => ({
    name: s.label,
    type: s.label === 'Overall' ? 'area' : 'line',
    data: s.months.map((element, index) => {
      var x = msStart.value + index * MS_IN_DAY;
      var y = element;
      return [x, y];
    }),
    // Overall is first in the array, subtract 1 so colors match the other charts
    color: getColor(s.label, index),
    marker: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 1,
      valueSuffix: ' months',
    },
    zIndex: s.label === 'Overall' ? 0 : 1,
  }));
});

const tooltip: TooltipOptions = {
  enabled: true,
  borderRadius: 0,
  borderColor: '#adb5bd',
  shared: true,
  shadow: false,
  xDateFormat: '<span style="font-size: 12px">%B %d, %Y</span>',
  useHTML: true,
  dateTimeLabelFormats: {
    millisecond: '%b %e, %Y',
  },
};

const xAxis: XAxisOptions = {
  type: 'datetime',
  tickLength: 0,
  labels: {
    formatter: chartUtilities.datetimeZoomLabelFormatter,
  },
  units: chartUtilities.units,
};

const yAxis = computed<YAxisOptions[]>(() => {
  const allValues = filteredTrendData.value.flatMap(s => s.months).filter(isDefined);
  const maxMonths = Math.max(...allValues);
  return [getMonthYAxis(maxMonths)];
});

const legend = ref<ChartLegendOptions[]>([]);
const buildLegend = () => {
  legend.value = series.value.map(s => ({
    label: s.name,
    color: s.color,
    selected: true,
  }));
};
watch(series, buildLegend);
onMounted(buildLegend);

const filteredSeries = computed(() => series.value.filter(s => legend.value.find(l => l.label === s.name)?.selected));
</script>

<template>
  <div>
    <ChartLegendV2 v-model:model-value="legend" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :tooltip="tooltip" :series="filteredSeries" zoomable />
  </div>
</template>
