<script lang="ts" setup>
import type { AccountValidationActionResponse, AccountValidationError, AwsAccount } from '@console/services/api.models';
import type { AwsAccountId, AwsOrganizationId } from '@shared/services/namedIds';

import _ from 'lodash';
import { computed, ref } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';
import { useAuthStore, Cloud, PermissionLevel } from '@shared/state/auth.store';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';

import AwsAccountStatusPill from '@aws/components/accounts/AwsAccountStatusPill.vue';
import ModalV2 from '@shared/design/ModalV2.vue';
import Pill from '@shared/design/Pill.vue';
import Tooltip from '@shared/design/Tooltip.vue';

export interface EnrichedAwsAccount extends AwsAccount {
  actionNonComputeServices: { name: string; label: string; regions: string[]; showToolTip: boolean }[];
  hasArmEc2Scheduler: boolean;
}

const props = defineProps<{
  awsAccounts: EnrichedAwsAccount[];
  showRiActionAccountRegionTooltip?: boolean;
  batchValidationEnabled?: boolean;
  isValidatingByAwsAccount: Record<string, boolean>;
  validationErrorByAwsAccount: Record<string, AccountValidationError>;
}>();

const authStore = useAuthStore();
const store = useVuexStore();
const selectedCompanyId = computed(() => store.state.customer.selectedCompanyId);

const failingAccount = ref('');
const showAccessFailuresModal = ref(false);

const needsSetup = (awsAccount: AwsAccount) => AwsServiceHelpers.needsSetup(awsAccount.status);

const isAtLeastEditor = (awsOrganizationId: AwsOrganizationId) =>
  authStore.isAtLeastRoleForCustomerOrGranular(
    selectedCompanyId.value,
    PermissionLevel.Editor,
    Cloud.AWS,
    awsOrganizationId
  );

const canViewAwsAccountConfiguration = (awsAccount: AwsAccount) =>
  awsAccount.status === 'Active' && isAtLeastEditor(awsAccount.aws_organization_id);

const applicableRegionsForActionAccount = (awsAccount: AwsAccount) => awsAccount?.action_account_regions ?? [];

const isRiActionAccount = (awsAccount: AwsAccount) => applicableRegionsForActionAccount(awsAccount).length > 0;

const showSavingsPlanActionAccountPill = (awsAccount: AwsAccount) =>
  !awsAccount.is_reseller_base_shard && awsAccount.is_savings_plan_action_aws_account;

const accessFailures = () => {
  if (!failingAccount.value) return [];
  return _.sortBy(
    JSON.parse(
      props.validationErrorByAwsAccount[failingAccount.value].errors[0].context.AccessFailures
    ) as AccountValidationActionResponse[],
    accessFailure => accessFailure.Action
  );
};

const failureKey = (accessFailure: AccountValidationActionResponse) =>
  `${accessFailure.Region}.${accessFailure.Action}`;
const failureReason = (accessFailureReason: string) => {
  if (accessFailureReason === 'ServiceControlPolicy') {
    return 'This action is blocked by an SCP';
  }
  return 'This action is not authorized by the policy';
};

const showFailures = (awsAccountId: AwsAccountId) => {
  failingAccount.value = awsAccountId;
  showAccessFailuresModal.value = true;
};

const showV2AccessFailure = (awsAccountId: AwsAccountId) =>
  props.validationErrorByAwsAccount[awsAccountId]?.code === 'AwsAccountIamInvalidAccessV2';

const showV1AccessFailure = (awsAccountId: AwsAccountId) =>
  !showV2AccessFailure(awsAccountId) && props.validationErrorByAwsAccount[awsAccountId];
</script>

<template>
  <div>
    <ul class="awsAccountList list-group pt-0 rounded-sm">
      <li
        v-for="awsAccount in awsAccounts"
        :key="awsAccount.aws_account_number"
        class="list-group-item awsAccountListItem"
      >
        <div class="awsAccountRow">
          <div class="status">
            <AwsAccountStatusPill :status="awsAccount.status" />
          </div>
          <div class="detail">
            <div class="description">
              <div>
                <span>
                  {{ awsAccount.friendly_name }}
                </span>
              </div>
              <div>
                <span class="text-muted mr-2 mb-1 d-inline-block"> #{{ awsAccount.aws_account_number }} </span>
                <Pill v-if="awsAccount.is_management_account" variant="primary" class="awsAccountListPill">
                  Management Account
                </Pill>
                <Pill v-if="awsAccount.is_reseller_base_shard" variant="info" class="awsAccountListPill">
                  Base Shard
                </Pill>
                <Pill v-if="awsAccount.reseller_flex_shard_region" variant="info" class="awsAccountListPill">
                  Flex Shard: {{ awsAccount.reseller_flex_shard_region }}
                </Pill>
                <Pill v-if="awsAccount.aws_partition === 'aws-us-gov'" variant="info" class="awsAccountListPill">
                  GovCloud
                </Pill>
                <Pill
                  v-if="isRiActionAccount(awsAccount)"
                  :id="awsAccount.id + '_ri_action'"
                  variant="info"
                  class="awsAccountListPill"
                >
                  Compute RI Action Account
                </Pill>
                <Pill v-if="showSavingsPlanActionAccountPill(awsAccount)" variant="info" class="awsAccountListPill">
                  Compute SP Action Account
                </Pill>
                <Tooltip v-if="showRiActionAccountRegionTooltip" :target="awsAccount.id + '_ri_action'">
                  <div>New RIs will be deployed in this account for the following region(s):</div>
                  <div v-for="region in applicableRegionsForActionAccount(awsAccount)" :key="region">
                    {{ region }}
                  </div>
                </Tooltip>
                <template v-for="service in awsAccount.actionNonComputeServices" :key="`${service.name}`">
                  <Pill
                    :id="`${awsAccount.aws_account_number}_${service.name}_ri_action`"
                    variant="info"
                    class="awsAccountListPill"
                  >
                    {{ service.label }} Action Account
                  </Pill>
                  <Tooltip
                    v-if="service.showToolTip"
                    :key="`${service.name}_tooltip`"
                    :target="`${awsAccount.aws_account_number}_${service.name}_ri_action`"
                  >
                    <div>New RIs will be deployed in this account for the following region(s):</div>
                    <div v-for="region in service.regions" :key="region">
                      {{ region }}
                    </div>
                  </Tooltip>
                </template>
                <Pill v-if="awsAccount.hasArmEc2Scheduler" variant="info" class="awsAccountListPill">
                  EC2 Scheduler
                </Pill>
              </div>
            </div>
            <div class="actions">
              <div v-if="needsSetup(awsAccount) && isAtLeastEditor(awsAccount.aws_organization_id)">
                <BaseButton
                  variant="dark"
                  size="sm"
                  class="mr-3 rounded-sm"
                  :to="{
                    name: 'aws_configure_account',
                    params: { awsAccountId: awsAccount.id },
                  }"
                  :disabled="batchValidationEnabled"
                >
                  Setup
                </BaseButton>
                <FormSubmitButton
                  variant="primary"
                  class="rounded-sm"
                  size="sm"
                  :loading="isValidatingByAwsAccount[awsAccount.id]"
                  :disabled="batchValidationEnabled"
                  @click.prevent="$emit('onValidateAccess', awsAccount)"
                >
                  Validate Access
                  <template v-slot:loading> Validating... </template>
                </FormSubmitButton>
                <span v-if="showV2AccessFailure(awsAccount.id)" class="ml-3">
                  <BaseIcon :id="awsAccount.id + '_error'" name="exclamation-triangle" class="text-danger" />
                  <Tooltip :target="awsAccount.id + '_error'">
                    <span>
                      Hmm, we weren't able to validate access. Click the 'More info...' link to see a full list of
                      actions we were unable to validate.
                    </span>
                  </Tooltip>
                  <a class="failureInfo" @click="showFailures(awsAccount.id)">More info...</a>
                </span>
                <span v-if="showV1AccessFailure(awsAccount.id)" class="ml-3">
                  <BaseIcon :id="awsAccount.id + '_error'" name="exclamation-triangle" class="text-danger" />
                  <Tooltip :target="awsAccount.id + '_error'">
                    <span>
                      Hmm, we weren't able to validate access. The ProsperOps role may not be configured correctly, IAM
                      permissions may still need to propagate, or AWS may be having an issue. Please try again. If the
                      issue persists, please chat with us for help.
                    </span>
                  </Tooltip>
                </span>
              </div>
              <div v-if="canViewAwsAccountConfiguration(awsAccount)">
                <BaseButton
                  variant="dark"
                  size="sm"
                  class="rounded-sm"
                  :to="{
                    name: 'aws_configure_account',
                    params: { awsAccountId: awsAccount.id },
                  }"
                  :disabled="batchValidationEnabled"
                >
                  View Configuration
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ModalV2 v-model="showAccessFailuresModal" size="xl" title="Action Validation Failures">
      <div id="access-failures-modal-body">
        <table class="actionFailures">
          <thead>
            <tr class="noTopBorder text-muted">
              <td class="failureAction">
                <span>Action</span>
              </td>
              <td class="failureRegion">
                <span>Region</span>
              </td>
              <td class="failureError">
                <span>Error</span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="accessFailure in accessFailures()" :key="failureKey(accessFailure)">
              <td class="failureAction">
                {{ accessFailure.Action }}
              </td>
              <td class="failureRegion">
                {{ accessFailure.Region }}
              </td>
              <td class="failureError">
                <span>
                  {{ failureReason(accessFailure.Reason) }}
                </span>
                <span :id="accessFailure.Region + accessFailure.Action" class="tooltip-target"
                  >(Full error message)</span
                >
                <Tooltip :target="accessFailure.Region + accessFailure.Action">
                  <span>
                    {{ accessFailure.Error }}
                  </span>
                </Tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ModalV2>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.awsAccountList {
  padding: 0;
  padding-top: 0.5rem;
  list-style-type: none;
}

.awsAccountList li {
  margin-bottom: 0;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}

.awsAccountRow {
  display: flex;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
}

.description {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > div:first-child {
    padding-bottom: 0.25rem;
    font-size: 1rem;
    font-weight: 500;
  }

  > div:last-child {
    padding-bottom: 0.25rem;
    font-size: 0.8rem;
  }
}

.actions {
  display: flex;
  align-items: center;
  min-width: 220px;
}

.failureInfo {
  margin-left: 5px;
  cursor: pointer;
}

.actionFailures {
  width: 100%;
  table-layout: fixed;

  > thead {
    display: block;
  }

  > thead > tr {
    display: inline-block;
    width: 100%;
  }

  > tbody {
    display: block;
    width: 100%;
    height: 500px;
    overflow-y: scroll;
  }

  > tbody::-webkit-scrollbar-track {
    background-color: #fff;
  }

  > tbody::-webkit-scrollbar {
    width: 16px;
    background-color: #fff;
  }

  > tbody::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border: 4px solid #fff;
    border-radius: 16px;
  }

  > tbody > tr {
    display: inline;
  }

  > tbody > tr > td {
    padding-bottom: 10px;
  }
}

.failureAction {
  display: inline-block;
  width: 35%;
  text-align: left;
}

.failureRegion {
  display: inline-block;
  width: 15%;
  text-align: left;
}

.failureError {
  display: inline-block;
  width: 50%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  > span {
    padding-right: 5px;
  }
}

.awsAccountListPill {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem !important;
  margin-right: 0.5rem !important;
  margin-bottom: 0.25rem !important;
  vertical-align: text-top !important;
}

// set breakpoint one larger than .layoutSidebar to ensure
// wrapping styles trigger once main content section narrows
@include media-breakpoint-up(lg) {
  .detail {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .actions {
    justify-content: right;
  }
}
</style>
