import { useAzureStore } from '@azure/state';
import { atLeastRole, hasRoles, notInDemo, requires, selectedCompany } from '@console/router/requirements';
import { getVuexStore } from '@console/state/vuex/store';

import Marketplace from '@azure/views/Marketplace.vue';
import Onboarding from '@azure/views/Onboarding.vue';
import ComputeSavings from '@azure/views/SavingsDataLoader.vue';

const isBillingScopeActive = () => {
  const vuexStore = getVuexStore();
  const azureStore = useAzureStore();

  const id = vuexStore.state.nav?.context?.id;
  return azureStore.isBillingScopeActive(id);
};

export const routes = [
  {
    path: '/azure/compute/savings/:timeframe?',
    name: 'azure_compute_savings',
    component: ComputeSavings,
    beforeEnter: requires(hasRoles, selectedCompany, atLeastRole('Viewer'), isBillingScopeActive),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/azure/onboarding',
    name: 'azure_onboarding',
    component: Onboarding,
    beforeEnter: requires(notInDemo, hasRoles, selectedCompany, atLeastRole('Viewer')),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/azure/marketplace',
    name: 'azure_marketplace',
    component: Marketplace,
    beforeEnter: requires(notInDemo, hasRoles, selectedCompany, atLeastRole('Viewer')),
    meta: {
      initModules: true,
    },
  },
];
