<script setup lang="ts">
import type {
  AwsOrganizationCommitmentLockInRiskDetailSeries,
  AwsOrganizationTotalRemainingCommitment,
  ComputeCommitmentsDashboardResponse,
} from '@console/services/api.models';
import type {
  Aggregation,
  AggregationChangeEvent,
  Amortization,
  AmortizationChangeEvent,
} from '@shared/design/panels/types';

import { useHead } from '@unhead/vue';
import { isAxiosError } from 'axios';
import _ from 'lodash';
import { computed, onMounted, reactive, ref, watch } from 'vue';

import customerService from '@console/services/customerService';
import { useVuexStore } from '@console/state/vuex/store';
import { useFeatureStore } from '@shared/state/feature.store';

import CommitmentLockInRisk from '@aws/components/adm/commitments/CommitmentLockInRisk.vue';
import CommitmentLockInRiskDetail from '@aws/components/adm/commitments/CommitmentLockInRiskDetail.vue';
import CommitmentLockInRiskTrend from '@aws/components/adm/commitments/CommitmentLockInRiskTrend.vue';
import CommitmentBurndown from '@aws/components/adm/commitments/compute/CommitmentBurndown.vue';
import RegionalCommitments from '@aws/components/adm/commitments/compute/RegionalCommitments.vue';
import WeightedAverageDurationTrend from '@aws/components/adm/commitments/compute/WeightedAverageDurationTrend.vue';
import TotalRemainingCommitment from '@aws/components/adm/commitments/non-compute/TotalRemainingCommitment.vue';
import BuildingState from '@console/components/BuildingState.vue';
import Layout from '@console/Layout.vue';
import CommitmentAsOfPill from '@shared/components/CommitmentAsOfPill.vue';
import InfoTooltip from '@shared/design/InfoTooltip.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import AggregationDropdown from '@shared/design/panels/AggregationDropdown.vue';
import AmortizationDropdown from '@shared/design/panels/AmortizationDropdown.vue';
import PanelDropdown from '@shared/design/panels/PanelDropdown.vue';
import PanelLabel from '@shared/design/panels/PanelLabel.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

useHead({
  title: 'Compute Commitments',
});

const commitments = ref<ComputeCommitmentsDashboardResponse>();
const commitmentsNotFound = ref(false);

type ClrPivots = 'Commitment Lock-In Risk' | 'Weighted Average Duration';
type FacetsAndPivots = {
  facets: {
    commitment_burndown: Amortization;
    total_remaining_commitment: Amortization;
  };
  pivots: {
    commitment_burndown: Aggregation;
  };
  clrPivots: {
    clrTrend: ClrPivots;
  };
};

const { facets, pivots, clrPivots } = reactive<FacetsAndPivots>({
  facets: {
    commitment_burndown: 'amortized',
    total_remaining_commitment: 'amortized',
  },
  pivots: {
    commitment_burndown: 'daily',
  },
  clrPivots: {
    clrTrend: 'Commitment Lock-In Risk',
  },
});

type FacetKeys = keyof typeof facets;
type PivotKeys = keyof typeof pivots;

const store = useVuexStore();
const selectedOrganization = computed(() => store.getters['aws/selectedOrganization']);

const isLoading = computed(() => !commitments.value && !commitmentsNotFound.value);
const emptyCommitments = computed(() => {
  const timelines = _.get(commitments.value, 'dashboard.convertible_reserved_instance_expiration_timelines', []);
  return !_.some(timelines);
});

const dashboard = computed(() => commitments.value?.dashboard);
const burndown = computed(() => {
  const facet = facets.commitment_burndown;
  const pivot = pivots.commitment_burndown;
  const prop = `${facet}_${pivot}_commitment_burndown`;
  return _.get(dashboard.value, prop);
});

const criExpirationTimelines = computed(() =>
  _.get(commitments.value, ['dashboard', 'convertible_reserved_instance_expiration_timelines'])
);

onMounted(async () => {
  try {
    const awsOrganizationId = selectedOrganization.value.id;
    const response = await customerService.getCommitments(awsOrganizationId);
    commitments.value = response?.data;
  } catch (e) {
    handleError(e);
  }
});

function facetChange(event: AmortizationChangeEvent) {
  facets[event.name as FacetKeys] = event.selected;
}

function pivotChange(event: AggregationChangeEvent) {
  pivots[event.name as PivotKeys] = event.selected;
}

function handleError(e: unknown) {
  if (isAxiosError(e) && e.status === 404) {
    commitmentsNotFound.value = true;
  } else {
    throw e;
  }
}

// Map chart data to a generic structure for compute and non-compute commitments
const mappedChartData = computed(() => {
  if (!dashboard.value) {
    return null;
  }

  const commitmentKeys = [
    'convertible_reserved_instances',
    'compute_savings_plans',
    'standard_reserved_instances',
    'ec2_instance_savings_plans',
  ] as const;

  const labels = {
    convertible_reserved_instances: 'Convertible RIs',
    standard_reserved_instances: 'Standard RIs',
    compute_savings_plans: 'Compute SPs',
    ec2_instance_savings_plans: 'EC2 Instance SPs',
  } as const;

  const commitmentLockInRiskDetail: AwsOrganizationCommitmentLockInRiskDetailSeries[] = commitmentKeys
    .map(key => ({
      label: labels[key],
      value: dashboard.value!.commitment_lock_in_risk_detail[key] ?? 0,
    }))
    .filter(v => v.value > 0);

  const remaining = dashboard.value[`${facets.total_remaining_commitment}_total_remaining_commitment`];
  const totalRemainingCommitment: AwsOrganizationTotalRemainingCommitment = {
    overall_dollars: remaining.overall_dollars,
    line_items: commitmentKeys
      .map(key => ({
        label: labels[key],
        dollars: remaining[`${key}_dollars`] ?? 0,
        percentage: remaining[`${key}_percentage`] ?? 0,
      }))
      .filter(v => v.dollars > 0),
  };

  return {
    commitmentLockInRiskDetail,
    totalRemainingCommitment,
    commitmentLockInRiskTrend: dashboard.value.commitment_lock_in_risk_trend,
    weightedAverageDurationTrend: dashboard.value.weighted_average_duration_trend,
  };
});

const overallClr = computed(() => dashboard.value?.commitment_lock_in_risk_detail.overall || 0);

const featureStore = useFeatureStore();
const showWadDropdown = computed(() => featureStore.featureFlags.awsClrWadDropdown);
watch(showWadDropdown, () => {
  if (!showWadDropdown.value) {
    clrPivots.clrTrend = 'Commitment Lock-In Risk';
  }
});

const showClrTrendTooltip = computed(() => {
  const trendData = dashboard.value?.commitment_lock_in_risk_trend?.overall;

  // Each item in the array represents a day across a 12 month period, with the first
  // element being the day exactly one year ago (365 days) and the last element being
  // the current date.
  // Missing data for a day is represented by null.
  // So if the first element is null, it means we haven't gotten 12 months of data yet.
  return trendData?.[0] == null;
});
</script>

<template>
  <Layout :loading="isLoading">
    <template #default>
      <BuildingState v-if="commitmentsNotFound || emptyCommitments" />
      <div v-else-if="dashboard && mappedChartData">
        <PageHeader wrap-utility>
          <h1>Compute Commitments</h1>
          <template v-slot:utility>
            <CommitmentAsOfPill :data-through-date="dashboard.data_through_date" />
          </template>
        </PageHeader>
        <h2 class="commitmentsScopeHeader">Global</h2>

        <div class="row sectional summary">
          <div class="col commitmentLockInRisk">
            <PanelSection header="Commitment Lock-In Risk">
              <template v-slot:utility>
                <PanelLabel>Amortized</PanelLabel>
              </template>
              <CommitmentLockInRisk
                :value="overallClr"
                :baseline="dashboard.baseline_commitment_lock_in_risk"
                variant="term-optimized"
                class="fadeIn"
              />
            </PanelSection>
          </div>
          <div class="col commitmentLockInRiskDetail">
            <PanelSection header="Commitment Lock-In Risk Detail">
              <template v-slot:utility>
                <PanelLabel>Amortized</PanelLabel>
              </template>
              <CommitmentLockInRiskDetail :data="mappedChartData.commitmentLockInRiskDetail" class="fadeIn" />
            </PanelSection>
          </div>
        </div>

        <div class="row sectional">
          <div class="col">
            <PanelSection header="Commitment Burndown">
              <template v-slot:utility>
                <div class="d-flex">
                  <AggregationDropdown name="commitment_burndown" class="mr-2" @change="pivotChange" />
                  <AmortizationDropdown class="fixedWidthToggle" name="commitment_burndown" @change="facetChange" />
                </div>
              </template>
              <CommitmentBurndown
                :key="'commitment_burndown_' + facets.commitment_burndown + pivots.commitment_burndown"
                :commitment-burndown="burndown"
                :data-through-date="dashboard.data_through_date"
                class="fadeIn"
              />
            </PanelSection>
          </div>
        </div>

        <div class="row sectional">
          <div class="col">
            <PanelSection header="Total Remaining Commitment">
              <template v-slot:utility>
                <AmortizationDropdown
                  class="fixedWidthToggle"
                  name="total_remaining_commitment"
                  @change="facetChange"
                />
              </template>
              <TotalRemainingCommitment
                :key="'tot_rem_commitment_' + facets.total_remaining_commitment"
                :total-remaining-commitment="mappedChartData.totalRemainingCommitment"
                class="fadeIn"
              />
            </PanelSection>
          </div>
        </div>

        <div class="row sectional">
          <div class="col">
            <PanelSection header="Commitment Lock-In Risk Trend">
              <template v-slot:header-right>
                <InfoTooltip v-if="showClrTrendTooltip" tooltip-class="clrTrendHeaderTooltip">
                  You currently have less than 12 months of trend data. Commitment Lock-In Risk is measured and
                  persisted on a daily basis and your trend history will build over time.
                </InfoTooltip>
              </template>
              <template v-slot:utility>
                <PanelDropdown
                  v-if="showWadDropdown"
                  name="clr_trend"
                  :options="['Commitment Lock-In Risk', 'Weighted Average Duration']"
                  class="mr-2"
                  @change="value => (clrPivots.clrTrend = value.selected)"
                />
                <PanelLabel>Amortized</PanelLabel>
              </template>
              <WeightedAverageDurationTrend
                v-if="clrPivots.clrTrend === 'Weighted Average Duration'"
                :trend="mappedChartData.weightedAverageDurationTrend"
                :data-through-date="dashboard.data_through_date"
              />
              <CommitmentLockInRiskTrend
                v-else
                :baseline="dashboard.baseline_commitment_lock_in_risk"
                :baseline-date="dashboard.baseline_commitment_lock_in_risk_date"
                :trend="dashboard.commitment_lock_in_risk_trend"
                :data-through-date="dashboard.data_through_date"
              />
            </PanelSection>
          </div>
        </div>

        <h2 class="commitmentsScopeHeader">Regional</h2>
        <RegionalCommitments
          v-if="criExpirationTimelines"
          :cri-expiration-timelines="criExpirationTimelines"
          :data-through-date="dashboard.data_through_date"
        />
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.commitmentsScopeHeader {
  margin: 0.5rem 0 0;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.row.sectional + .commitmentsScopeHeader {
  margin-top: 2rem;
}

// Highcharts resizing via javascript breaks the layout with flexbox, the detail CLR detail loads fine but
// gets stuck on the second row when resizing up or down. Using css grid for full control over the columns/rows
$sidebar-and-padding: 375px;
$gauge-width: 430px;
$detail-min: 550px;

// account for the -15 margin on each side from the row class
$detail-offset: 15px * 2;

.row.summary {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $gauge-width + $detail-min + $sidebar-and-padding) {
    grid-template-columns: $gauge-width minmax($detail-min, 1fr);
  }
}

.commitmentLockInRisk {
  :deep(p) {
    max-width: 675px;
  }
}

.commitmentLockInRiskDetail {
  width: calc(100% - $detail-offset);
  padding: 0;
  margin-left: 15px;
  overflow: hidden;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}

.fadeIn {
  opacity: 1;
  animation: fade 1s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.fixedWidthToggle {
  width: 6rem;
}
</style>

<style lang="scss">
// Because the tooltip is rendered in a portal, we can't use scoped styles
.popover.clrTrendHeaderTooltip {
  max-width: 280px;
}
</style>
