<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState } from 'pinia';
import { mapGetters as vuexMapGetters } from 'vuex';

import customerService from '@console/services/customerService';
import { useAuthStore } from '@shared/state/auth.store';
import env from '@shared/utilities/env';

import PrepaymentAmortizationSummary from '@aws/components/adm/prepayments/PrepaymentAmortizationSummary.vue';
import PrepaymentInvoiceTable from '@aws/components/adm/prepayments/PrepaymentInvoiceTable.vue';
import PrepaymentInvoiceTableActions from '@aws/components/adm/prepayments/PrepaymentInvoiceTableActions.vue';
import TimeframeSelector from '@aws/components/adm/prepayments/TimeframeSelector.vue';
import BuildingState from '@console/components/BuildingState.vue';
import Layout from '@console/Layout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import BoxMessageV2 from '@shared/design/BoxMessageV2.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const isLocal = env.isLocal();

export default {
  components: {
    Layout,
    PageHeader,
    BoxMessage,
    BoxMessageV2,
    BuildingState,
    PanelSection,
    TimeframeSelector,
    PrepaymentInvoiceTable,
    PrepaymentInvoiceTableActions,
    PrepaymentAmortizationSummary,
  },
  data() {
    return {
      selectedTimeframe: null,
      prepayments: null,
      prepaymentsNotFound: false,
      anyExpanded: false,
      anyCollapsed: true,
    };
  },
  head: {
    title: 'Compute Prepayments',
  },
  computed: {
    ...vuexMapGetters('aws', ['selectedOrganization']),
    ...vuexMapGetters('customer', ['isDemo']),
    ...mapState(useAuthStore, ['isEmployee']),
    isLoading() {
      return this.prepayments === null && !this.prepaymentsNotFound;
    },
    hasInvoices() {
      const invoices = _.get(this.prepayments, 'dashboard.prepayment_invoices', []);
      return _.some(invoices);
    },
    prepaymentSummaryVisibleToEmployeesOnly() {
      if (!this.timeForNewPrepayments) {
        return false;
      }
      // Don't show incomplete/ineligible prepayment summary to potential customers while demoing
      if (this.isDemo) {
        return false;
      }

      return !this.customerVisible && this.prepayments.dashboard.amortization_summary && (isLocal || this.isEmployee);
    },
    timeForNewPrepayments() {
      const monthStart = moment.utc(this.prepayments.month_start);
      const firstSummaryDashboard = moment.utc('2023-03-01');
      return monthStart.isSameOrAfter(firstSummaryDashboard);
    },
    displayPrepaymentSummary() {
      return this.hasAmortizationSummary && (this.prepaymentSummaryVisibleToEmployeesOnly || this.customerVisible);
    },
    hasAmortizationSummary() {
      return _.has(this.prepayments.dashboard, 'amortization_summary');
    },
    customerVisible() {
      const amortizationSummary = _.get(this.prepayments.dashboard, 'amortization_summary', {});
      const isCustomerVisible = _.get(amortizationSummary, 'customer_visible', false);
      const beginningPrepaymentBalance = _.get(amortizationSummary, 'beginning_prepayment_balance', 0);
      const endingPrepaymentBalance = _.get(amortizationSummary, 'ending_prepayment_balance', 0);
      const newPrepaymentDeployed = _.get(amortizationSummary, 'new_prepayment_deployed', 0);
      const prepaymentConsumed = _.get(amortizationSummary, 'prepayment_consumed', 0);
      const allValuesAreZero =
        beginningPrepaymentBalance === 0 &&
        endingPrepaymentBalance === 0 &&
        newPrepaymentDeployed === 0 &&
        prepaymentConsumed === 0;

      if (!this.timeForNewPrepayments) {
        return false;
      }
      return isCustomerVisible && beginningPrepaymentBalance >= 0 && !allValuesAreZero;
    },
    isThroughDateLastDayOfMonth() {
      const monthStart = moment.utc(this.prepayments.month_start);
      const throughDate = moment.utc(this.prepayments.dashboard.data_through_date);
      const end = monthStart.clone().endOf('month');
      const ymdFormat = 'YYYYMMDD';
      return throughDate.format(ymdFormat) === end.format(ymdFormat);
    },
  },
  async mounted() {
    await this.load(this.$route.params.timeframe);
  },
  methods: {
    async load(timeframe) {
      try {
        const awsOrganizationId = this.selectedOrganization.id;
        const response = await customerService.getPrepayments(awsOrganizationId, timeframe);
        this.prepayments = response.data;

        if (this.isDemo) this.obfuscateDataForDemo();

        this.selectedTimeframe = this.prepayments.selected_timeframe;
      } catch (e) {
        this.handleError(e);
      }
    },
    obfuscateDataForDemo() {
      const invoices = this.prepayments?.dashboard?.prepayment_invoices || [];

      invoices.forEach((invoice, invoiceIndex) => {
        invoice.invoice_id = `${invoiceIndex + 1}`.padStart(10, 'X');

        invoice.invoice_details.forEach((detail, detailIndex) => {
          detail.resource_id = 'XXXXXXXX-XXXX-XXXX-XXXX-' + `${detailIndex + 1}`.padStart(12, 'X');
        });
      });
    },
    handleError(e) {
      const status = _.get(e, 'response.status', 500);
      if (status === 404) {
        this.prepaymentsNotFound = true;
      } else {
        throw e;
      }
    },
    onExpand() {
      this.anyExpanded = true;
      this.anyCollapsed = false;
      this.$refs.detailTable.expandAll();
    },
    onCollapse() {
      this.anyExpanded = false;
      this.anyCollapsed = true;
      this.$refs.detailTable.collapseAll();
    },
    onCsv() {
      const dashboard = this.prepayments.dashboard;
      const currTimeframe = this.selectedTimeframe.key;
      const invoices = dashboard.prepayment_invoices;
      const header = this.invoiceToCsvHeader();
      const lines = invoices.map(invoice => this.invoiceToCsvLines(invoice)).join('\n');
      const csvContent = `data:text/csv;charset=utf-8,${header}\n${lines}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `prosperops-prepayments-${currTimeframe}-${+new Date()}.csv`);
      document.body.appendChild(link);
      link.click();
    },
    invoiceToCsvHeader() {
      return ['invoice_id', 'resource_type', 'resource_id', 'start_date', 'end_date', 'cost'].join(',');
    },
    invoiceToCsvLines(invoice) {
      return invoice.invoice_details
        .map(detail => {
          return [
            invoice.invoice_id,
            detail.resource_type,
            detail.resource_id,
            detail.start_date,
            detail.end_date,
            detail.prepayment_amount,
          ].join(',');
        })
        .join('\n');
    },
    onExpandChanged({ anyExpanded, anyCollapsed }) {
      this.anyExpanded = anyExpanded;
      this.anyCollapsed = anyCollapsed;
    },
    async onChange(nextTimeframe) {
      const timeframe = nextTimeframe.key;
      await this.$router.push({ name: 'aws_compute_prepayments', params: { timeframe } });
    },
  },
};
</script>

<template>
  <Layout :loading="isLoading">
    <template #default>
      <BuildingState v-if="prepaymentsNotFound" />
      <div v-else class="h-100">
        <PageHeader wrap-utility>
          <h1>Compute Prepayments</h1>
          <template #utility>
            <TimeframeSelector
              :selected="selectedTimeframe"
              :timeframes="prepayments.available_timeframes"
              :finalized="prepayments.dashboard.is_finalized"
              :data-through-date="prepayments.dashboard.data_through_date"
              @change="onChange"
            />
          </template>
        </PageHeader>
        <div
          v-if="prepayments.prepayment_deployed_after_data_through_date && !isThroughDateLastDayOfMonth"
          class="row sectional"
        >
          <div class="col">
            <BoxMessage type="warning">
              <div>
                <strong
                  >Prepayment has been deployed since the <span class="font-italic">As of</span> date above which is not
                  yet reflected.</strong
                >
                Additional prepayment data will appear as it becomes available in AWS systems.
              </div>
            </BoxMessage>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <BoxMessageV2>
              <div>
                <p>
                  Prepaying commitments increases discount rates by paying for future usage in advance. This decouples
                  the timing of when usage is paid for from when it’s consumed, resulting in amortization. Because
                  ProsperOps dynamically manipulates commitments, the amount of amortization applied in future periods
                  can change. This page provides the information necessary to properly understand and account for
                  prepayment.
                </p>
                <p class="mb-0">
                  Note: Amounts shown do not include post-purchase adjustments such as AWS Private Pricing, EDPs,
                  refunds, credits, taxes, etc. Your actual AWS invoices and net amortized costs may vary.
                </p>
              </div>
            </BoxMessageV2>
          </div>
        </div>
        <div v-if="prepaymentSummaryVisibleToEmployeesOnly" class="row sectional">
          <div class="col">
            <BoxMessage type="error">
              <div>
                This month is not currently customer visible as either a) the ending balance is negative, b) the
                beginning prepayment balance is negative, or c) all prepayment values are $0
              </div>
            </BoxMessage>
          </div>
        </div>
        <div v-if="displayPrepaymentSummary" class="row sectional">
          <div class="col">
            <PanelSection header="Amortization Summary">
              <PrepaymentAmortizationSummary :amortization-summary="prepayments.dashboard.amortization_summary" />
            </PanelSection>
          </div>
        </div>
        <div v-if="hasInvoices" class="row sectional">
          <div class="col">
            <PanelSection header="New Prepayment Deployed Detail">
              <template #utility>
                <PrepaymentInvoiceTableActions
                  :any-expanded="anyExpanded"
                  :any-collapsed="anyCollapsed"
                  @expand="onExpand"
                  @collapse="onCollapse"
                  @csv="onCsv"
                />
              </template>
              <PrepaymentInvoiceTable ref="detailTable" :dashboard="prepayments.dashboard" @changed="onExpandChanged" />
            </PanelSection>
          </div>
        </div>
        <div v-if="!hasInvoices && !displayPrepaymentSummary" class="emptyState">
          <div class="text-center text-muted">
            <h1 class="mt-1 display-4">No prepayments this month</h1>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 300px;
}
</style>
