<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import NumberHelpers from '@shared/utilities/number_helpers';

import ExchangeIcon from '@shared/design/icons/ExchangeIcon.vue';
import LineUpIcon from '@shared/design/icons/LineUpIcon.vue';
import UtilizationIcon from '@shared/design/icons/UtilizationIcon.vue';
import MetricPanel from '@shared/design/panels/MetricPanel.vue';

const supportedMetrics = {
  portfolio_actions: {
    description: 'ProsperOps Portfolio Action',
    unit: 'count',
    icon: ExchangeIcon,
    checkPlural: true,
    tooltip:
      'The number of actions our service has taken to purchase, seed, optimize, ' +
      'grow, squash, roll, etc. your commitment portfolio. This reflects the amount ' +
      'of work our service has performed to deliver the savings outcome received.',
  },
  ec2_instance_changes: {
    description: 'EC2 Instance Change',
    unit: 'count',
    icon: LineUpIcon,
    checkPlural: true,
  },
  reserved_instance_utilization_percentage: {
    description: 'Reserved Instance Utilization',
    resellerDescription: 'Arbitrage Reserved Instance Utilization',
    unit: 'percentage',
    icon: UtilizationIcon,
  },
  savings_plan_utilization_percentage: {
    description: 'Savings Plan Utilization',
    resellerDescription: 'Arbitrage Savings Plan Utilization',
    unit: 'percentage',
    icon: UtilizationIcon,
  },
};

export default {
  components: {
    MetricPanel,
  },
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        if (!supportedMetrics[value]) {
          throw new Error(`Unknown savings metric with key: ${value}`);
        }
        return true;
      },
    },
    tooltipOverride: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: true,
      validator(value) {
        return ['primary', 'secondary', 'success', 'warning'].indexOf(value) !== -1;
      },
    },
    value: {
      type: Number,
      required: true,
    },
    useSmallerText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('customer', ['isReseller']),
    config() {
      return supportedMetrics;
    },
    unit() {
      return supportedMetrics[this.name].unit;
    },
    formattedValue() {
      if (this.unit === 'percentage') {
        if (this.value === 100 || this.value === '100') {
          return '100%';
        }
        return NumberHelpers.formatNumber(this.value, 1) + '%';
      }
      if (this.unit === 'count') {
        return NumberHelpers.formatNumber(this.value);
      }
      return this.value;
    },
    metricValueClasses() {
      return `text-${this.variant} ${this.useSmallerText ? 'metricValueSmall' : 'metricValue'}`;
    },
  },
  methods: {
    descriptionFor(name) {
      const checkPlural = _.get(this.config, [name, 'checkPlural'], false);

      if (this.isReseller && this.config[name].resellerDescription) {
        return this.config[name].resellerDescription;
      }

      if (checkPlural && this.value !== 1) {
        return `${this.config[name].description}s`;
      }
      return this.config[name].description;
    },
  },
};
</script>

<template>
  <MetricPanel
    :name="descriptionFor(name)"
    :color="variant"
    :icon="config[name].icon"
    :description="tooltipOverride ?? config[name].tooltip"
  >
    {{ formattedValue }}
    <slot name="value-icon" />
  </MetricPanel>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.metric {
  display: flex;
  align-items: center;
}

.metric > div:first-child {
  display: flex;
  justify-content: center;
  padding-right: 1rem;
  padding-left: 0.5rem;
  font-size: 2rem;
}

.metricValue {
  font-size: 2.5rem;
  line-height: 1.2;
}

.metricValueSmall {
  font-size: 2.5rem;

  @include media-breakpoint-only(xl) {
    font-size: 2rem;
  }

  line-height: 1.2;
}

.metricDescription {
  font-size: 0.9rem;
}
</style>
