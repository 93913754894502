<script>
import Layout from '@console/Layout.vue';

export default {
  components: {
    Layout,
  },
  head: {
    title: 'Session Ended',
  },
  methods: {
    close() {
      window.close();
    },
  },
};
</script>

<template>
  <Layout no-nav :with-announcement-banner="false">
    <template #default>
      <div class="d-flex justify-content-center align-items-center h-75">
        <div class="w-75">
          <h1 class="display-3 font-weight-bold textGradient">What happened!?!</h1>
          <p class="description textGradient">
            You federated into another customer's account and left this window open.
            <strong>You can only have one active session at a time.</strong>
          </p>
          <div class="pt-3">
            <BaseButton variant="danger" class="rounded-sm" @click="close">
              <BaseIcon name="times" class="mr-1" />
              Close Current Tab
            </BaseButton>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style scoped>
.description {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

.textGradient {
  color: #5c54ff;
  background: -webkit-linear-gradient(45deg, #5c54ff, #00c58c 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
