<script lang="ts" setup>
import type { EffectiveSavingsRate } from '@azure/services/savings.models';

import { computed } from 'vue';

import SolidGaugeChart from '@console/components/charts/SolidGaugeChart.vue';
import Percentage from '@shared/components/Percentage.vue';
import Modal from '@shared/design/Modal.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const props = defineProps<{
  data: EffectiveSavingsRate;
}>();

const currentEsr = computed(() => props.data.current * 100);
const previousEsr = computed(() => (props.data.previous ? props.data.previous * 100 : 0));
const hasPreviousEsr = computed(() => props.data.previous !== undefined && props.data.previous !== null);
</script>

<template>
  <PanelSection header="Effective Savings Rate">
    <div class="h-100">
      <Modal id="azure-prior-esr" size="lg" title="Effective Savings Rate before ProsperOps">
        <p>Your Effective Savings Rate for the 90 days prior to ProsperOps was:</p>
        <div class="pb-4 text-center display-4">
          <strong><Percentage :value="previousEsr" :decimals="1" :convert-to-percent="false" /> </strong>
        </div>
        <p>
          Note: This value assumes there were <strong>zero</strong> management costs (e.g. cloud management platform or
          other tooling/script development, people time, etc.) necessary to achieve this savings outcome. Effective
          Savings Rate after ProsperOps includes our savings share so is inclusive of management costs.
        </p>
      </Modal>
      <div class="currentEsr">
        <div>
          <SolidGaugeChart title="Savings Rate" :value="currentEsr" />
          <p>
            ProsperOps algorithms optimize for Effective Savings Rate. This metric best represents overall savings
            performance and always correlates with maximum savings.
            <a href="https://www.effectivesavingsrate.com/" class="ml-2" target="_blank">Learn More</a>
          </p>
          <p v-if="hasPreviousEsr" class="priorEsr mx-3 mt-3 pt-3 text-center">
            <a v-b-modal.azure-prior-esr href="#" class="text-muted" @click.prevent
              ><small>What was my Effective Savings Rate before ProsperOps?</small></a
            >
          </p>
        </div>
      </div>
    </div>
  </PanelSection>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.currentEsr {
  display: flex;
  flex-direction: column;
  height: 100%;

  > div:first-child {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;

    > p {
      padding-right: 1rem;
      padding-left: 1rem;
      margin-bottom: 0;
    }
  }

  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 1.5rem;
    text-align: center;
  }

  .priorEsr {
    padding: 1rem 0;
    margin: 2rem 2rem 0 2rem;
    border-top: 1px solid $gray-200;
  }
}
</style>
