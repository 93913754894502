<script setup lang="ts">
import type { AwsComputeSavingsDashboardResponse, MonthStart } from '@console/services/aws/savings.models';

import { useHead } from '@unhead/vue';
import _ from 'lodash';
import { computed, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';

import { isOrganizationComputeDashboard, isCustomerComputeDashboard } from '@console/services/aws/savings.models';
import { useVuexStore } from '@console/state/vuex/store';
import storage from '@shared/lib/local_storage';
import { dateFormat, dateUtc } from '@shared/utilities/filters';

import ArbitrageOpportunityChart from '@aws/components/adm/savings/ArbitrageOpportunityChart.vue';
import ArbitrageOpportunityTrendChart from '@aws/components/adm/savings/ArbitrageOpportunityTrendChart.vue';
import DailySavingsChart from '@aws/components/adm/savings/DailySavingsChart.vue';
import DailySpendCoverageChart from '@aws/components/adm/savings/DailySpendCoverageChart.vue';
import EffectiveSavingsRate from '@aws/components/adm/savings/EffectiveSavingsRate.vue';
import NetSavingsTrendChart from '@aws/components/adm/savings/NetSavingsTrendChart.vue';
import OrganizationCount from '@aws/components/adm/savings/OrganizationCount.vue';
import OrganizationSummary from '@aws/components/adm/savings/OrganizationSummary.vue';
import PortfolioAllocation from '@aws/components/adm/savings/PortfolioAllocation.vue';
import RiUtilizationMetric from '@aws/components/adm/savings/RiUtilizationMetric.vue';
import SavingsBreakdown from '@aws/components/adm/savings/SavingsBreakdown.vue';
import SavingsCallout from '@aws/components/adm/savings/SavingsCallout.vue';
import SavingsMetric from '@aws/components/adm/savings/SavingsMetric.vue';
import SavingsPlanUtilizationMetric from '@aws/components/adm/savings/SavingsPlanUtilizationMetric.vue';
import SpendCoverageBreakdown from '@aws/components/adm/savings/SpendCoverageBreakdown.vue';
import SpendCoverageTrendChart from '@aws/components/adm/savings/SpendCoverageTrendChart.vue';
import TimeframeSelector from '@console/components/savings/TimeframeSelector.vue';
import Toggle from '@shared/components/Toggle.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import Panel from '@shared/design/panels/Panel.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const store = useVuexStore();
const router = useRouter();

useHead({
  title: 'Compute Savings',
});

const props = defineProps<{
  savings: AwsComputeSavingsDashboardResponse;
  selectedTimeframe: MonthStart;
}>();

const savings = computed(() => props.savings);
const dashboard = computed(() => savings.value.dashboard);
const organizationDashboard = computed(() =>
  isOrganizationComputeDashboard(dashboard.value) ? dashboard.value : null
);

const selectedCompany = computed(() => store.getters['customer/selectedCompany']);
const isSettingEnabled = computed(() => store.getters['customer/isSettingEnabled']);
const isReseller = computed(() => store.getters['customer/isReseller']);
const isDemo = computed(() => store.getters['customer/isDemo']);

const selectedOrganization = computed(() => store.getters['aws/selectedOrganization']);
const selectedOrganizationActiveAccounts = computed(() => store.getters['aws/selectedOrganizationActiveAccounts']);
const savingsTimeframes = computed(() => store.getters['aws/savingsTimeframes']);
const selectedOrganizationIsCurDataSource = computed(() => store.getters['aws/selectedOrganizationIsCurDataSource']);
const isMultiOrgSummarySelected = computed(() => store.getters['aws/isMultiOrgSummarySelected']);

const hasSavingsPlan = computed(() => !!dashboard.value.key_metrics.savings_plan_utilization_percentage);

const hasDailySavings = computed(() => {
  const ds = dashboard.value.daily_savings;
  return ds.some(d => Object.entries(d).some(([field, value]) => field !== 'day' && !!value));
});

const metricColGridClasses = computed(() => {
  if (metricColumnCount.value === 3) {
    return 'col-lg-12 col-xl-4';
  }

  if (metricColumnCount.value === 4) {
    return containsLargeMetricValues.value ? 'col-lg-6' : 'col-lg-6 col-xl-3';
  }

  return 'col-xl-6';
});

const containsLargeMetricValues = computed(() => {
  const thresholdForLargeDigits = 5;
  const ec2InstanceChanges = _.get(dashboard.value, 'key_metrics.ec2_instance_changes', 0);
  const portfolioActions = _.get(dashboard.value, 'key_metrics.portfolio_actions', 0);
  const containsLargePortfolioActions = portfolioActions.toString().length > thresholdForLargeDigits;
  const containsLargeEc2InstanceChanges =
    !selectedOrganizationIsCurDataSource.value && ec2InstanceChanges.toString().length > thresholdForLargeDigits;

  return containsLargeEc2InstanceChanges || containsLargePortfolioActions;
});

const useSmallerMetricText = computed(() => {
  const hasFourColumnsWithSmallNumbers = metricColumnCount.value === 4 && !containsLargeMetricValues.value;
  const hasThreeColumnsWithLargeNumbers = metricColumnCount.value === 3 && containsLargeMetricValues.value;

  return hasFourColumnsWithSmallNumbers || hasThreeColumnsWithLargeNumbers;
});

const metricColumnCount = computed(() => {
  if (!selectedOrganizationIsCurDataSource.value && hasSavingsPlan.value) {
    return 4;
  }

  if (
    (selectedOrganizationIsCurDataSource.value && hasSavingsPlan.value) ||
    (!selectedOrganizationIsCurDataSource.value && !hasSavingsPlan.value)
  ) {
    return 3;
  }

  return 2;
});

const hasArbitrageData = computed(
  () => dashboard.value.arbitrage_opportunity_trend && dashboard.value.arbitrage_opportunity
);

const onChange = async (nextTimeframe: MonthStart) => {
  const timeframe = nextTimeframe.key;
  await router.push({ name: 'aws_compute_savings', params: { timeframe }, query: router.currentRoute.value.query });
};

const resellerPrefix = (text: string) => (isReseller.value ? `Arbitrage ${text}` : text);

const formatDate = (date: string, format = 'MMMM D, YYYY') => dateFormat(dateUtc(date), format);

const hasNonCommitmentDiscounts = computed(() => {
  const orgDashboard = dashboard.value;
  if (
    isMultiOrgSummarySelected.value ||
    !isOrganizationComputeDashboard(orgDashboard) ||
    !isSettingEnabled.value('EnableAwsFullDiscountExperience')
  ) {
    return false;
  }

  const breakdownCommitmentDiscounts = orgDashboard.savings_breakdown_periods;
  const breakdownAllDiscounts = orgDashboard.savings_breakdown_periods_all_discounts;

  // TODO: CG - move to BE, verify with product if this should be just discounts or include usage as well
  // Only show the toggle if there exists a discount not found in the commitment discounts
  return breakdownAllDiscounts?.some(period => {
    const commitmentPeriod = breakdownCommitmentDiscounts.find(
      commitmentPeriod => commitmentPeriod.sort_order === period.sort_order
    );
    return (
      commitmentPeriod &&
      (period.gross_savings !== commitmentPeriod.gross_savings || period.net_savings !== commitmentPeriod.net_savings)
    );
  });
});

const storageKey = 'savings_showAllDiscounts';
const showAllDiscounts = ref(false);

const onDiscountExperienceToggle = (enabled: boolean) => {
  showAllDiscounts.value = enabled;
  storage.set(storageKey, enabled);
};

onBeforeMount(() => {
  const storedValue = storage.get<boolean>(storageKey);
  if (
    storedValue !== undefined &&
    isOrganizationComputeDashboard(dashboard.value) &&
    dashboard.value.has_non_commitment_discount_savings
  ) {
    showAllDiscounts.value = storedValue;
  }
});

const keySuffix = computed(() => (showAllDiscounts.value ? '_allDiscounts' : ''));

const filteredCharts = computed(() => {
  if (isOrganizationComputeDashboard(dashboard.value) && showAllDiscounts.value) {
    return {
      dailySavings: dashboard.value.daily_savings_all_discounts!,
      netSavingsTrend: dashboard.value.net_savings_trend_all_discounts!,
      portfolioAllocation: dashboard.value.portfolio_distribution_all_discounts!,
      dailySpendCoverage: dashboard.value.daily_spend_coverage_all_discounts!,
      spendCoverageBreakdown: dashboard.value.spend_coverage_summary_all_discounts!,
      spendCoverageTrend: dashboard.value.spend_coverage_trend_all_discounts!,
    };
  }
  return {
    dailySavings: dashboard.value.daily_savings,
    netSavingsTrend: dashboard.value.net_savings_trend,
    portfolioAllocation: dashboard.value.portfolio_distribution,
    dailySpendCoverage: dashboard.value.daily_spend_coverage,
    spendCoverageBreakdown: dashboard.value.spend_coverage_summary,
    spendCoverageTrend: dashboard.value.spend_coverage_trend,
  };
});

const computeUsage = computed(
  () => dashboard.value?.savings_breakdown_periods.find(b => b.sort_order === 2)?.compute_usage
);
</script>

<template>
  <div>
    <PageHeader wrap-utility>
      <div class="d-flex gap-3 align-items-center mr-2">
        <h1>Compute Savings</h1>
        <div v-if="hasNonCommitmentDiscounts" class="discountExperienceToggleContainer">
          <Toggle
            class="discountExperienceToggle"
            :enabled="showAllDiscounts"
            off="RI/SP Only"
            on="All Discounts"
            @change="onDiscountExperienceToggle"
          />
        </div>
      </div>
      <template v-slot:utility>
        <TimeframeSelector
          :selected="selectedTimeframe"
          :timeframes="savingsTimeframes"
          :finalized="dashboard.is_finalized"
          :data-through-date="dashboard.data_through_date"
          @change="onChange"
        />
      </template>
    </PageHeader>
    <div v-if="organizationDashboard?.subscription_start_date" class="row sectional">
      <div class="col">
        <BoxMessage type="info">
          <strong
            >{{ formatDate(organizationDashboard.subscription_start_date) }} was your first full day with the ProsperOps
            service enabled.</strong
          >
          This month is considered a transition month as the savings results reflect a blend of before and after
          ProsperOps management.
        </BoxMessage>
      </div>
    </div>
    <div v-if="organizationDashboard?.savings_impacted_by_inherited" class="row sectional">
      <div class="col">
        <BoxMessage type="warning">
          <div v-if="dashboard.is_finalized">
            <strong
              >Savings performance was impacted by unutilized Standard RIs and/or EC2 Instance Savings Plans which were
              inherited.</strong
            >
            This will continue until these discount instruments expire or are otherwise disposed of.
          </div>
          <div v-else>
            <strong
              >Savings performance is impacted by unutilized Standard RIs and/or EC2 Instance Savings Plans which were
              inherited.</strong
            >
            This will continue until these discount instruments expire or are otherwise disposed of. Please
            <router-link :to="{ name: 'help' }">contact us</router-link>
            for further guidance.
          </div>
        </BoxMessage>
      </div>
    </div>
    <div class="row sectional">
      <div class="col">
        <Panel>
          <SavingsCallout
            :savings="savings"
            :all-discounts="showAllDiscounts"
            :organization="selectedOrganization"
            :aws-accounts="selectedOrganizationActiveAccounts"
            :demo="isDemo"
          />
        </Panel>
      </div>
    </div>
    <div class="row sectional summary">
      <div v-if="isMultiOrgSummarySelected && isCustomerComputeDashboard(dashboard)" class="col-xl-auto">
        <div>
          <PanelSection header="Effective Savings Rate" class="effectiveSavingsRate">
            <EffectiveSavingsRate service="compute" :data="dashboard.effective_savings_rate" :default-max="50" />
          </PanelSection>
        </div>
        <div class="organizationCountWidget">
          <OrganizationCount :organization-summary="dashboard.organization_summary" />
        </div>
      </div>
      <div v-else-if="organizationDashboard" class="col-xl-auto effectiveSavingsRate">
        <PanelSection header="Effective Savings Rate">
          <EffectiveSavingsRate
            :key="'esr' + keySuffix"
            service="compute"
            :data="organizationDashboard.effective_savings_rate"
            :pre-subscription-esr-days="organizationDashboard.pre_subscription_effective_savings_rate_days"
            :default-max="showAllDiscounts ? 60 : 50"
            :show-all-discounts="showAllDiscounts"
            :has-non-commitment-discounts="hasNonCommitmentDiscounts"
          />
        </PanelSection>
      </div>
      <div class="col">
        <SavingsBreakdown :all-discounts="showAllDiscounts" :savings="savings" :demo="isDemo" service="compute" />
      </div>
    </div>
    <div v-if="isMultiOrgSummarySelected" class="row sectional">
      <div class="col">
        <OrganizationSummary :savings="savings" :demo="isDemo" :selected-timeframe="selectedTimeframe" />
      </div>
    </div>
    <div v-if="isReseller && hasArbitrageData" class="row sectional stack-sectional">
      <div class="col">
        <PanelSection header="Arbitrage Opportunity Trend">
          <ArbitrageOpportunityTrendChart
            v-if="dashboard.arbitrage_opportunity_trend"
            :arbitrage-opportunity-trend="dashboard.arbitrage_opportunity_trend"
          />
        </PanelSection>
      </div>
      <div class="col">
        <PanelSection header="Arbitrage Opportunity">
          <ArbitrageOpportunityChart
            v-if="dashboard.arbitrage_opportunity"
            :arbitrage-opportunity="dashboard.arbitrage_opportunity"
          />
        </PanelSection>
      </div>
    </div>
    <div class="row sectional">
      <div v-if="!selectedOrganizationIsCurDataSource" :class="metricColGridClasses">
        <SavingsMetric
          name="ec2_instance_changes"
          variant="warning"
          :value="dashboard.key_metrics.ec2_instance_changes"
          :use-smaller-text="useSmallerMetricText"
          :company="selectedCompany"
        />
      </div>
      <div :class="metricColGridClasses">
        <SavingsMetric
          name="portfolio_actions"
          variant="success"
          :value="dashboard.key_metrics.portfolio_actions"
          :use-smaller-text="useSmallerMetricText"
          :company="selectedCompany"
        />
      </div>
      <div :class="metricColGridClasses">
        <RiUtilizationMetric :savings="savings" :company="selectedCompany" :use-smaller-text="useSmallerMetricText" />
      </div>
      <div v-if="hasSavingsPlan" :class="metricColGridClasses">
        <SavingsPlanUtilizationMetric
          name="savings_plan_utilization_percentage"
          variant="secondary"
          :company="selectedCompany"
          :savings="savings"
          :use-smaller-text="useSmallerMetricText"
        />
      </div>
    </div>
    <div v-if="hasDailySavings" class="row sectional">
      <div class="col">
        <PanelSection :header="resellerPrefix('Daily Savings')">
          <DailySavingsChart
            :key="'daily_savings' + keySuffix"
            :all-discounts="showAllDiscounts"
            :daily-savings="filteredCharts.dailySavings"
            :dashboard-variant="dashboard.variant"
            :month-start="savings.month_start"
            service="compute"
          />
        </PanelSection>
      </div>
    </div>
    <div class="row sectional">
      <div class="col">
        <PanelSection :header="resellerPrefix('Net Savings Trend')">
          <NetSavingsTrendChart
            :key="'net_savings_trend' + keySuffix"
            :all-discounts="showAllDiscounts"
            :net-savings-trend="filteredCharts.netSavingsTrend"
            :empty-y-axis-max="computeUsage"
            service="compute"
          />
        </PanelSection>
      </div>
    </div>
    <div class="row sectional stack-sectional">
      <div class="col">
        <PanelSection :header="resellerPrefix('Portfolio Allocation')">
          <PortfolioAllocation
            v-if="dashboard.portfolio_distribution"
            :key="'portfolio_allocation' + keySuffix"
            :all-discounts="showAllDiscounts"
            :portfolio-allocation="filteredCharts.portfolioAllocation"
            :dashboard-variant="dashboard.variant"
            service="compute"
          />
        </PanelSection>
      </div>
      <div class="col">
        <PanelSection :header="resellerPrefix('Spend Coverage')" class="spendCoverageBreakdown">
          <SpendCoverageBreakdown
            v-if="dashboard.spend_coverage_summary"
            :spend-coverage="filteredCharts.spendCoverageBreakdown"
            :dashboard-variant="dashboard.variant"
            :finalized="dashboard.is_finalized"
            service="compute"
          />
        </PanelSection>
      </div>
    </div>
    <div class="row sectional">
      <div class="col">
        <PanelSection :header="resellerPrefix('Daily Spend Coverage')">
          <DailySpendCoverageChart
            :key="'daily_spend_coverage' + keySuffix"
            :all-discounts="showAllDiscounts"
            :month-start="savings.month_start"
            :daily-spend-coverage="filteredCharts.dailySpendCoverage"
            :dashboard-variant="dashboard.variant"
            service="compute"
          />
        </PanelSection>
      </div>
    </div>
    <div class="row sectional">
      <div class="col">
        <PanelSection :header="resellerPrefix('Spend Coverage Trend')">
          <SpendCoverageTrendChart
            :key="'spend_coverage_trend' + keySuffix"
            :all-discounts="showAllDiscounts"
            :spend-coverage-trend="filteredCharts.spendCoverageTrend"
            :dashboard-variant="dashboard.variant"
            :empty-y-axis-max="computeUsage"
            service="compute"
          />
        </PanelSection>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.row.summary {
  display: flex;

  > div:first-child {
    flex-basis: auto;

    @include media-breakpoint-up(xl) {
      flex-basis: 380px;
    }
  }
}

.metricPanel {
  min-width: 160px;
}

.organizationCountWidget {
  margin-top: 30px;
}

.effectiveSavingsRate > :deep(section) {
  padding-top: 0;
}

.effectiveSavingsRate {
  max-height: 560px;
}

.spendCoverageBreakdown {
  height: auto; // Don't expand the panel to match the Portfolio Allocation panel's height
}

@include media-breakpoint-only(xl) {
  .metricPanelLargeNumbers {
    padding: 0;
    margin: 0;
  }

  .metricPanelSmallNumbers {
    padding: 0;
    margin-right: 4%;
    margin-left: 4%;
  }

  .colPadding {
    padding: 0;
  }
}

.discountExperienceToggleContainer {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
