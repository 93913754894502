<script>
import _ from 'lodash';
import Vue from 'vue';

import PrepaymentInvoiceDetailTable from '@aws/components/adm/prepayments/PrepaymentInvoiceDetailTable.vue';
import Currency from '@shared/components/Currency.vue';

export default {
  components: {
    Currency,
    PrepaymentInvoiceDetailTable,
  },
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: {},
    };
  },
  mounted() {
    this.collapseAll();
  },
  methods: {
    onClick(invoice) {
      const current = this.expanded[invoice.invoice_id];
      Vue.set(this.expanded, invoice.invoice_id, !current);
      const states = _.values(this.expanded);
      const anyExpanded = _.some(states, _.identity);
      const anyCollapsed = _.some(states, state => !state);
      this.$emit('changed', { anyExpanded, anyCollapsed });
    },
    expandAll() {
      for (const invoice of this.dashboard.prepayment_invoices) {
        Vue.set(this.expanded, invoice.invoice_id, true);
      }
    },
    collapseAll() {
      for (const invoice of this.dashboard.prepayment_invoices) {
        Vue.set(this.expanded, invoice.invoice_id, false);
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="header text-muted text-uppercase font-weight-normal d-flex">
      <div class="colInvoiceId">AWS Invoice ID</div>
      <div class="flex-grow-1"></div>
      <div class="colAmount">Prepay Amount</div>
    </div>
    <div v-for="invoice in dashboard.prepayment_invoices" :key="invoice.invoice_id" class="invoice">
      <BaseButton variant="transparent" class="expandible btn-no-focus-box-shadow" @click="onClick(invoice)">
        <div class="text-center">
          <BaseIcon name="angle-down" :class="{ expanded: expanded[invoice.invoice_id] }" />
        </div>
        <div class="colInvoiceId">
          {{ invoice.invoice_id }}
        </div>
        <div class="flex-grow-1"></div>
        <div class="colAmount font-weight-bold">
          <Currency :value="invoice.prepayment_total_amount" />
        </div>
      </BaseButton>
      <b-collapse v-model="expanded[invoice.invoice_id]">
        <PrepaymentInvoiceDetailTable :invoice="invoice" resource-type="reserved_instance" class="mb-2" />
        <PrepaymentInvoiceDetailTable :invoice="invoice" resource-type="savings_plan" />
      </b-collapse>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.header {
  background-color: $table-head-background;

  > .colInvoiceId {
    margin-left: 30px;
  }

  > div {
    padding: 18px 12px;
  }
}

.invoice {
  .colInvoiceId {
    text-align: left;
  }
}

.invoice:not(:last-child) {
  border-bottom: 1px solid #e9ecef;
}

.expandible {
  display: flex;
  width: 100%;
  padding: 18px 12px;

  > div:first-child {
    width: 30px;
    color: #6c757d;
  }
}

.expandible:hover {
  cursor: pointer;

  > div:first-child {
    color: #a7a3ff;
  }
}

.colInvoiceId {
  width: 150px;
}

.colAmount {
  width: 150px;
  text-align: right;
}

.expanded {
  transform: scaleY(-1);
}
</style>
