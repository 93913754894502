<script lang="ts" setup>
import type { Timeframe } from '@shared/design/TimeframeDropdown.vue';

import ShowbackAsOfPill from '@aws/components/adm/showback/ShowbackAsOfPill.vue';
import TimeframeDropdown from '@shared/design/TimeframeDropdown.vue';

defineProps<{
  selected: Timeframe;
  timeframes: Timeframe[];
}>();

const emits = defineEmits<{
  (e: 'change', newTimeframe: Timeframe): void;
}>();

const change = (newTimeframe: Timeframe) => emits('change', newTimeframe);
</script>

<template>
  <div class="timeframeSelector">
    <div>
      <ShowbackAsOfPill />
    </div>
    <div>
      <TimeframeDropdown :selected="selected" :timeframes="timeframes" @change="change" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.timeframeSelector {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
  }
}

.timeframeSelector > div:first-child {
  padding-top: 0.38rem;
  margin-right: 0;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    margin-right: 0.75rem;
  }
}
</style>
