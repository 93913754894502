<script>
import CopyButton from '@shared/design/CopyButton.vue';

export default {
  components: {
    CopyButton,
  },
  props: {
    codeAsText: {
      type: String,
      required: true,
    },
    codeSource: {
      type: String,
      required: true,
    },
    headerText: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: '500px',
    },
  },
};
</script>

<template>
  <div class="text-white">
    <div class="topBar">
      <div class="topBarHeader">
        {{ headerText }}
      </div>
      <div>
        <div>
          <slot name="filter"></slot>
        </div>
        <div>
          <CopyButton :key="codeSource" :text="codeAsText" />
        </div>
      </div>
    </div>
    <div class="codePreview">
      <pre>{{ codeAsText }}</pre>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.codePreview {
  background-image: linear-gradient(to bottom right, $gray-700, $gray-800);
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.codePreview pre {
  height: v-bind(height);
  padding: 0.8rem;
  margin: 0;
  overflow: scroll;
  color: white;
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
}

.codePreview pre::-webkit-scrollbar {
  width: 1em;
}

.codePreview pre::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.codePreview pre::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.3);
}

.codePreview pre::-webkit-scrollbar-thumb {
  background-color: $gray-500;
  outline: 1px solid $gray-500;
}

.topBar {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0.5rem 0.8rem;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: $gray-600;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;

  > div:first-child {
    flex-grow: 1;
    font-size: 0.8rem;
    font-weight: 500;
  }

  > div:last-child {
    display: flex;
    align-items: center;
  }
}
</style>
