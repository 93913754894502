<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import awsService from '@aws/services/awsService';
import { useVuexStore } from '@console/state/vuex/store';

import CodePreview from '@shared/design/CodePreview.vue';

defineProps<{ value?: string }>();

const vuexStore = useVuexStore();

const awsAccountFriendlyName = computed(
  () => vuexStore.getters['aws/selectedOrganizationManagementAccount']?.friendly_name
);
const awsAccountNumber = computed(
  () => vuexStore.getters['aws/selectedOrganizationManagementAccount']?.aws_account_number
);
const permissionRole = ref('');
const permissionPolicy = ref('');

onMounted(async () => {
  const iamDetails = await awsService.getIamDetails(vuexStore.getters['aws/selectedOrganizationManagementAccount'].id);
  permissionRole.value = iamDetails.iam_role_name;
  permissionPolicy.value =
    iamDetails.configuration_variants.find(v => v.configuration_variant === 'AWSConsole')?.configuration ?? '';
});
</script>

<template>
  <div class="stepTwo">
    <div class="mb-3">
      Additional IAM permissions are required for ProsperOps to write data to the specified S3 bucket. Please change the
      IAM policy on the following role to the updated policy below. Once complete, click the
      <strong>Validate & Enable</strong> button.
    </div>
    <div>
      <ul>
        <li>
          AWS Account: <strong> {{ awsAccountFriendlyName }}</strong> ({{ awsAccountNumber }})
        </li>
        <li>
          Role: <strong>{{ permissionRole }}</strong>
        </li>
      </ul>
      <div>
        <CodePreview
          code-source="AWSConsole"
          :code-as-text="permissionPolicy"
          :header-text="`${permissionRole} IAM Policy`"
          :height="'300px'"
        />
      </div>
    </div>
  </div>
</template>
