import type { RouteRecordRaw } from 'vue-router';

import admComputeRoutes from './adm_compute_routes';
import admNonComputeRoutes from './adm_non_compute_routes';
import { hasSingleOrgSelected, notNewOrganization } from '../requirements';
import {
  requires,
  hasRoles,
  selectedCompany,
  atLeastRole,
  activeSubscription,
} from '@console/router/requirements';
import { PermissionLevel } from '@shared/state/auth.store';

import Showback from '@aws/views/adm/Showback.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/aws/showback/:timeframe?',
    name: 'aws_showback',
    component: Showback,
    beforeEnter: requires(
      hasRoles,
      selectedCompany,
      activeSubscription,
      atLeastRole(PermissionLevel.Viewer),
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      initModules: true,
      useNewLayout: true,
    },
  },

  ...admComputeRoutes,
  ...admNonComputeRoutes,
];

export default routes;
