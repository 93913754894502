<script setup lang="ts">
// refer to src\aws\views\compute\Savings.vue
import type { ComputeSavingsDashboardResponse } from '@azure/services/savings.models';
import type { MonthStart } from '@console/services/api.models';

import { useHead } from '@unhead/vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

// import { useAzureStore } from '@azure/state';
import { useVuexStore } from '@console/state/vuex/store';

import EffectiveSavingsRate from '@azure/components/savings/EffectiveSavingsRate.vue';
import PortfolioActions from '@azure/components/savings/PortfolioActions.vue';
import ReservationUtilization from '@azure/components/savings/ReservationUtilization.vue';
import SavingsBreakdown from '@azure/components/savings/SavingsBreakdown.vue';
import SavingsCallout from '@azure/components/savings/SavingsCallout.vue';
import SavingsPlanUtilization from '@azure/components/savings/SavingsPlanUtilization.vue';
import TimeframeSelector from '@console/components/savings/TimeframeSelector.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';

const router = useRouter();
const store = useVuexStore();
// const azureStore = useAzureStore();

useHead({
  title: 'Compute Savings',
});

const props = defineProps<{
  savings: ComputeSavingsDashboardResponse;
  selectedTimeframe: MonthStart;
}>();

const savings = computed(() => props.savings);
const dashboard = computed(() => savings.value.dashboard);
const customerVisible = computed(() => savings.value.customer_visible ?? false);
const savingsTimeframes = computed(() => props.savings.available_timeframes);
const hasPortfolioActions = computed(() => props.savings.dashboard.key_metrics?.action_count > 0);

// const selectedCompany = computed(() => store.getters['customer/selectedCompany']);
const isDemo = computed(() => store.getters['customer/isDemo']);
// const selectedBillingScope = computed(() => {
//   const billingScopeId = azureStore.billingScopeId();
//   return azureStore.getBillingScopeById(billingScopeId);
// });

// const metricColGridClasses = computed(() => {
//   if (metricColumnCount.value === 3) {
//     return 'col-lg-12 col-xl-4';
//   }
//   if (metricColumnCount.value === 4) {
//     return containsLargeMetricValues.value ? 'col-lg-6' : 'col-lg-6 col-xl-3';
//   }
//   return 'col-xl-6';
// });

// const containsLargeMetricValues = computed(() => false);

// const useSmallerMetricText = computed(() => {
//   const hasFourColumnsWithSmallNumbers = metricColumnCount.value === 4 && !containsLargeMetricValues.value;
//   const hasThreeColumnsWithLargeNumbers = metricColumnCount.value === 3 && containsLargeMetricValues.value;
//   return hasFourColumnsWithSmallNumbers || hasThreeColumnsWithLargeNumbers;
// });

// const metricColumnCount = computed(() => 3);

const onChange = async (nextTimeframe: MonthStart) => {
  const timeframe = nextTimeframe.key;
  await router.push({ name: 'azure_compute_savings', params: { timeframe }, query: router.currentRoute.value.query });
};
</script>

<template>
  <div>
    <PageHeader wrap-utility>
      <h1>Compute Savings</h1>
      <template v-slot:utility>
        <TimeframeSelector
          :selected="selectedTimeframe"
          :timeframes="savingsTimeframes"
          :finalized="dashboard.is_finalized"
          :data-through-date="dashboard.data_through_date"
          @change="onChange"
        />
      </template>
    </PageHeader>
    <div v-if="!customerVisible" class="row sectional">
      <div class="col">
        <BoxMessage type="error">
          <div>This month is not currently customer visible.</div>
        </BoxMessage>
      </div>
    </div>
    <div class="row sectional">
      <div class="col">
        <Panel>
          <SavingsCallout
            :total-savings="dashboard?.savings_summary.total_savings"
            :month="dashboard.data_through_date"
            :is-finalized="dashboard.is_finalized"
          />
        </Panel>
      </div>
    </div>
  </div>
  <div class="row sectional">
    <div class="col-xl-auto effectiveSavingsRate">
      <EffectiveSavingsRate :data="dashboard?.effective_savings_rate"></EffectiveSavingsRate>
    </div>
    <div class="col">
      <SavingsBreakdown :savings="savings" :demo="isDemo" service="compute" />
    </div>
  </div>
  <div class="row sectional">
    <div v-if="hasPortfolioActions" class="col-lg">
      <PortfolioActions :count="dashboard.key_metrics.action_count" />
    </div>
    <div class="col-lg">
      <ReservationUtilization :utilization="dashboard.key_metrics.reservation_utilization_percentage" />
    </div>
    <div class="col-lg">
      <SavingsPlanUtilization :utilization="dashboard.key_metrics.savings_plan_utilization_percentage" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.row.summary {
  display: flex;

  > div:first-child {
    flex-basis: auto;

    @include media-breakpoint-up(xl) {
      flex-basis: 380px;
    }
  }
}

.metricPanel {
  min-width: 160px;
}

.organizationCountWidget {
  margin-top: 30px;
}

.effectiveSavingsRate > :deep(section) {
  padding-top: 0;
}

.effectiveSavingsRate {
  flex-basis: auto;
  max-height: 560px;

  @include media-breakpoint-up(xl) {
    flex-basis: 380px;
  }
}

.spendCoverageBreakdown {
  height: auto; // Don't expand the panel to match the Portfolio Allocation panel's height
}

@include media-breakpoint-only(xl) {
  .metricPanelLargeNumbers {
    padding: 0;
    margin: 0;
  }

  .metricPanelSmallNumbers {
    padding: 0;
    margin-right: 4%;
    margin-left: 4%;
  }

  .colPadding {
    padding: 0;
  }
}
</style>
