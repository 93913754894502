<script setup>
// import Tooltip from '@shared/design/Tooltip.vue';
import moment from 'moment';

import RegionName from '@aws/components/RegionName.vue';
import InfoModal from '@shared/design/InfoModal.vue';
import LastUpdated from '@shared/design/panels/LastUpdated.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const props = defineProps({
  settings: {
    type: Object,
    required: true,
  },
});

const getValueOrGlobal = (setting, key) => {
  const stringValue = setting[key] ?? props.settings.global_settings[key];
  switch (key) {
    case 'MinimumCoverage':
      return stringValue + '%';
    case 'PaymentTermPreference':
      return stringValue;
    default:
      return stringValue;
  }
};

const getVacateByDate = settings => {
  const stringValue = settings.VacateByDate;
  return stringValue ? Intl.DateTimeFormat().format(moment(stringValue).toDate()) : '-';
};
</script>

<template>
  <PanelSection new-header :underlined="false" class="matchKeyPanel">
    <template v-slot:utility>
      <LastUpdated :value="settings.match_key_settings_updated_date" />
    </template>
    <template v-slot:header>
      <div id="matchKeyPanelHeader">Match Key</div>
      <!-- TODO: Waiting on help center article to be published
        <Tooltip target="matchKeyPanelHeader">
        <div class="matchKeyTooltipContents">
          <a
            href="https://www.prosperops.com/blog/how-to-maximize-cloud-savings-on-cyclical-workloads-using-commitment-based-discounts/"
            target="_blank"
            rel="noopener noreferrer"
            class="text-nowrap"
            >Learn more</a
          >
          about the concept of match key.
        </div>
      </Tooltip> -->
    </template>
    <div v-if="!settings.match_key_settings.length" class="noData text-muted">No match key settings configured.</div>
    <div v-else class="tableWrapper">
      <table class="table table-striped matchKeyTable">
        <thead>
          <tr class="matchKeyHeader">
            <th>Region</th>
            <th>Match Key</th>
            <th>
              Mode
              <InfoModal title="Match Key Modes" :modal-id="matchKeyModalId" class="text-dark">
                <p>
                  <strong>Smart:</strong> Usage for a specific match key is continually analyzed by ProsperOps and
                  autonomous discount management automatically starts/stops based on changing usage patterns, scale,
                  stability, etc. ProsperOps algorithms automatically determine and adapt the size and spread of
                  commitment coverage.
                </p>
                <p>
                  <strong>Enabled:</strong> Usage for a specific match key is continually analyzed by ProsperOps, but
                  unlike Smart mode, ProsperOps will not automatically stop managing commitments for match keys with
                  small amounts of usage. Use this mode when you are certain small amounts of usage will persist.
                </p>
                <p><strong>Disabled:</strong> ProsperOps will not manage commitments regardless of match key usage.</p>
              </InfoModal>
            </th>
            <!-- force 2 lines to save horizontal space -->
            <th class="text-center" style="width: 95px">Minimum Coverage</th>
            <th class="text-center">RI Preference</th>
            <!-- force 2 lines to save horizontal space -->
            <th class="text-center" style="width: 97px">Vacate by Date</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="setting in props.settings.match_key_settings"
            :key="`${setting.region}:${setting.match_key}_row`"
          >
            <tr>
              <td>
                <RegionName :region="setting.region" :inline-system-name="false" />
              </td>
              <td>{{ setting.match_key.split(':').slice(1).join(':') }}</td>
              <td>{{ setting.settings.Mode }}</td>
              <template v-if="setting.settings.Mode !== 'Disabled'">
                <td class="text-center" :class="{ global: !setting.settings.MinimumCoverage }">
                  {{ getValueOrGlobal(setting.settings, 'MinimumCoverage') }}
                </td>
                <td class="text-center" :class="{ global: !setting.settings.PaymentTermPreference }">
                  {{ getValueOrGlobal(setting.settings, 'PaymentTermPreference') }}
                </td>
                <td class="text-center">{{ getVacateByDate(setting.settings) }}</td>
              </template>
              <template v-else>
                <td></td>
                <td></td>
                <td></td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </PanelSection>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.matchKeyTooltipContents {
  // the tooltip text is one word longer than the max tooltip length
  // set the width smaller so the first and second line are roughly equal in length
  width: 140px;
}

.tableWrapper {
  overflow: auto;
}

.matchKeyTable {
  width: 100%;
  min-width: max-content;
  max-height: 300px;
  margin-bottom: 0;

  td,
  th {
    align-content: center;
  }
}

.matchKeyHeader {
  font-size: 15px;
}

.regionSystemName {
  font-size: 10px;
  font-weight: 400;
}

.global {
  color: $gray-500;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
}

#matchKeyPanelHeader {
  flex: 1 0 auto;
  font-weight: 500;
  vertical-align: top;
}
</style>
