<script lang="ts" setup>
import type { AwsAccount, IamDetails } from '@console/services/api.models';
import type { BootstrapVariant } from '@shared/design/bootstrap';

import { useHead } from '@unhead/vue';
import { isAxiosError } from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import awsService from '@aws/services/awsService';
import customerService from '@console/services/customerService';
import { useVuexStore } from '@console/state/vuex/store';
import { useFeatureStore } from '@shared/state/feature.store';

import ErrorMessageByCode from '@aws/components/accounts/ErrorMessageByCode.vue';
import SecurityFooter from '@aws/components/accounts/SecurityFooter.vue';
import SetupDirections from '@aws/components/accounts/SetupDirections.vue';
import Layout from '@console/Layout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import Pill from '@shared/design/Pill.vue';

useHead({
  title: 'AWS Account',
});

const store = useVuexStore();
const awsAccounts = computed<AwsAccount[]>(() => store.state.aws.awsAccounts);
const selectedOrganizationHasOnboarded = computed<boolean>(() => store.getters['aws/selectedOrganizationHasOnboarded']);

const featureStore = useFeatureStore();

const router = useRouter();
const route = useRoute();
const awsAccountId = computed<string>(() => {
  // route params can be an array but we never actually use it like that for awsAccountId
  // so we are coercing to a string here.
  if (typeof route.params.awsAccountId === 'string') {
    return route.params.awsAccountId;
  }
  return '';
});

const iamDetails = ref<IamDetails | null>(null);
const validating = ref(false);
const errorCode = ref<string | null>(null);
const errorCollection = ref<string[] | null>(null);

onMounted(async () => {
  iamDetails.value = await awsService.getIamDetails(awsAccountId.value);
});

const selectedAwsAccount = computed(() => awsAccounts.value?.find(a => a.id === awsAccountId.value));
const subheader = computed(
  () => `${selectedAwsAccount.value?.friendly_name} (${selectedAwsAccount.value?.aws_account_number})`
);

interface HeaderPill {
  label: string;
  variant: BootstrapVariant;
  visible: boolean;
}
const headerPills = computed(
  () => <HeaderPill[]>[
      {
        label: 'GovCloud',
        variant: 'info',
        visible: selectedAwsAccount.value?.aws_partition === 'aws-us-gov',
      },
      {
        label: 'EC2 Scheduler',
        variant: 'info',
        visible:
          featureStore.armScheduler &&
          selectedAwsAccount.value?.status_by_offering.AutonomousResourceManagementSchedulerForAwsEc2 === 'Active',
      },
    ].filter(p => p.visible)
);

const selectedAwsAccountIsConfigured = computed(() => {
  const completedStatuses = ['Active', 'Ignored', 'ResellerEndCustomer', 'NoAccessRequired'];
  const status = selectedAwsAccount.value?.status ?? 'PendingIamSetup';
  return completedStatuses.includes(status);
});

function loadAwsAccounts(): Promise<void> {
  return store.dispatch('aws/loadAwsAccounts');
}

async function validateAccessClicked() {
  try {
    validating.value = true;
    errorCode.value = null;
    errorCollection.value = null;

    await customerService.validateAccess(awsAccountId.value);
    await loadAwsAccounts();

    const accountListRoute = selectedOrganizationHasOnboarded.value ? 'aws_accounts' : 'aws_onboarding';
    await router.push({ name: accountListRoute });
  } catch (e) {
    validating.value = false;
    handleError(e);
  }
}

function handleError(e: unknown) {
  const err = isAxiosError(e) ? e : null;
  const status = err?.response?.status ?? 500;
  const data = err?.response?.data;
  const code = data?.code ?? 'General';
  const errors = data?.errors ?? null;

  if (status >= 500 || code === 'General' || !errors) {
    throw e;
  }

  errorCode.value = code;
  errorCollection.value = errors;
}
</script>

<template>
  <Layout :loading="!iamDetails" with-footer>
    <template #default>
      <div class="row pb-3">
        <div class="col">
          <router-link :to="{ name: 'aws_accounts' }">
            <BaseIcon name="arrow-left" class="mr-1" />
            Back to AWS Accounts List
          </router-link>
        </div>
      </div>
      <PageHeader>
        <h1>AWS Account Setup</h1>
        <template v-if="selectedAwsAccount" v-slot:subheader>
          <span class="mr-2">{{ subheader }}</span>
          <Pill v-for="p in headerPills" :key="p.label" class="mr-2 px-3 py-1 align-text-bottom" :variant="p.variant">
            {{ p.label }}
          </Pill>
        </template>
      </PageHeader>
      <div v-if="iamDetails && selectedAwsAccount">
        <BoxMessage v-if="selectedAwsAccountIsConfigured" type="success" class="pt-4 pb-2">
          <strong>This AWS Account is fully configured!</strong>
          <p class="mb-0">
            We have confirmed that the ProsperOps role is configured correctly and no further action is necessary.
          </p>
        </BoxMessage>
        <BoxMessage v-else type="info" class="pt-4 pb-2">
          <p class="mb-0">
            ProsperOps access is granted via an IAM role. To make configuration simple, customized
            scripts/templates/instructions are provided below. Please choose whichever method is best for you. Once the
            IAM role has been configured, click the
            <strong>Validate Access</strong> button to continue.
          </p>
        </BoxMessage>
        <BoxMessage v-if="errorCode" type="error" class="pt-4 pb-2">
          <ErrorMessageByCode :code="errorCode" :errors="errorCollection" />
        </BoxMessage>
        <div class="row pt-2">
          <div class="col">
            <SetupDirections
              :iam-details="iamDetails"
              :aws-account-number="selectedAwsAccount.aws_account_number"
              :disabled="selectedAwsAccountIsConfigured"
            />
          </div>
        </div>
        <div v-if="!selectedAwsAccountIsConfigured" class="row pt-2">
          <div class="col pt-3 pb-3 d-flex flex-row-reverse">
            <form-submit-button
              variant="primary"
              type="submit"
              :loading="validating"
              class="rounded-sm"
              @click="validateAccessClicked"
            >
              Validate Access
              <template v-slot:loading> Validating... </template>
            </form-submit-button>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <SecurityFooter />
    </template>
  </Layout>
</template>
