<script>
import errors from '@console/lib/errors';
import env from '@shared/utilities/env';

import AppLayout from './AppLayout.vue';

const isLocal = env.isLocal();
const isDev = env.isDev();
const link = [];
if (isLocal || isDev) {
  const color = isLocal ? 'red' : 'yellow';
  link.push({
    rel: 'icon',
    type: 'image/png',
    href: `/icons/favicon-194x194-${color}.png`,
    sizes: '194x194',
    id: 'env-icon',
  });
}

export default {
  components: {
    AppLayout,
  },
  data() {
    return {
      loaded: false,
    };
  },
  head: {
    titleTemplate(title) {
      return title ? `${title} | ProsperOps Console` : 'ProsperOps Console';
    },
    link,
  },
  async errorCaptured(e) {
    try {
      const redirect = await errors.handle(e, this.$route.fullPath);
      if (redirect) {
        await this.$router.push(redirect);
      }
    } catch (e) {
      await this.$router.push({ name: 'error' });
    }
  },
  beforeMount() {
    if (isLocal || isDev) {
      const head = document.querySelector('head');
      if (head) {
        head.querySelectorAll('head > link[rel="icon"]:not(#env-icon)').forEach(link => {
          link.remove();
        });
      }
    }
  },
  async mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 15000);
  },
};
</script>

<template>
  <div id="app">
    <iframe v-if="!loaded" src="/gtm" height="0" width="0" style="display: none; visibility: hidden"></iframe>

    <template v-if="$route.meta.useNewLayout">
      <AppLayout>
        <router-view />
      </AppLayout>
    </template>
    <template v-else>
      <!-- This key is needed because a lot of components currently only update via onMounted and router-view will
       reuse the component when navigating to the same route with a different route parameter (e.g. selecting a
       different month for the Savings Dashboard). This key forces the entire router-view to be destroyed and remounted
       whenever the route changes -->
      <router-view :key="$route.fullPath" />
    </template>
  </div>
</template>

<style lang="scss">
@import 'nprogress/nprogress.css';
@import '@shared/scss/index.scss';

#nprogress .bar {
  background: map-get($theme-colors, 'secondary');
}

#app {
  width: 100%;
  height: 100%;
}
</style>
