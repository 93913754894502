<script>
import { mapActions } from 'pinia';

import { useAuthStore } from '@shared/state/auth.store';

import Layout from '@console/Layout.vue';

export default {
  components: {
    Layout,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  head: {
    title: 'Unauthorized',
  },
  methods: mapActions(useAuthStore, ['logOut']),
};
</script>

<template>
  <Layout no-nav wide static-top-bar>
    <template #default>
      <div class="announcement pt-4">
        <div class="pt-2 pb-2 text-center">
          <h1 class="display-4">You are not authorized</h1>
        </div>
        <p v-if="code === 'FEDERATION_NOT_CONFIGURED'" class="lead text-center mb-0 w-50">
          An error occurred while federating into the ProsperOps Console.
        </p>
        <p v-else-if="code === 'FEDERATION_REQUIRED'" class="lead text-center mb-0 w-50">
          Login has been configured to use your company identity provider. Please contact your company's system
          administrator to configure access.
        </p>
        <p v-else-if="code === 'INVALID_GROUPS'" class="lead text-center mb-0 w-50">
          You are not a member of any group in your company's identity system that grants you access to the ProsperOps
          Console. Please contact your administrator for assistance.
        </p>
        <p v-else class="lead text-center mb-0 w-50">
          An error occurred during your login attempt. We are investigating the issue.
        </p>
        <BaseButton variant="primary" class="rounded-sm mt-4" @click="logOut"> Return to Login </BaseButton>
      </div>
    </template>
  </Layout>
</template>

<style scoped>
.announcement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75%;
}
</style>
