<script>
export default {
  props: {
    enabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    primary: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    text() {
      return this.enabled ? 'On' : 'Off';
    },
  },
};
</script>

<template>
  <div class="badge badge-pill switch" :class="{ enabled, primary: primary }">
    <span>{{ text }}</span>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.switch {
  padding: 0.5rem 0.9rem;
  margin-right: 0.5rem;
  font-weight: 400;
  color: #fff;
  cursor: default;
}

.switch.enabled {
  background-color: map-get($theme-colors, 'secondary');
}

.switch.enabled.primary {
  background-color: map-get($theme-colors, 'primary');
}

.switch:not(.enabled) {
  background-color: #bcbccb;
}
</style>
