<script>
import customerService from '@console/services/customerService';

export default {
  props: {
    awsAccountNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async switchManagementAccount() {
      this.loading = true;
      const response = await customerService.addRole(this.awsAccountNumber);
      this.loading = false;
      this.$emit('click', response.data);
    },
  },
};
</script>

<template>
  <form @submit.prevent="switchManagementAccount">
    <BaseButton type="submit" variant="success" class="rounded-sm text-nowrap p-2" :disabled="loading">
      Switch to Management Account
    </BaseButton>
  </form>
</template>
