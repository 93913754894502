<script lang="ts" setup>
import { computed } from 'vue';

export type SavingsShareType = 'include' | 'exclude';

const props = defineProps<{
  value: SavingsShareType;
  disabled?: boolean;
}>();
const emits = defineEmits<{
  (e: 'change', newValue: SavingsShareType): void;
}>();

interface SavingsShareOption {
  type: SavingsShareType;
  label: string;
}
const options: SavingsShareOption[] = [
  { type: 'include', label: 'Include' },
  { type: 'exclude', label: 'Exclude' },
];

const selectedLabel = computed(() => options.find(o => o.type === props.value)?.label);

function change(newValue: SavingsShareType) {
  if (newValue === props.value) return;
  emits('change', newValue);
}
</script>

<template>
  <b-dropdown
    variant="transparent"
    menu-class="rounded-0"
    toggle-class="btn-no-focus-box-shadow rounded-0"
    right
    no-caret
    :disabled="disabled"
  >
    <template #button-content>
      <div v-if="selectedLabel" class="d-flex align-items-center justify-content-center">
        <div class="text-capitalize">
          {{ selectedLabel }}
        </div>
        <div class="ml-2">
          <BaseIcon name="angle-down" />
        </div>
      </div>
    </template>
    <b-dropdown-item v-for="o in options" :key="o.type" @click.prevent="change(o.type)">
      <div class="d-flex flex-column align-items-start justify-content-center">{{ o.label }}</div>
    </b-dropdown-item>
  </b-dropdown>
</template>
