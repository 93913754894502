<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import * as marketplace from '@azure/services/marketplace';

import Layout from '@console/Layout.vue';

const route = useRoute();

const success = ref<boolean | null>(null);

const isLoading = computed<boolean>(() => {
  return success.value == null;
});

useHead({
  title: 'Azure Marketplace',
});

onMounted(async () => {
  const token = route.query.token as string;

  if (!token) {
    success.value = false;
    return;
  }

  try {
    await marketplace.resolve({ token });
    success.value = true;
  } catch {
    success.value = false;
  }
});
</script>

<template>
  <Layout :loading="isLoading" wide :with-announcement-banner="false">
    <template #default>
      <div class="announcement pt-4">
        <template v-if="success">
          <div class="pt-2 pb-2 text-center">
            <h1 class="display-4">Thanks!</h1>
          </div>
          <p class="lead text-center mb-0 w-50">
            We have successfully processed your Azure Marketplace subscription details. You can now
            <router-link :to="{ name: 'azure_onboarding' }">continue the onboarding process</router-link>.
          </p>
        </template>
        <template v-else>
          <p class="lead text-center mb-0 w-50">
            We encountered an error processing your Azure Marketplace subscription. We'll be in touch shortly to discuss
            next steps.
          </p>
        </template>
      </div>
    </template>
  </Layout>
</template>

<style scoped>
.announcement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75%;
}
</style>
