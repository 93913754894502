import type { AwsAccountStatus, AwsAdmOfferingCode, AwsAdmNonComputeOfferingCode, AwsOfferingCode, AwsBillingOffering } from '@console/services/api.models';

const nonComputeServices: AwsAdmNonComputeOfferingCode[] =
  ['elasti_cache', 'memory_db', 'open_search', 'rds', 'redshift'] as const;

const awsProductCodes = ['ec2', 'fargate', 'lambda', ...nonComputeServices] as const;

type AwsProductCode = typeof awsProductCodes[number];
type AwsRiProductCode = AwsAdmNonComputeOfferingCode | 'ec2';

const displayNames = {
  compute: 'Compute',
  ec2: 'EC2',
  elasti_cache: 'ElastiCache',
  fargate: 'Fargate',
  lambda: 'Lambda',
  memory_db: 'MemoryDB',
  open_search: 'OpenSearch',
  rds: 'RDS',
  redshift: 'Redshift',
};

const billingOfferingNames: Record<AwsOfferingCode, AwsBillingOffering> = {
  // ADM
  compute: 'AutonomousDiscountManagementForAwsCompute',
  elasti_cache: 'AutonomousDiscountManagementForAwsElastiCache',
  memory_db: 'AutonomousDiscountManagementForAwsMemoryDb',
  open_search: 'AutonomousDiscountManagementForAwsOpenSearch',
  rds: 'AutonomousDiscountManagementForAwsRds',
  redshift: 'AutonomousDiscountManagementForAwsRedshift',

  // ARM
  arm_scheduler_ec2: 'AutonomousResourceManagementSchedulerForAwsEc2',
};

// Only AWS services with RIs
const reservedItemTypes: Record<AwsRiProductCode, string> = {
  ec2: 'Instance',
  elasti_cache: 'Node',
  memory_db: 'Node',
  open_search: 'Instance',
  rds: 'Instance',
  redshift: 'Node',
};

// Only AWS services with RI Action Accounts
const riActionAccountIdSettingNames: Record<AwsRiProductCode, string> = {
  ec2: 'ActionAwsAccountId',
  elasti_cache: 'ElastiCacheActionAwsAccountId',
  memory_db: 'MemoryDbActionAwsAccountId',
  open_search: 'OpenSearchActionAwsAccountId',
  rds: 'RdsActionAwsAccountId',
  redshift: 'RedshiftActionAwsAccountId',
};

// Only AWS services with RIs (these values come from the "service" query param in cost explorer's RI Utilization
// Report)
const costExplorerReservedInstanceServiceNames: Record<AwsRiProductCode, string> = {
  ec2: 'Amazon Elastic Compute Cloud - Compute',
  elasti_cache: 'Amazon ElastiCache',
  memory_db: 'Amazon MemoryDB',
  open_search: 'Amazon OpenSearch Service',
  rds: 'Amazon Relational Database Service',
  redshift: 'Amazon Redshift',
};

const nonComputeIncludeBillingOfferingPermissionsSettingNames: Record<AwsAdmNonComputeOfferingCode, string> = {
  elasti_cache: 'ElastiCacheIncludeBillingOfferingPermissions',
  memory_db: 'MemoryDbIncludeBillingOfferingPermissions',
  open_search: 'OpenSearchIncludeBillingOfferingPermissions',
  rds: 'RdsIncludeBillingOfferingPermissions',
  redshift: 'RedshiftIncludeBillingOfferingPermissions',
};

const nonComputePftSettingNames: Record<AwsAdmNonComputeOfferingCode, string> = {
  elasti_cache: 'elasti_cache_primary_function_manage_commitment',
  memory_db: 'memory_db_primary_function_manage_commitment',
  open_search: 'open_search_primary_function_manage_commitment',
  rds: 'rds_primary_function_manage_commitment',
  redshift: 'redshift_primary_function_manage_commitment',
};

export default {
  isValidService: (x: string) => awsProductCodes.includes(x as AwsProductCode),
  isValidNonComputeService: (x: string) => nonComputeServices.includes(x as AwsAdmNonComputeOfferingCode),

  getDisplayName: (service: AwsAdmOfferingCode | AwsProductCode) => getServiceValue(service, 'Display Name', displayNames),
  getBillingOffering: (service: AwsOfferingCode) => getServiceValue(service, 'Billing Offering', billingOfferingNames),
  getReservedItemType: (service: AwsRiProductCode) => getServiceValue(service, 'Reserved Item Type', reservedItemTypes),

  getActionAccountIdSettingName: (service: AwsRiProductCode) =>
    getServiceValue(service, 'Action Account ID Setting', riActionAccountIdSettingNames),

  getNonComputeIncludeBillingOfferingPermissionsSettingName: (service: AwsAdmNonComputeOfferingCode) =>
    getServiceValue(service, 'Non-Compute Enabled Setting', nonComputeIncludeBillingOfferingPermissionsSettingNames),

  getNonComputePftSettingName: (service: AwsAdmNonComputeOfferingCode) =>
    getServiceValue(service, 'Non-Compute PFT Setting', nonComputePftSettingNames),

  getCostExplorerReservedInstanceServiceName: (service: AwsRiProductCode) =>
    getServiceValue(service, 'Cost Explorer RI Service Name', costExplorerReservedInstanceServiceNames),

  needsSetup: (awsAccountStatus: AwsAccountStatus) => ['PendingIamSetup', 'AccessError'].includes(awsAccountStatus),
};

function getServiceValue<TValues, TKey extends Extract<keyof TValues, string>>(
  service: TKey,
  name: string,
  values: TValues
): TValues[TKey] {
  const val = values[service];
  if (!val) throw new Error(`${service} isn't supported for ${name}`);
  return val;
}
