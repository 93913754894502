import type { RouteRecordRaw } from 'vue-router';

import {
  hasSingleOrgSelected,
  notNewOrganization,
} from '../requirements';
import {
  requires,
  hasRoles,
  selectedCompany,
  atLeastRole,
  activeSubscription,
} from '@console/router/requirements';
import { PermissionLevel } from '@shared/state/auth.store';

import ComputeCommitments from '@aws/views/adm/compute/Commitments.vue';
import ComputePrepayments from '@aws/views/adm/compute/Prepayments.vue';
import ComputeSavings from '@aws/views/adm/compute/SavingsDataLoader.vue';
import ComputeSettings from '@aws/views/adm/compute/Settings.vue';

const baseRequirements = [
  hasRoles,
  selectedCompany,
  activeSubscription,
  atLeastRole(PermissionLevel.Viewer),
  notNewOrganization,
];

const routes: RouteRecordRaw[] = [
  // The Compute Savings Dashboard is currently the only page that supports the multi-org view
  {
    path: '/aws/compute/savings/:timeframe?',
    name: 'aws_compute_savings',
    component: ComputeSavings,
    beforeEnter: requires(hasRoles, selectedCompany, atLeastRole(PermissionLevel.Viewer), notNewOrganization),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/compute/commitments',
    name: 'aws_compute_commitments',
    component: ComputeCommitments,
    props: true,
    beforeEnter: requires(...baseRequirements, hasSingleOrgSelected),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/compute/prepayments/:timeframe?',
    name: 'aws_compute_prepayments',
    component: ComputePrepayments,
    beforeEnter: requires(...baseRequirements, hasSingleOrgSelected),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/compute/settings',
    name: 'aws_compute_settings',
    component: ComputeSettings,
    props: true,
    beforeEnter: requires(...baseRequirements, hasSingleOrgSelected),
    meta: {
      initModules: true,
    },
  },
];

export default routes;
