<script>
import Clipboard from 'clipboard';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      clipboard: undefined,
    };
  },
  created() {
    this.clipboard = new Clipboard('.copyBtn');
  },
  unmounted() {
    this.clipboard.destroy();
  },
};
</script>

<template>
  <BaseButton
    id="copyBtn"
    variant="transparent"
    class="copyBtn btn-no-focus-box-shadow p-0"
    :data-clipboard-text="text"
  >
    <div class="d-flex align-items-center justify-content-center">
      <BaseIcon name="copy" />
    </div>
  </BaseButton>
</template>
