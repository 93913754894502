<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import SwitchManagementAccountForm from '@aws/components/forms/SwitchManagementAccountForm.vue';
import Modal from '@shared/design/Modal.vue';
import Recommendation from '@shared/design/Recommendation.vue';
import Tooltip from '@shared/design/Tooltip.vue';

const supportedCodes = [
  'AwsAccountAlreadyExists',
  'AwsAccountIamInvalidAccess',
  'AwsAccountIamInvalidAccessV2',
  'AwsAccountNotManagementAccount',
  'ContactSupportRequired',
  'AwsAccountIamInvalidAccessCostExplorer',
];

export default {
  components: {
    Modal,
    Recommendation,
    SwitchManagementAccountForm,
    Tooltip,
  },
  props: {
    code: {
      type: String,
      required: true,
      validator(value) {
        if (!_.includes(supportedCodes, value)) {
          throw new Error(`Unknown error code: ${value}`);
        }
        return true;
      },
    },
    errors: {
      type: Array,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters('customer', ['companyName']),
  },
  methods: {
    contextValue(key) {
      return _.get(this, `errors[0].context.${key}`);
    },
    managementAccountSwitched(data) {
      const evt = { code: this.code, data: data };
      this.$emit('resolved', evt);
    },
    accessFailures() {
      return _.sortBy(JSON.parse(this.errors[0].context.AccessFailures), accessFailure => accessFailure.Action);
    },
    showFailures() {
      this.$bvModal.show('aws-account-access-errors');
    },
    failureKey(accessFailure) {
      return `${accessFailure.region}.${accessFailure.action}`;
    },
    failureReason(accessFailureReason) {
      if (accessFailureReason === 'ServiceControlPolicy') {
        return 'This action is blocked by an SCP';
      }
      return 'This action is not authorized by the policy';
    },
  },
};
</script>

<template>
  <div class="w-100">
    <div v-if="code === 'AwsAccountAlreadyExists'" class="w-100">
      <Recommendation>
        <template v-slot:text>
          <strong>Hmm, it looks like this AWS account already exists.</strong>
          <p class="mb-0">
            This AWS account ({{ contextValue('awsAccountNumber') }}) already exists for {{ companyName }}.
          </p>
        </template>
        <template v-slot:action>
          <div>
            <BaseButton
              variant="success"
              class="rounded-sm"
              :to="{
                name: 'root',
                query: {
                  contextCloud: 'aws',
                  contextId: contextValue('awsOrganizationId'),
                },
              }"
            >
              Go to Management Account
            </BaseButton>
          </div>
        </template>
      </Recommendation>
    </div>
    <div v-if="code === 'AwsAccountIamInvalidAccess'">
      <strong>Hmm, we weren't able to validate access</strong>
      <p class="mb-0">
        The ProsperOps role may not be configured correctly, IAM permissions may still need to propagate, or AWS may be
        having an issue. Please try again. If the problem persists, please chat with us for help.
      </p>
    </div>
    <div v-if="code === 'AwsAccountIamInvalidAccessV2'">
      <strong>Hmm, we weren't able to validate access</strong>
      <p class="mb-0">
        <a class="failureInfo" @click="showFailures">Click here</a> to see a full list of actions we were unable to
        validate.
      </p>
    </div>
    <div v-if="code === 'AwsAccountNotManagementAccount'">
      <Recommendation>
        <template v-slot:text>
          <strong>This AWS account is not a management account</strong>
          <p class="mb-0">
            ProsperOps requires access to your management account (the account where you receive your AWS bill). This
            AWS organization's management account is
            <strong>{{ contextValue('managementAccountAwsAccountNumber') }}</strong
            >.
          </p>
        </template>
        <template v-slot:action>
          <SwitchManagementAccountForm
            :aws-account-number="contextValue('managementAccountAwsAccountNumber')"
            @click="managementAccountSwitched"
          />
        </template>
      </Recommendation>
    </div>
    <div v-if="code === 'ContactSupportRequired'">
      <strong>Something went wrong</strong>
      <p class="mb-0">
        We're unable to add this AWS account at this time. Please
        <router-link :to="{ name: 'help' }">contact us</router-link> to continue.
      </p>
    </div>
    <div v-if="code === 'AwsAccountIamInvalidAccessCostExplorer'">
      <strong>Hmm, it appears that Cost Explorer is either not yet enabled or data is not yet available</strong>
      <p class="mb-0">
        Please
        <a href="https://docs.aws.amazon.com/cost-management/latest/userguide/ce-enable.html" target="_blank">
          enable Cost Explorer</a
        >
        (it can take up to 24 hours for data to become available) and try validating access again. If the problem
        persists, please chat with us for help.
      </p>
    </div>
    <Modal id="aws-account-access-errors" size="xl" title="Action Validation Failures">
      <div>
        <table class="actionFailures">
          <thead>
            <tr class="noTopBorder text-muted">
              <td class="failureAction">
                <span>Action</span>
              </td>
              <td class="failureRegion">
                <span>Region</span>
              </td>
              <td class="failureError">
                <span>Error</span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="accessFailure in accessFailures()" :key="failureKey(accessFailure)">
              <td class="failureAction">
                {{ accessFailure.Action }}
              </td>
              <td class="failureRegion">
                {{ accessFailure.Region }}
              </td>
              <td class="failureError">
                <span>
                  {{ failureReason(accessFailure.Reason) }}
                </span>
                <span :id="accessFailure.Region + accessFailure.Action" class="tooltip-target"
                  >(Full error message)</span
                >
                <Tooltip :target="accessFailure.Region + accessFailure.Action">
                  <span>
                    {{ accessFailure.Error }}
                  </span>
                </Tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  </div>
</template>

<style lange="scss" scoped>
.actionFailures {
  width: 100%;
  table-layout: fixed;

  > thead {
    display: block;
  }

  > thead > tr {
    display: inline-block;
    width: 100%;
  }

  > tbody {
    display: block;
    width: 100%;
    height: 500px;
    overflow-y: scroll;
  }

  > tbody::-webkit-scrollbar-track {
    background-color: #fff;
  }

  > tbody::-webkit-scrollbar {
    width: 16px;
    background-color: #fff;
  }

  > tbody::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border: 4px solid #fff;
    border-radius: 16px;
  }

  > tbody > tr {
    display: inline;
  }

  > tbody > tr > td {
    padding-bottom: 10px;
  }
}

.failureAction {
  display: inline-block;
  width: 35%;
  text-align: left;
}

.failureRegion {
  display: inline-block;
  width: 15%;
  text-align: left;
}

.failureError {
  display: inline-block;
  width: 50%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  > span {
    padding-right: 5px;
  }
}

.failureInfo {
  cursor: pointer;
}
</style>
