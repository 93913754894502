<script lang="ts" setup>
import type {
  AwsAccount,
  AwsAccountStatus,
  AwsBillingOffering,
  AwsAdmOfferingCode,
} from '@console/services/api.models';
import type { BootstrapVariant } from '@shared/design/bootstrap';
import type { SolidIconName } from '@shared/design/icons/BaseIcon.vue';

import { computed } from 'vue';

import AccountHelpers from './account_helpers';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';
import NumberHelpers from '@shared/utilities/number_helpers';
import { neverReached } from '@shared/utilities/typescript_helpers';

import CollapsePanel from '@shared/design/panels/CollapsePanel.vue';
import Pill from '@shared/design/Pill.vue';

const props = defineProps<{
  awsAccounts: AwsAccount[];
}>();

declare interface StatusIndicator {
  iconName: SolidIconName;
  variant: BootstrapVariant;
  spin?: boolean;
}
declare interface StatusPill {
  status: AwsAccountStatus;
  label: string;
  count: string;
  variant: BootstrapVariant;
  priority: number;
}

declare interface Summary {
  status: StatusIndicator;
  pills: StatusPill[];
}
const summary = computed<Summary>(() => {
  const activeOrNoAccessStatuses = ['Active', 'Ignored', 'NoAccessRequired', 'ResellerEndCustomer'];
  const pills = getStatusPills(account => account.status);

  return {
    status: getStatusIndicator(pills),
    // Don't include the Active or No Access statuses, since we just want to higlight the issues
    pills: pills.filter(p => !activeOrNoAccessStatuses.includes(p.status)),
  };
});

declare interface DetailDefinition {
  heading: string;
  services: AwsAdmOfferingCode[];
}
const detailDefinitions: DetailDefinition[] = [
  {
    heading: 'Autonomous Discount Management',
    services: ['compute', 'rds', 'elasti_cache', 'memory_db', 'open_search', 'redshift'],
  },
];

declare interface DetailService {
  label: string;
  status: StatusIndicator;
  allPills: StatusPill[];
  displayPills: StatusPill[];
}
declare interface Detail {
  heading: string;
  status: StatusIndicator;
  services: DetailService[];
}
const details = computed<Detail[]>(() =>
  detailDefinitions.map(populateDetails).filter(details => details.services.length > 0)
);

function populateDetails(definition: DetailDefinition): Detail {
  const services = definition.services.map(popluateDetailService).filter(s => !!s) as DetailService[];

  return {
    heading: definition.heading,
    status: getStatusIndicator(services.flatMap(s => s.allPills)),
    services,
  };
}

function popluateDetailService(service: AwsAdmOfferingCode): DetailService | null {
  const offering = AwsServiceHelpers.getBillingOffering(service) as AwsBillingOffering;
  const pills = getStatusPills(account => account.status_by_offering[offering]);
  if (pills.length === 0) return null;

  return {
    label: AwsServiceHelpers.getDisplayName(service),
    allPills: pills,
    // Don't display the Active status, since we just want to higlight the issues
    displayPills: pills.filter(p => p.label !== 'Active'),
    status: getStatusIndicator(pills),
  };
}

function getStatusPills(statusGetter: (account: AwsAccount) => AwsAccountStatus | undefined): StatusPill[] {
  const groupedStatuses = props.awsAccounts.reduce((results, account) => {
    const status = statusGetter(account);
    if (status) {
      const label = AccountHelpers.getStatusLabel(status);
      const result = results[label];
      if (result) {
        result.count++;
      } else {
        results[label] = { status, count: 1 };
      }
    }

    return results;
  }, {} as Record<string, { status: AwsAccountStatus; count: number }>);

  return Object.entries(groupedStatuses)
    .map(([label, { status, count }]) => ({
      status,
      label,
      count: NumberHelpers.formatNumber(count),
      variant: AccountHelpers.getStatusVariant(status),
      priority: AccountHelpers.getStatusPriority(status),
    }))
    .sort((a, b) => a.priority - b.priority);
}

function getStatusIndicator(pills: StatusPill[]): StatusIndicator {
  const worstStatus = AccountHelpers.getWorstStatus(pills.map(p => p.status));

  switch (worstStatus) {
    case 'AccessError':
      return { variant: 'danger', iconName: 'exclamation-triangle' };

    case 'PendingIamSetup':
      return { variant: 'warning', iconName: 'exclamation-triangle' };

    case 'AwaitingProvisioning':
    case 'Provisioning':
    case 'ProvisioningError':
      return { variant: 'info', iconName: 'spinner', spin: true };

    case 'Active':
    case 'Ignored':
    case 'ResellerEndCustomer':
    case 'NoAccessRequired':
      return { variant: 'success', iconName: 'check' };

    default:
      return neverReached<StatusIndicator>(worstStatus, { variant: 'warning', iconName: 'exclamation-triangle' });
  }
}
</script>

<template>
  <CollapsePanel class="mb-3 py-2">
    <template #header>
      <div class="header d-flex align-items-center column-gap-1">
        <BaseIcon
          :name="summary.status.iconName"
          variant="fas"
          :class="`text-${summary.status.variant}`"
          style="width: 20px"
        />
        <b>{{ props.awsAccounts.length }} Accounts</b>
        <Pill v-for="p in summary.pills" :key="p.label" :variant="p.variant" class="ml-1">
          {{ p.count }} {{ p.label }}
        </Pill>
      </div>
    </template>

    <template #default>
      <div class="contents">
        <ul class="mt-1 mb-2 offerings">
          <li v-for="detail in details" :key="detail.heading">
            <div class="d-flex align-items-center column-gap-1">
              <BaseIcon
                :name="detail.status.iconName"
                variant="fas"
                :class="`text-${detail.status.variant}`"
                :spin="detail.status.spin"
                style="width: 20px"
              />
              <b>{{ detail.heading }}</b>
            </div>
            <ul class="offering-services">
              <li v-for="service in detail.services" :key="service.label">
                <div class="d-flex align-items-center column-gap-1">
                  <BaseIcon
                    :name="service.status.iconName"
                    variant="fas"
                    :class="`text-${service.status.variant}`"
                    :spin="service.status.spin"
                    style="width: 20px"
                  />
                  <b>{{ service.label }}</b>
                  <Pill v-for="p in service.displayPills" :key="p.label" :variant="p.variant">
                    {{ p.count }} {{ p.label }}
                  </Pill>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </template>
  </CollapsePanel>
</template>

<style lang="scss" scoped>
.header {
  flex-wrap: wrap;
  cursor: pointer;

  .badge {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    font-size: 0.8rem;
  }
}

.offerings {
  margin-left: -1.5rem;

  > li {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.contents ul {
  padding-left: 0.5rem;

  li {
    padding-left: 1rem;
    list-style: none;
  }
}
</style>
