<script>
import ShowbackTableRow from '@aws/components/adm/showback/ShowbackTableRow.vue';

export default {
  components: {
    ShowbackTableRow,
  },
  props: {
    row: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
};
</script>

<template>
  <ShowbackTableRow :row="row" :level="2" small>
    <template v-slot:label>
      <slot name="label" />
    </template>
  </ShowbackTableRow>
</template>
