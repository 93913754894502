<script setup>
import Percentage from '@shared/components/Percentage.vue';
import UtilizationIcon from '@shared/design/icons/UtilizationIcon.vue';
import MetricPanel from '@shared/design/panels/MetricPanel.vue';

const props = defineProps({
  utilization: {
    type: Number,
    required: true,
  },
});
</script>

<template>
  <MetricPanel name="Resource-based CUD Utilization" color="primary" :icon="UtilizationIcon">
    <Percentage :value="props.utilization" :convert-to-percent="true" />
  </MetricPanel>
</template>
