<script>
import _ from 'lodash';

import SavingsMetric from './SavingsMetric.vue';
import CyclicalWaveIcon from '@shared/design/icons/CyclicalWaveIcon.vue';
import Modal from '@shared/design/Modal.vue';

export default {
  components: {
    CyclicalWaveIcon,
    SavingsMetric,
    Modal,
  },
  props: {
    savings: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
    useSmallerText: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    cyclicalityDetected() {
      return _.get(this.savings, ['dashboard', 'ec2_cyclicality_detected'], false);
    },
    riUtilizationPercentage() {
      return _.toNumber(this.savings.dashboard.key_metrics.reserved_instance_utilization_percentage);
    },
    showCyclicalityDetected() {
      return this.cyclicalityDetected;
    },
  },
};
</script>

<template>
  <div v-if="showCyclicalityDetected">
    <SavingsMetric
      name="reserved_instance_utilization_percentage"
      variant="primary"
      :use-smaller-text="useSmallerText"
      :value="riUtilizationPercentage"
      :company="company"
    >
      <template v-slot:value-icon>
        <a v-b-modal.cyclicality-detected-modal href="#" class="wave" @click.prevent><CyclicalWaveIcon /></a>
      </template>
    </SavingsMetric>
    <Modal id="cyclicality-detected-modal" size="md" title="Cyclical Usage Detected">
      <div class="pb-3">
        <span>
          Our platform has detected cyclical usage patterns and is using advanced strategies to maximize your savings.
          These techniques may lower your Reserved Instance utilization rate but improve your Effective Savings Rate.
        </span>
        <a
          href="https://www.prosperops.com/blog/how-to-maximize-cloud-savings-on-cyclical-workloads-using-commitment-based-discounts/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-nowrap"
          >Learn more</a
        >
      </div>
    </Modal>
  </div>
  <SavingsMetric
    v-else
    name="reserved_instance_utilization_percentage"
    variant="primary"
    :use-smaller-text="useSmallerText"
    :value="riUtilizationPercentage"
    :company="company"
  />
</template>

<style lang="scss" scoped>
.wave {
  margin-left: 0.25rem;
  line-height: 1;

  > svg {
    height: 18px;
  }
}
</style>
