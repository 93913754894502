<script lang="ts" setup>
import type { BootstrapVariant } from './bootstrap';
import type { RouteLocationRaw } from 'vue-router';

import { computed } from 'vue';

export type ButtonVariant = BootstrapVariant | 'link' | 'transparent';

interface Props {
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  variant: ButtonVariant;

  // Used for <router-link> buttons
  to?: RouteLocationRaw;

  // Used for <a> buttons
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';

  // Used for <button> buttons
  type?: 'button' | 'submit' | 'reset';
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  size: undefined,

  // Used for <router-link> buttons
  to: undefined,

  // Used for <a> buttons
  href: undefined,
  target: '_self',

  // Used for <button> buttons
  type: 'button',
});

const emits = defineEmits<{
  (e: 'click', event: MouseEvent): void;
}>();

const classes = computed(() => {
  const items = ['btn', `btn-${props.variant}`];

  if (props.size) items.push(`btn-${props.size}`);

  // Apply the disabled class when disabled, which is needed for <a> elements, since they don't have a disabled attribute
  if (props.disabled) items.push('disabled');

  return items;
});

const handleClick = (event: MouseEvent) => {
  // When disabled, prevent the default event so that clicked links don't navigate
  if (props.disabled) {
    event.preventDefault();
    return;
  }

  emits('click', event);
};
</script>

<template>
  <template v-if="to">
    <RouterLink :class="classes" :disabled="disabled" :to="to" @click="handleClick">
      <slot />
    </RouterLink>
  </template>

  <template v-else-if="href">
    <a :class="classes" :disabled="disabled" :href="href" :target="target" @click="handleClick">
      <slot />
    </a>
  </template>

  <template v-else>
    <button :class="classes" :disabled="disabled" :type="type" @click="handleClick">
      <slot />
    </button>
  </template>
</template>
