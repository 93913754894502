<script>
import ShowbackTableAggregateRow from '@aws/components/adm/showback/ShowbackTableAggregateRow.vue';

export default {
  components: {
    ShowbackTableAggregateRow,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <ShowbackTableAggregateRow :rows="rows" class="summary">
    <template v-slot:label>
      <div class="text-uppercase font-weight-custom-bold">Total</div>
    </template>
  </ShowbackTableAggregateRow>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.summary {
  background-color: map-get($theme-colors, 'tertiary');

  > div:first-child {
    width: 40px;
    min-width: 40px;
    text-align: right;
  }

  > div:nth-child(2) {
    text-transform: uppercase;
  }
}
</style>
