// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import type { AwsAccount, AwsAccountStatus, AwsBillingOffering } from '@console/services/api.models';

import _ from 'lodash';

import * as mask from '@console/lib/mask';
import customerService from '@console/services/customerService';

const statusPriority = {
  PendingIamSetup: 1,
  AwaitingProvisioning: 1,
  Provisioning: 1,
  ProvisioningError: 1,
  AccessError: 1,
  Active: 2,
  ResellerEndCustomer: 3,
  NoAccessRequired: 3,
  Ignored: 4,
};

const groupingPriority = awsAccount => {
  if (['PendingIamSetup', 'AccessError'].includes(awsAccount.status)) {
    return 1;
  }
  if (['AwaitingProvisioning', 'Provisioning', 'ProvisioningError'].includes(awsAccount.status)) {
    return 2;
  }
  if (awsAccount.is_reseller_base_shard) {
    return 3;
  }
  if (awsAccount.reseller_flex_shard_region) {
    return 4;
  }
  if (awsAccount.status === 'Active') {
    return 5;
  }
  if (['ResellerEndCustomer', 'NoAccessRequired'].includes(awsAccount.status)) {
    return 6;
  }
  return 7;
};

const state = {
  awsOrganizations: null,
  awsOrganizationsSettings: null,
  awsAccounts: null,
  savings: {},
};

const maskAwsOrganizations = awsOrganizations => {
  return _.map(awsOrganizations, awsOrganization => {
    return _.assign({}, awsOrganization, {
      friendly_name: mask.generateAwsOrganizationName(awsOrganization.id),
      management_aws_account_number: mask.redact(awsOrganization.management_aws_account_number),
      original_management_aws_account_number: awsOrganization.management_aws_account_number,
      aws_organization_arn: null,
    });
  });
};

const maskComputeSavingsDashboard = savings => {
  const maskedSavings = _.assign({}, savings);
  maskedSavings.dashboard.organization_summary.details = _.map(
    savings.dashboard.organization_summary.details,
    organizationSummary => ({
      ...organizationSummary,
      aws_organization_friendly_name: mask.generateAwsOrganizationName(organizationSummary.aws_organization_id),
      management_aws_account_number: mask.redact(organizationSummary.management_aws_account_number),
      original_management_aws_account_number: organizationSummary.management_aws_account_number,
    })
  );
  return maskedSavings;
};

const maskAwsAccounts = awsAccounts => {
  return _.map(awsAccounts, awsAccount => {
    return _.assign({}, awsAccount, {
      friendly_name: mask.generateAwsAccountName(awsAccount.id),
      aws_account_number: mask.redact(awsAccount.aws_account_number),
      unmasked_aws_account_number: awsAccount.aws_account_number,
    });
  });
};

const mutations = {
  RESET_AWS_ORGANIZATION(state) {
    state.awsOrganizations = null;
    state.awsAccounts = null;
    state.savings = {};
  },
  SET_AWS_ORGANIZATIONS(state, awsOrganizations) {
    state.awsOrganizations = _.keyBy(awsOrganizations, 'id');
  },
  SET_AWS_ORGANIZATION_SETTINGS(state, payload) {
    const { awsOrganizationId, settings } = payload;
    const path = ['awsOrganizationsSettings', awsOrganizationId];
    _.set(state, path, settings);
  },
  UPDATE_AWS_ACCOUNT_OFFERING_STATUS(state, { awsAccountId, offering, status }) {
    const awsAccount = _.find(state.awsAccounts, { id: awsAccountId }) as AwsAccount;
    if (!awsAccount) return;

    awsAccount.status_by_offering[offering] = status;

    // TODO while migrating to the per-offering account statuses, update the account's status when the Compute status changes
    if (offering === 'AutonomousDiscountManagementForAwsCompute') {
      awsAccount.status = status;
    }
  },
  UPDATE_AWS_ORGANIZATION_FRIENDLY_NAME(state, payload) {
    const { awsOrganizationId, friendlyName } = payload;
    const path = ['awsOrganizations', awsOrganizationId, 'friendly_name'];
    _.set(state, path, friendlyName);
  },
  SET_AWS_ACCOUNTS(state, awsAccounts) {
    state.awsAccounts = awsAccounts;
  },
  UPDATE_AWS_ORGANIZATION_STATUS(state, payload) {
    const { awsOrganizationId, status } = payload;
    const path = ['awsOrganizations', awsOrganizationId, 'status'];
    _.set(state, path, status);
  },
  SET_SAVINGS(state, savings) {
    state.savings = savings;
  },
};

const getters = {
  selectedOrganizationId(state, getters, rootState) {
    return _.get(rootState, ['nav', 'context', 'id']);
  },
  selectedOrganization(state, getters) {
    if (getters.selectedOrganizationId === 'MULTI_ORG_SUMMARY') {
      return {
        id: 'MULTI_ORG_SUMMARY',
        friendly_name: 'All Organizations',
        status: 'Active',
        data_source: 'CostAndUsageReport',
      };
    }
    return _.get(state, ['awsOrganizations', getters.selectedOrganizationId]);
  },
  selectedOrganizationSettings(state, getters) {
    return _.get(state, ['awsOrganizationsSettings', getters.selectedOrganizationId]);
  },
  isMultiOrgSummarySelected(state, getters) {
    return getters.selectedOrganizationId === 'MULTI_ORG_SUMMARY';
  },
  isNewOrganization(state, getters) {
    return getters.selectedOrganizationId === 'NEW_ORGANIZATION_PLACEHOLDER';
  },
  selectedOrganizationIsCurDataSource(state, getters) {
    const org = getters.selectedOrganization;
    return org?.is_cur_data_source ?? false;
  },
  selectedOrganizationRegionDisplayNames(state, getters) {
    const regions = _.get(getters.selectedOrganization, 'regions', []);
    const keyed = _.keyBy(regions, 'region');
    return _.mapValues(keyed, 'region_display_name');
  },
  selectedOrganizationHasOnboarded(state, getters) {
    if (!getters.selectedOrganizationId) {
      // When customers first sign up, we initialize this to null
      return false;
    }
    if (getters.selectedOrganizationId === 'NEW_ORGANIZATION_PLACEHOLDER') {
      // When customers go through "Add Organization" flow, we initialize
      // this to a placeholder value.
      return false;
    }
    if (getters.selectedOrganizationId === 'MULTI_ORG_SUMMARY') {
      return true;
    }
    const path = ['awsOrganizations', getters.selectedOrganizationId, 'status'];
    const status = _.get(state, path, 'New');
    const preOnboardingStatuses = ['New', 'Provisioning', 'ProvisioningError'];
    return !_.includes(preOnboardingStatuses, status);
  },
  selectedOrganizationCompletedSavingsAnalysis(state, getters) {
    if (getters.isNewOrganization) {
      return false;
    }
    const selectedOrganization = getters.selectedOrganization;
    return _.get(selectedOrganization, 'savings_analysis_completed', false);
  },
  selectedOrganizationAccounts(state, getters) {
    const managementAccount = getters.selectedOrganizationManagementAccount;
    const childAccounts = getters.selectedOrganizationChildAccounts;
    return _.concat([managementAccount], childAccounts);
  },
  selectedOrganizationActiveAccounts(state, getters) {
    const filter = { status: 'Active' };
    return _.filter(getters.selectedOrganizationAccounts, filter);
  },
  selectedOrganizationManagementAccount(state, getters) {
    const managementAwsAccountNumber = _.get(getters, ['selectedOrganization', 'management_aws_account_number']);
    const filter = { aws_account_number: managementAwsAccountNumber };
    return _.find(state.awsAccounts, filter);
  },
  selectedOrganizationHasManagementAccount(state, getters) {
    return !!_.get(getters, 'selectedOrganizationManagementAccount', false);
  },
  selectedOrganizationManagementAccountIsConfigured(state, getters) {
    const { selectedOrganizationManagementAccount } = getters;
    const managementAccountStatus = _.get(selectedOrganizationManagementAccount, 'status');
    const configuredStatuses = ['AwaitingProvisioning', 'Provisioning', 'ProvisioningError', 'Active'];
    return _.includes(configuredStatuses, managementAccountStatus);
  },
  selectedOrganizationChildAccounts(state, getters) {
    const filter = { aws_organization_id: getters.selectedOrganizationId };
    const awsAccounts = _.filter(state.awsAccounts, filter);
    const managementAccountId = _.get(getters, ['selectedOrganizationManagementAccount', 'id']);
    const isNotManagementAccount = awsAccount => awsAccount.id !== managementAccountId;
    const name = a => _.toLower(a.friendly_name);
    return _.sortBy(_.filter(awsAccounts, isNotManagementAccount), [
      groupingPriority,
      'reseller_flex_shard_region',
      name,
    ]);
  },
  selectedOrganizationHasChildAccounts(state, getters) {
    const { selectedOrganizationChildAccounts } = getters;
    return _.some(selectedOrganizationChildAccounts);
  },
  selectedOrganizationChildAccountsAreConfigured(state, getters) {
    const { selectedOrganizationChildAccounts } = getters;
    const configuredStatuses = ['Active', 'Ignored', 'ResellerEndCustomer', 'NoAccessRequired'];
    const isConfigured = ({ status }) => _.includes(configuredStatuses, status);
    return _.every(selectedOrganizationChildAccounts, isConfigured);
  },
  selectedOrganizationIsConfigured(state, getters) {
    const { selectedOrganization } = getters;
    const organizationStatus = _.get(selectedOrganization, 'status');
    const configuredStatuses = ['Active'];
    return _.includes(configuredStatuses, organizationStatus);
  },
  selectableOrganizations(state, getters) {
    const isNotSelected = o => o.id !== getters.selectedOrganizationId;
    return _.filter(_.values(state.awsOrganizations), isNotSelected);
  },
  selectedOrganizationIsPaused(state, getters) {
    if (!getters.selectedOrganizationManagementAccountIsConfigured) {
      return true;
    }
    if (!getters.selectedOrganizationChildAccountsAreConfigured) {
      return true;
    }
    return false;
  },
  savingsTimeframes(state) {
    return _.get(state.savings, 'available_timeframes', []);
  },
  selectedOrganizationEnabledNonComputeServices(state, getters) {
    const nonComputeServices = ['elasti_cache', 'memory_db', 'open_search', 'rds', 'redshift'];
    const settings = getters.selectedOrganizationSettings;

    return nonComputeServices.reduce((result, service) => {
      result[service] = settings?.[`${service}_primary_function_manage_commitment`] === true;

      return result;
    }, {});
  },
};

const actions = {
  resetAwsOrganization({ commit, dispatch }) {
    commit('RESET_AWS_ORGANIZATION');
    dispatch('nav/reset', undefined, { root: true });
  },
  reset(context) {
    context.commit('customer/RESET_CUSTOMER', null, { root: true });
    context.commit('RESET_AWS_ORGANIZATION');
  },
  async initializeAwsOrganization({ dispatch, state, getters }) {
    await Promise.all([
      state.awsOrganizations === null && dispatch('loadOrganizations'),
      state.awsAccounts === null && dispatch('loadAwsAccounts'),
    ]);

    if (!getters.selectedOrganizationId && !getters.isNewOrganization) {
      const awsOrganizations = _.values(state.awsOrganizations);
      const activeOrganizations = _.filter(awsOrganizations, {
        status: 'Active',
      });
      const placeholder = 'NEW_ORGANIZATION_PLACEHOLDER';
      let selected;
      if (activeOrganizations.length > 1) {
        selected = 'MULTI_ORG_SUMMARY';
      } else {
        selected = _.get(awsOrganizations, '[0].id', placeholder);
      }

      dispatch('nav/switch', { cloud: 'aws', id: selected }, { root: true });
    }

    await dispatch('initializeAwsOrganizationSettings');
  },
  async initializeAwsOrganizationSettings({ commit, getters }) {
    if (getters.isMultiOrgSummarySelected || getters.isNewOrganization) {
      return;
    }

    if (!getters.selectedOrganization) {
      return;
    }

    const req = await customerService.getSettingsSummary(getters.selectedOrganization.id);
    const settings = req.data;

    commit('SET_AWS_ORGANIZATION_SETTINGS', { awsOrganizationId: getters.selectedOrganization.id, settings });
  },
  async reload(context) {
    context.commit('RESET_AWS_ORGANIZATION');
    await Promise.all([context.dispatch('loadOrganizations'), context.dispatch('loadAwsAccounts')]);
  },
  async loadOrganizations(context) {
    if (context.state.awsOrganizations !== null) {
      return;
    }
    const response = await customerService.getAwsOrganizations();
    if (context.rootGetters['customer/isDemo']) {
      const activeOrganizations = _.filter(response.data, { status: 'Active' });
      context.commit('SET_AWS_ORGANIZATIONS', maskAwsOrganizations(activeOrganizations));
    } else {
      context.commit('SET_AWS_ORGANIZATIONS', response.data);
    }
  },
  async loadAwsAccounts(context) {
    if (context.state.awsAccounts !== null) {
      return;
    }
    const withDefaults = a => _.defaults(a, { friendly_name: null });
    const maxPriority = _.max(_.values(statusPriority));
    const response = await customerService.getAwsAccounts();
    const awsAccounts = _.map(response.data, withDefaults);
    const priority = a => statusPriority[a.status] || maxPriority;
    const name = a => _.toLower(a.friendly_name);

    if (context.rootGetters['customer/isDemo']) {
      const sorted = _.sortBy(awsAccounts, ['aws_account_number']);
      context.commit('SET_AWS_ACCOUNTS', maskAwsAccounts(sorted));
    } else {
      const sorted = _.sortBy(awsAccounts, priority, name);
      context.commit('SET_AWS_ACCOUNTS', sorted);
    }
  },
  async loadSavings({ commit, getters, rootGetters }, timeframe = null) {
    const awsOrganizationId = getters.selectedOrganizationId;
    const res = await customerService.getSavings(awsOrganizationId, timeframe);
    if (rootGetters['customer/isDemo'] && getters.isMultiOrgSummarySelected) {
      commit('SET_SAVINGS', maskComputeSavingsDashboard(res.data));
    } else {
      commit('SET_SAVINGS', res.data);
    }
  },
  async switchOrganization({ dispatch }, organizationId) {
    dispatch('nav/switch', { cloud: 'aws', id: organizationId }, { root: true });
    await dispatch('reload');
  },
  async addManagementAccount({ dispatch }, { awsAccountNumber, friendlyName }) {
    const res = await customerService.addManagementAccount(awsAccountNumber, friendlyName);
    const awsAccount = res.data;
    const awsOrganizationId = awsAccount.aws_organization_id;

    dispatch('nav/switch', { cloud: 'aws', id: awsOrganizationId }, { root: true });
    await dispatch('reload');
  },
  async validateManagementAccountAccess({ getters, commit }) {
    const managementAccount = getters.selectedOrganizationManagementAccount;
    await customerService.validateAccess(managementAccount.id);
    commit('UPDATE_AWS_ACCOUNT_OFFERING_STATUS', {
      awsAccountId: managementAccount.id,
      offering: <AwsBillingOffering>'AutonomousDiscountManagementForAwsCompute',
      status: <AwsAccountStatus>'Active',
    });
  },
  updateOrganizationFriendlyName({ commit }, { awsOrganizationId, friendlyName }) {
    commit('UPDATE_AWS_ORGANIZATION_FRIENDLY_NAME', {
      awsOrganizationId,
      friendlyName,
    });

    return customerService.updateOrganizationFriendlyName(awsOrganizationId, friendlyName);
  },
  updateAccountOfferingStatus({ commit }, { awsAccountId, offering, status }) {
    commit('UPDATE_AWS_ACCOUNT_OFFERING_STATUS', { awsAccountId, offering, status });
  },
  updateOrganizationStatus({ commit }, { awsOrganizationId, status }) {
    commit('UPDATE_AWS_ORGANIZATION_STATUS', { awsOrganizationId, status });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
