import type { BillingScopeResponse } from '@azure/services/billingScope';
import type { CreateServicePrincipalResponse } from '@azure/services/servicePrincipal';

export const generate = (billingScope: BillingScopeResponse, servicePrincipal: CreateServicePrincipalResponse) => {
  /* eslint-disable no-useless-escape */
  return `# Constants
AZ_CLI_CMD="az"
BILLING_PROFILE_CONTRIBUTOR_ROLE_DEFINITION_ID="40000000-aaaa-bbbb-cccc-100000000001"
ENROLLMENT_PURCHASER_ROLE_DEFINITION_ID="bbbb1b1b-cc2c-dd3d-ee4e-ffffff5f5f5f"

# Input
BILLING_ACCOUNT_ID="${billingScope.billing_account_id}"
BILLING_PROFILE_ID="${billingScope.billing_profile_id}"
SPN_APPLICATION_ID="${servicePrincipal?.application_id}"
SPN_TENANT_ID="${servicePrincipal?.tenant_id}"

# State
AGREEMENT_TYPE=""
ROOT_MANAGEMENT_GROUP=""
SPN_OBJECT_ID=""

export AZURE_CORE_ONLY_SHOW_ERRORS=true

validate_service_principal () {
  printf "==> Validating service principal...\\n\\n"

  SPN_OBJECT_ID=$(az rest --method get --url "https://graph.microsoft.com/v1.0/servicePrincipals(appId='$SPN_APPLICATION_ID')" --query id --output tsv)
}

validate_billing_account () {
  printf "==> Validating billing account...\\n\\n"

  az billing account show --name $BILLING_ACCOUNT_ID

  local account_status=$(az billing account show --name $BILLING_ACCOUNT_ID --query accountStatus --output tsv)
  if [ "$account_status" != "Active" ]; then
    printf "Account status must be Active. Found account status: $account_status."
    exit 1
  fi

  AGREEMENT_TYPE=$(az billing account show --name $BILLING_ACCOUNT_ID --query agreementType --output tsv)

  printf "\\n"
}

validate_billing_profile () {
  printf "==> Validating billing profile...\\n\\n"

  if [ "$AGREEMENT_TYPE" = "EnterpriseAgreement" ]; then
    printf "Skipping billing profile validation for Enterprise Agreement account. Found agreement type: $AGREEMENT_TYPE.\\n"
  else
    az billing profile show --account-name $BILLING_ACCOUNT_ID --name $BILLING_PROFILE_ID
  fi

  printf "\\n"
}

validate_root_management_group () {
  printf "==> Validating root management group...\\n\\n"

  az account management-group list
  ROOT_MANAGEMENT_GROUP=$(az account management-group list --query "[?contains(displayName, 'Tenant Root Group')] | [0].name" --output tsv)

  printf "\\n"
}

assign_service_principal_permissions () {
  printf "==> Assigning service principal permissions...\\n\\n"

  if [ "$AGREEMENT_TYPE" = "EnterpriseAgreement" ]; then
    az rest --method put \\
      --url "https://management.azure.com/providers/Microsoft.Billing/billingAccounts/$BILLING_ACCOUNT_ID/billingRoleAssignments/${crypto.randomUUID()}?api-version=2019-10-01-preview" \\
      --headers "Content-Type=application/json" \\
      --body "{
        \\"properties\\": {
          \\"principalId\\": \\"$SPN_OBJECT_ID\\",
          \\"principalTenantId\\": \\"${servicePrincipal?.tenant_id}\\",
          \\"roleDefinitionId\\": \\"/providers/Microsoft.Billing/billingAccounts/$BILLING_ACCOUNT_ID/billingRoleDefinitions/$ENROLLMENT_PURCHASER_ROLE_DEFINITION_ID\\"
        }
      }"
  else
    az rest --method post \\
      --url https://management.azure.com/providers/Microsoft.Billing/billingAccounts/$BILLING_ACCOUNT_ID/billingProfiles/$BILLING_PROFILE_ID/createBillingRoleAssignment\?api-version\=2024-04-01 \\
      --headers "Content-Type=application/json" \\
      --body "{
        \\"principalId\\": \\"$SPN_OBJECT_ID\\",
        \\"roleDefinitionId\\": \\"$BILLING_PROFILE_CONTRIBUTOR_ROLE_DEFINITION_ID\\"
      }"
  fi

  az role assignment create --assignee-object-id $SPN_OBJECT_ID --assignee-principal-type ServicePrincipal --role "Reservation Purchaser" --scope "/providers/Microsoft.Management/managementGroups/$ROOT_MANAGEMENT_GROUP"
  az role assignment create --assignee-object-id $SPN_OBJECT_ID --assignee-principal-type ServicePrincipal --role "Savings plan Purchaser" --scope "/providers/Microsoft.Management/managementGroups/$ROOT_MANAGEMENT_GROUP"

  printf "\\n"
}

main () {
  validate_billing_account
  validate_billing_profile
  validate_root_management_group
  validate_service_principal
  assign_service_principal_permissions

  echo 'ProsperOps setup complete!'
}

main
`;
};

export const generateBillingRoleAssignmentScript = (
  billingAccountId: string,
  applicationId: string,
  tenantId: string
) => {
  const enterprisePurchaserRole = 'bbbb1b1b-cc2c-dd3d-ee4e-ffffff5f5f5f';
  const assignmentId = self.crypto.randomUUID();

  return `SPN_OBJECT_ID=$(az rest --method get --url "https://graph.microsoft.com/v1.0/servicePrincipals(appId='${applicationId}')" --query id --output tsv)

az rest --method put \\
  --url "https://management.azure.com/providers/Microsoft.Billing/billingAccounts/${billingAccountId}/billingRoleAssignments/${assignmentId}?api-version=2019-10-01-preview" \\
  --headers "Content-Type=application/json" \\
  --body "{
    \\"properties\\": {
      \\"principalId\\": \\"$SPN_OBJECT_ID\\",
      \\"principalTenantId\\": \\"${tenantId}\\",
      \\"roleDefinitionId\\": \\"/providers/Microsoft.Billing/billingAccounts/${billingAccountId}/billingRoleDefinitions/${enterprisePurchaserRole}\\"
    }
  }"
`;
};
