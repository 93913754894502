<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useVuexStore } from './state/vuex/store';
import { LayoutClassName } from '@shared/layout';

import Analytics from '@console/components/Analytics.vue';
import FederationBoundary from '@console/components/FederationBoundary.vue';
import ConsoleNavigation from '@console/components/navigation/ConsoleNavigation.vue';
import TopBar from '@console/components/navigation/TopBar.vue';
import TopBarMobile from '@console/components/navigation/TopBarMobile.vue';
import TopBarStatic from '@console/components/navigation/TopBarStatic.vue';
import AnnouncementBanner from '@shared/components/AnnouncementBanner.vue';
import BackgroundDonuts from '@shared/design/BackgroundDonuts.vue';

const vuexStore = useVuexStore();
const hasSelectedCustomer = computed(() => !!vuexStore.getters['customer/selectedCompany']);
const hasNavContext = computed(() => !!vuexStore.state.nav.context);
const hasBootstrapped = computed(() => hasSelectedCustomer.value && hasNavContext.value);

const route = useRoute();
const layout = computed(() => route.meta.layout ?? {});
const showNav = computed(() => !layout.value.noNav && hasBootstrapped.value);
const showAnnouncementBanner = computed(() => !layout.value.noAnnouncementBanner && hasBootstrapped.value);
</script>

<template>
  <FederationBoundary>
    <Analytics>
      <div class="container-fluid p-0 layoutWrapper">
        <header class="layoutHeader">
          <template v-if="layout.staticTopBar">
            <TopBarStatic class="topBarWrapper" />
          </template>
          <template v-else>
            <TopBarMobile class="topBarWrapper d-block d-md-none" />
            <TopBar class="topBarWrapper d-none d-md-block" />
          </template>
        </header>

        <BackgroundDonuts v-if="!layout.noBackgroundDonuts" class="layoutDonuts donuts" />
        <nav v-if="showNav" class="layoutSidebar">
          <ConsoleNavigation />
        </nav>
        <div :class="{ wide: !showNav, [LayoutClassName]: true }">
          <AnnouncementBanner v-if="showAnnouncementBanner" />
          <div class="layoutContentInner" :class="{ wide: !showNav }">
            <slot />
          </div>
        </div>
      </div>
    </Analytics>
  </FederationBoundary>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';
@import '@shared/scss/colors.scss';

.layoutWrapper {
  display: grid;
  grid-template-rows: 62px calc(100vh - 62px);
  grid-template-columns: auto;
  height: 100% !important;

  @include media-breakpoint-up(md) {
    grid-template-columns: 295px auto;
  }
}

.layoutHeader {
  grid-row: 1;
  grid-column: 1/3;
}

.layoutDonuts {
  grid-row: 2;
  grid-column: 2;
}

.layoutSidebar {
  display: none;
  grid-row: 2;
  grid-column: 1;
  overflow-y: auto;
  background-color: $prosperops-purple;
  background-image: linear-gradient(to bottom, $prosperops-purple 80%, rgba(0, 0, 0, 0.15));

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.layoutContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-row: 2;
  grid-column: 2;
  overflow: auto;
}

.layoutContent.wide {
  grid-column-start: 1;
  grid-column-end: span 2;
}

.layoutContentInner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1600px;
  padding: 1rem 2rem;

  @include media-breakpoint-up(md) {
    padding: 2rem 3rem;
  }

  .layoutContent.wide & {
    max-width: 100%;
  }
}

.topBarWrapper {
  position: fixed;
  top: 0;
  z-index: 10;
  height: 62px;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}
</style>
