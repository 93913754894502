<script lang="ts" setup>
export type ReallocationMethodType = 'shared' | 'earned';

const props = defineProps<{
  value: ReallocationMethodType;
  disabled?: boolean;
}>();
const emits = defineEmits<{
  (e: 'change', newValue: ReallocationMethodType): void;
}>();

const labels: Record<ReallocationMethodType, string> = {
  shared: 'Shared Benefit',
  earned: 'Earned Benefit',
};

function change(newValue: ReallocationMethodType) {
  if (newValue === props.value) return;
  emits('change', newValue);
}
</script>

<template>
  <b-dropdown
    variant="transparent"
    menu-class="rounded-0"
    toggle-class="btn-no-focus-box-shadow rounded-0"
    right
    no-caret
    :disabled="disabled"
  >
    <template #button-content>
      <div v-if="value" class="d-flex align-items-center justify-content-center">
        <div class="text-capitalize">
          {{ labels[value] }}
        </div>
        <div class="ml-2">
          <BaseIcon name="angle-down" />
        </div>
      </div>
    </template>
    <b-dropdown-item @click.prevent="change('shared')">
      <div class="d-flex align-items-start justify-content-start">Shared Benefit</div>
    </b-dropdown-item>
    <b-dropdown-item disabled @click.prevent="change('earned')">
      <div class="d-flex align-items-start justify-content-start">
        Earned Benefit <span class="pl-1">(coming soon)</span>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>
