import awsRoutes from '@aws/router';
import { routes as azureRoutes } from '@azure/router';
import {
  requires,
  hasRoles,
  selectedCompany,
  notInDemo,
  activeSubscription,
  atLeastRole,
} from '@console/router/requirements';
import authService from '@console/services/authService';
import customerService from '@console/services/customerService';
import { routes as gcpRoutes } from '@gcp/router';
import analytics from '@shared/lib/analytics';
import { useAuthStore } from '@shared/state/auth.store';

import AwsMarketplace from '@aws/views/AwsMarketplace.vue';
import Billing from '@console/views/Billing.vue';
import Callback from '@console/views/Callback.vue';
import CompanyAdd from '@console/views/CompanyAdd.vue';
import ErrorPage from '@console/views/Error.vue';
import Federate from '@console/views/Federate.vue';
import GoogleTagManager from '@console/views/GoogleTagManager.vue';
import Help from '@console/views/Help.vue';
import IdpLogin from '@console/views/IdpLogin.vue';
import NotFound from '@console/views/NotFound.vue';
import Profile from '@console/views/Profile.vue';
import Root from '@console/views/Root.vue';
import SessionEnded from '@console/views/SessionEnded.vue';
import Start from '@console/views/Start.vue';
import Switch from '@console/views/Switch.vue';
import Unauthorized from '@console/views/Unauthorized.vue';
import UserAdd from '@console/views/UserAdd.vue';
import UserEdit from '@console/views/UserEdit.vue';
import UserManagement from '@console/views/UserManagement.vue';
import VerifyEmail from '@console/views/VerifyEmail.vue';
import VersionView from '@console/views/Version.vue';

const userIsEmployee = () => useAuthStore().isEmployee;
const userIsNotFederated = () => !useAuthStore().isFederated;
const hasNoRoles = () => !useAuthStore().hasRoles;
export default [
  {
    path: '/',
    name: 'root',
    component: Root,
  },
  {
    path: '/start',
    name: 'start',
    component: Start,
    meta: {
      initModules: true,
    },
    beforeEnter: requires(notInDemo, hasRoles, selectedCompany, atLeastRole('Viewer')),
  },
  ...awsRoutes,
  ...azureRoutes,
  ...gcpRoutes,
  {
    path: '/callback',
    name: 'callback',
    component: Callback,
    meta: {
      authenticated: false,
    },
  },
  {
    path: '/idp_login',
    name: 'IdpLogin',
    component: IdpLogin,
    meta: {
      authenticated: false,
    },
  },
  {
    path: '/company/add',
    name: 'company_add',
    component: CompanyAdd,
    beforeEnter: requires(hasNoRoles),
  },
  {
    path: '/signup',
    name: 'signup',
    beforeEnter(to) {
      const email = to.query.email;
      const firstName = to.query.firstName;
      const lastName = to.query.lastName;
      const redirect = () => authService.redirectToSignup({ email, firstName, lastName });
      if (!email) {
        return redirect();
      }
      analytics.track('Signup Started', { email });
      return customerService.addLead(email).then(redirect, redirect);
    },
    meta: {
      authenticated: false,
    },
  },
  {
    path: '/federated_login/:connectionId',
    name: 'federated_login',
    beforeEnter(to) {
      const connectionId = to.params.connectionId;
      return authService.redirectToFederatedLogin(connectionId);
    },
    meta: {
      authenticated: false,
    },
  },
  {
    path: '/user_profile',
    name: 'user_profile',
    component: Profile,
    beforeEnter: requires(hasRoles, notInDemo),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/help',
    name: 'help',
    component: Help,
    beforeEnter: requires(hasRoles, selectedCompany),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/user_management',
    name: 'user_management',
    component: UserManagement,
    props: true,
    beforeEnter: requires(notInDemo, hasRoles, selectedCompany, atLeastRole('Owner')),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/user_management/add',
    name: 'add_user',
    component: UserAdd,
    beforeEnter: requires(notInDemo, hasRoles, selectedCompany, atLeastRole('Owner'), userIsNotFederated),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/user_management/:emailAddress',
    name: 'edit_user',
    component: UserEdit,
    beforeEnter: requires(notInDemo, hasRoles, selectedCompany, atLeastRole('Owner'), userIsNotFederated),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/billing',
    name: 'billing',
    component: Billing,
    beforeEnter: requires(notInDemo, hasRoles, selectedCompany, activeSubscription, atLeastRole('Owner')),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws_marketplace',
    name: 'aws_marketplace',
    component: AwsMarketplace,
    beforeEnter: requires(notInDemo, hasRoles, selectedCompany, atLeastRole('Owner')),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/switch/:customerId/:awsOrganizationId',
    name: 'switch',
    component: Switch,
    props: true,
    beforeEnter: requires(hasRoles),
  },
  {
    path: '/federate',
    name: 'federate',
    props: route => ({
      customerId: route.query.customerId,
      awsOrganizationId: route.query.awsOrganizationId,
      cloud: route.query.cloud,
      id: route.query.id,
      demo: route.query.demo === 'true',
    }),
    component: Federate,
    beforeEnter: requires(userIsEmployee),
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
    props: true,
    meta: {
      authenticated: false,
      initHandleError: false,
    },
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
    meta: {
      initHandleError: false,
    },
  },
  {
    path: '/session_ended',
    name: 'session_ended',
    component: SessionEnded,
    beforeEnter: requires(userIsEmployee),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/verify_email',
    name: 'verify_email',
    component: VerifyEmail,
    meta: {
      authenticated: false,
    },
  },
  {
    path: '/login',
    redirect: { name: 'savings' },
  },
  {
    path: '/billing/payment_methods',
    redirect: { name: 'billing' },
  },
  {
    path: '/billing/company_address',
    redirect: { name: 'billing' },
  },
  {
    path: '/billing/history/invoice/:invoiceId',
    redirect: { name: 'billing' },
  },
  {
    path: '/billing/overview',
    redirect: { name: 'billing' },
  },
  {
    path: '/billing/history',
    redirect: { name: 'billing' },
  },
  {
    path: '/billing/payment_method',
    redirect: { name: 'billing' },
  },
  {
    path: '/billing/settings',
    redirect: { name: 'billing' },
  },
  {
    path: '/aws_organizations_and_accounts/add',
    redirect: { name: 'savings' },
  },
  {
    path: '/aws_organizations_and_accounts',
    redirect: { name: 'aws_accounts' },
  },
  {
    path: '/initial_setup',
    redirect: { name: 'root' },
  },
  {
    path: '/dashboard',
    redirect: { name: 'root' },
  },
  {
    path: '/onboarding/:wizard_step',
    redirect: { name: 'root' },
  },
  {
    path: '/savings/:timeframe?',
    redirect: { name: 'aws_compute_savings' },
  },
  {
    path: '/commitments',
    redirect: { name: 'aws_compute_commitments' },
  },
  {
    path: '/prepayments/:timeframe?',
    redirect: { name: 'aws_compute_prepayments' },
  },
  {
    path: '/settings',
    redirect: { name: 'aws_compute_settings' },
  },
  {
    path: '/onboarding',
    redirect: { name: 'aws_onboarding' },
  },
  {
    path: '/showback/:timeframe?',
    redirect: { name: 'aws_showback' },
  },
  {
    path: '/aws_accounts',
    redirect: { name: 'aws_accounts' },
  },
  {
    path: '/aws_accounts/:awsAccountId',
    redirect: { name: 'aws_configure_account' },
  },
  {
    path: '/version',
    name: 'version',
    component: VersionView,
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      initModules: true,
    },
  },
  {
    path: '/gtm',
    name: 'gtm',
    component: GoogleTagManager,
    meta: {
      authenticated: false,
    },
  },
  {
    path: '/:pathmatch(.*)*',
    redirect: { name: '404' },
  },
];
