<script setup lang="ts">
import type { ComputeSavingsDashboardResponse, SavingsBreakdownPeriod } from '@azure/services/savings.models';
import type { SolidIconName } from '@shared/design/icons/BaseIcon.vue';

import Big from 'big.js';
import _ from 'lodash';
import { computed, ref } from 'vue';

import NumberHelpers from '@shared/utilities/number_helpers';

import NormalizedVsActualToggle from '@azure/components/savings/NormalizedVsActualToggle.vue';
import Currency from '@shared/components/Currency.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';
import Tooltip from '@shared/design/Tooltip.vue';

type Breakdown = SavingsBreakdownPeriod;
type SavingsBreakdownPeriodField = keyof Breakdown;

type RowConfig = {
  key: SavingsBreakdownPeriodField;
  label: string;
  desc: string;
  show: boolean;
  class?: string;
};

const displayNames = [{ service: 'compute', displayName: 'Compute' }];

type Props = {
  savings: ComputeSavingsDashboardResponse;
  demo?: boolean;
  service: string;
};

const props = withDefaults(defineProps<Props>(), {
  demo: false,
  service: 'compute',
});

const showNormalized = ref(true);

const serviceDisplayName = computed(() => {
  const displayName = displayNames.find(t => t.service === props.service);
  if (!displayName) {
    throw new Error(`Unsupported service ${displayName}`);
  }
  return displayName.displayName;
});

const header = computed(() => `${serviceDisplayName.value} Savings Breakdown`);

const savingsBreakdownPeriods = computed(() => {
  return _.sortBy(props.savings.dashboard.savings_breakdown_periods, 'sort_order');
});

const headings = computed(() => {
  return savingsBreakdownPeriods.value.map(s => s.period_title);
});

const rows = computed(() => {
  return computeRows();
});

const computeRows = (): RowConfig[] => {
  return [
    {
      key: 'compute_usage',
      label: 'Compute Usage',
      desc: computeUsageDescription(),
      show: true,
    },
    {
      key: 'compute_spend',
      label: 'Compute Spend',
      desc: computeSpendDescription(),
      show: true,
    },
    {
      key: 'gross_savings',
      label: 'Gross Savings',
      desc: 'how much you saved on your Azure bill',
      show: true,
    },
    {
      key: 'total_commitment_savings',
      label: 'RI & SP Savings',
      desc: 'how much commitment discounts saved you on your Azure bill',
      show: true,
      class: 'indent',
    },
    {
      key: 'total_other_savings',
      label: 'Other Savings',
      desc: 'how much custom pricing saved you on your Azure bill',
      show: true,
      class: 'indent',
    },
    {
      key: 'savings_share',
      label: 'Savings Share',
      desc: 'our share of the savings generated',
      show: false,
    },
    {
      key: 'net_savings',
      label: 'Net Savings',
      desc: 'your savings net of our charge',
      show: false,
    },
  ];
};

const computeUsageDescription = () => {
  return 'what you would have paid Azure without discounts';
};

const computeSpendDescription = () => {
  return 'what you paid Azure';
};

const showDelta = (row: RowConfig, period: Breakdown) => {
  var key = `${row.key}_delta` as keyof Breakdown;
  var delta = period[key];
  return showNormalized.value && (!!delta || delta === 0);
};

const formatDelta = (delta: number) => {
  const value = NumberHelpers.formatDollars(Math.abs(delta));
  return delta < 0 ? `-${value}` : value;
};

const formatSingleDecimal = (delta: number) => {
  return NumberHelpers.formatNumber(delta, 1);
};

const deltaClasses = (delta: number) => {
  if (delta > 0) {
    return 'positive';
  }
  if (delta < 0) {
    return 'negative';
  }
  return null;
};

const getDelta = (row: RowConfig, period: Breakdown): number => {
  var key = `${row.key}_delta` as keyof Breakdown;
  var value = period[key];
  return typeof value === 'number' ? value : 0;
};

const currencyClasses = (rowKey: SavingsBreakdownPeriodField, period: SavingsBreakdownPeriod) => {
  if (tdHasTooltip(rowKey, period)) {
    return 'tooltip-target';
  }
  return null;
};

const tdTrendValueId = (rowKey: string, period: SavingsBreakdownPeriod) => {
  const periodTitle = period.period_title.toString().replace(' ', '_');
  return `${rowKey}_${periodTitle}_trend_value`;
};

const tdHasTooltip = (rowKey: SavingsBreakdownPeriodField, period: SavingsBreakdownPeriod) => {
  if (rowKey === 'total_commitment_savings') {
    const propsForTooltip = ['total_reservation_savings', 'total_savings_plan_savings'];
    return propsForTooltip.some(field => isNumber(period, field as SavingsBreakdownPeriodField));
  }
  return false;
};

const tdEmptyState = (rowKey: string, period: SavingsBreakdownPeriod) => {
  const isFinalized = _.get(period, 'is_finalized', false);
  if (isFinalized) {
    return '-';
  }
  return _.includes(['savings_share', 'net_savings'], rowKey) ? '(not finalized)' : '-';
};

const getValue = (period: SavingsBreakdownPeriod, field: SavingsBreakdownPeriodField) => {
  if (showNormalized.value) field += '_normalized';
  return period[field];
};

const isNumber = (period: SavingsBreakdownPeriod, field: SavingsBreakdownPeriodField) => {
  return _.isNumber(getValue(period, field));
};

const getDeltaPercentage = (row: RowConfig, period: Breakdown): number => {
  var key = `${row.key}_delta_percentage` as keyof Breakdown;
  var value = period[key];
  return typeof value === 'number' ? value : 0;
};

const isNonZeroNumber = (period: SavingsBreakdownPeriod, field: SavingsBreakdownPeriodField) => {
  const value = getValue(period, field);
  return _.isNumber(value) && value !== 0;
};

const deltaIcon = (delta: number): SolidIconName => {
  if (delta > 0) return 'arrow-up';
  if (delta < 0) return 'arrow-down';
  throw new Error('Invalid delta value');
};

const savingsPercentage = (
  period: SavingsBreakdownPeriod,
  numeratorField: keyof typeof period,
  denominatorField: keyof typeof period
) => {
  const numerator = getValue(period, numeratorField);
  const denominator = getValue(period, denominatorField);

  if (typeof denominator !== 'number' || denominator <= 0) {
    return null;
  }

  if (typeof numerator !== 'number') {
    return null;
  }

  const percent = NumberHelpers.formatPercent(Number(Big(numerator).div(denominator)), 1);
  return `(${percent})`;
};
</script>

<template>
  <PanelSection :header="header" class="savingsBreakdown">
    <template v-slot:utility>
      <NormalizedVsActualToggle :show-normalized="showNormalized" @change="e => (showNormalized = e)" />
    </template>
    <!-- mb-0 is needed to prevent the vertical scrollbar from appearing on the parent panel -->
    <table class="table breakdown mb-0">
      <thead>
        <tr class="tableHeader">
          <th></th>
          <th v-for="heading in headings" :key="heading" class="text-uppercase text-muted font-weight-normal">
            {{ heading }}
          </th>
        </tr>
      </thead>
      <tbody class="tableBody">
        <tr v-for="row in rows" :key="row.key" :class="row.class">
          <th>
            <div>{{ row.label }}</div>
            <small class="d-none d-xl-block description text-muted">{{ row.desc }}</small>
          </th>
          <td v-for="period in savingsBreakdownPeriods" :key="row.key + period.period_title">
            <!-- Hide -->
            <div v-if="!row.show">-</div>
            <!-- show '-' if col doesn't exist or empty-->
            <div v-else-if="!getValue(period, row.key)">
              {{ tdEmptyState(row.key, period) }}
            </div>
            <div v-else>
              <!-- Actual value for period-->
              <Currency
                :id="tdTrendValueId(row.key, period)"
                class="trendValue"
                :class="currencyClasses(row.key, period)"
                :value="getValue(period, row.key)"
              />
              <!-- Tool tips based on column-->
              <Tooltip
                v-if="row.key === 'total_commitment_savings'"
                :target="tdTrendValueId(row.key, period)"
                placement="bottom"
              >
                <div v-if="isNonZeroNumber(period, 'total_reservation_savings')" class="tooltipBaseFlex">
                  <div>Total Reservation Savings:</div>
                  <div>
                    <Currency :value="getValue(period, 'total_reservation_savings')" />
                    {{ savingsPercentage(period, 'total_reservation_savings', 'total_commitment_savings') }}
                  </div>
                </div>
                <div v-if="isNonZeroNumber(period, 'total_savings_plan_savings')" class="tooltipBaseFlex">
                  <div>Total Savings Plan Savings:</div>
                  <div>
                    <Currency :value="getValue(period, 'total_savings_plan_savings')" />
                    {{ savingsPercentage(period, 'total_savings_plan_savings', 'total_commitment_savings') }}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                v-if="row.key === 'total_other_savings'"
                :target="tdTrendValueId(row.key, period)"
                placement="bottom"
              >
                <div v-if="isNonZeroNumber(period, 'other_savings')" class="tooltipBaseFlex">
                  <div>Total Reservation Savings:</div>
                  <div>
                    <Currency :value="getValue(period, 'other_savings')" />
                    {{ savingsPercentage(period, 'other_savings', 'total_other_savings') }}
                  </div>
                </div>
              </Tooltip>
              <!-- Delta-->
              <div v-if="showDelta(row, period)">
                <div class="delta" :class="deltaClasses(getDelta(row, period))">
                  <div>
                    <BaseIcon v-if="getDelta(row, period) !== 0" :name="deltaIcon(getDelta(row, period))" />
                  </div>
                  <div>
                    <div v-if="getDelta(row, period) === 0" class="pr-1">
                      <small>
                        -
                        <br />
                        -
                      </small>
                    </div>
                    <div v-else>
                      <small>
                        {{ formatDelta(getDelta(row, period)) }}
                        <br />
                        {{ formatSingleDecimal(getDeltaPercentage(row, period)) }}%
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </PanelSection>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

table.breakdown {
  font-size: 12px;

  th,
  td {
    padding: 6px;
  }

  @include media-breakpoint-up(md) {
    font-size: inherit;

    th,
    td {
      padding: 12px;
    }
  }
}

.tableHeader th {
  text-align: right;
  background-color: $table-head-background;
  border-top: 0;
  border-bottom: 0;
}

.tableBody > tr > th {
  width: 60%;
}

.tableBody > tr > td,
.tableBody > tr > th {
  font-weight: 400;
}

.tableBody > tr:first-child > td,
.tableBody > tr:first-child > th {
  border-top: 0;
}

.tableBody td {
  text-align: right;
}

.tableBody > tr.indent th {
  padding-left: 1.8rem;
}

.tableBody > tr.indent th,
.tableBody > tr.indent td {
  font-size: 0.85rem;
  background-color: $gray-100;
  border-top: 0;
  border-bottom: 1px solid $gray-200;
}

.tableBody td > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.tableBody tr:last-child td,
.tableBody tr:last-child th {
  background-color: lighten(map-get($theme-colors, 'secondary'), 15%);
  border-top: 0;
  border-bottom: 0;
}

.tableBody tr:last-child th > div,
.tableBody tr:last-child td .trendValue {
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 1.1rem;
  }
}

.tableBody > tr > td:last-child .trendValue {
  font-weight: 500;
}

.delta {
  display: flex;
}

.delta small {
  font-weight: 500;
}

.delta.positive {
  color: map-get($theme-colors, 'success');
}

.delta.negative {
  color: map-get($theme-colors, 'danger');
}

.delta > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
}

.description {
  max-width: 600px;
}

.tooltipBaseFlex {
  font-size: 12px;

  > div {
    display: inline-block;
  }

  > div:first-child {
    padding-right: 0.25rem;
  }

  > div > span {
    font-weight: bold;
  }
}

.tooltipIncrementalSavings {
  font-weight: bold;
}
</style>
