<script>
import Big from 'big.js';
import _ from 'lodash';

import NumberHelpers from '@shared/utilities/number_helpers';

import NormalizedVsActualToggle from './NormalizedVsActualToggle.vue';
import CostBreakdownModal from '@gcp/components/savings/CostBreakdownModal.vue';
import Currency from '@shared/components/Currency.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';
import Tooltip from '@shared/design/Tooltip.vue';

const displayNames = {
  compute: 'Compute',
};

export default {
  components: {
    NormalizedVsActualToggle,
    CostBreakdownModal,
    Currency,
    PanelSection,
    Tooltip,
  },
  props: {
    billingAccountId: {
      type: String,
      required: true,
    },
    savings: {
      type: Object,
      required: true,
    },
    demo: {
      type: Boolean,
      required: false,
      default: false,
    },
    service: {
      type: String,
      required: true,
      default: 'compute',
    },
  },
  data: function () {
    return {
      showNormalized: true,
    };
  },
  computed: {
    header() {
      return `${this.serviceDisplayName} Savings Breakdown`;
    },
    savingsBreakdownPeriods() {
      return _.sortBy(this.savings.dashboard.savings_breakdown_periods, 'sort_order');
    },
    headings() {
      return _.map(this.savingsBreakdownPeriods, 'period_title');
    },
    rows() {
      return this.computeRows();
    },
    serviceDisplayName() {
      const displayName = displayNames[this.service];
      if (!displayName) {
        throw new Error(`Unsupported service ${displayName}`);
      }
      return displayName;
    },
  },
  methods: {
    computeUsageDescription() {
      return 'what you would have paid Google Cloud without discounts';
    },
    computeSpendDescription() {
      return 'what you paid Google Cloud';
    },
    showDelta(delta) {
      return this.showNormalized && (!!delta || delta === 0);
    },
    formatDelta(delta) {
      const value = NumberHelpers.formatDollars(Math.abs(delta));
      return delta < 0 ? `-${value}` : value;
    },
    formatSingleDecimal(delta) {
      return NumberHelpers.formatNumber(delta, 1);
    },
    deltaClasses(delta) {
      if (delta > 0) {
        return 'positive';
      }
      if (delta < 0) {
        return 'negative';
      }
      return null;
    },
    deltaIcon(delta) {
      if (delta > 0) return 'arrow-up';
      if (delta < 0) return 'arrow-down';
      return null;
    },
    currencyClasses(rowKey, period) {
      if (this.tdHasTooltip(rowKey, period) || this.tdHasIncrementalSavingsTooltip(rowKey, period)) {
        return 'tooltip-target';
      }
      return null;
    },
    tdTrendValueId(rowKey, period) {
      const periodTitle = _.replace(period.period_title, ' ', '_');
      return `${rowKey}_${periodTitle}_trend_value`;
    },
    tdHasTooltip(rowKey, period) {
      if (rowKey === 'total_cud_savings') {
        const propsForTooltip = ['inherited_savings', 'base_savings', 'smart_savings'];
        return propsForTooltip.some(field => this.isNumber(period, field));
      }
      if (rowKey === 'total_other_savings') {
        const propsForTooltip = ['sud_savings', 'free_tier_savings', 'on_demand_savings', 'other_savings'];
        return propsForTooltip.some(field => this.isNumber(period, field));
      }
      return false;
    },
    tdHasIncrementalSavingsTooltip(rowKey, period) {
      if (rowKey === 'net_savings') {
        return this.isNumber(period, 'incremental_savings');
      }
      return false;
    },
    tdEmptyState(rowKey, period) {
      const isFinalized = _.get(period, 'is_finalized', false);
      if (isFinalized) {
        return '-';
      }
      return _.includes(['savings_share', 'net_savings'], rowKey) ? '(not finalized)' : '-';
    },
    getValue(period, field) {
      if (this.showNormalized) field += '_normalized';
      return period[field];
    },
    isNumber(period, field) {
      return _.isNumber(this.getValue(period, field));
    },
    isNonZeroNumber(period, field) {
      const value = this.getValue(period, field);
      return _.isNumber(value) && value !== 0;
    },
    computeRows() {
      return [
        {
          key: 'compute_engine_usage',
          label: 'Compute Usage',
          desc: this.computeUsageDescription(),
          show: true,
        },
        {
          key: 'compute_engine_spend',
          label: 'Compute Spend',
          desc: this.computeSpendDescription(),
          show: true,
        },
        {
          key: 'gross_savings',
          label: 'Gross Savings',
          desc: 'how much you saved on your Google Cloud bill',
          show: true,
        },
        {
          key: 'total_cud_savings',
          label: 'CUD Savings',
          desc: 'how much CUDs saved you',
          show: true,
          class: 'indent',
        },
        {
          key: 'total_other_savings',
          label: 'Other Savings',
          desc: 'additional non-CUD savings',
          show: true,
          class: 'indent',
        },
        {
          key: 'savings_share',
          label: 'Savings Share',
          desc: 'our share of the CUD savings generated',
          show: !this.demo,
        },
        {
          key: 'net_savings',
          label: 'Net Savings',
          desc: 'your savings net of our charge',
          show: !this.demo,
        },
      ];
    },
    savingsPercentage(period, numeratorField, denominatorField) {
      const numerator = this.getValue(period, numeratorField);
      const denominator = this.getValue(period, denominatorField);

      if (denominator <= 0) {
        return null;
      }

      const percent = NumberHelpers.formatPercent(Number(Big(numerator).div(denominator)), 1);
      return `(${percent})`;
    },
  },
};
</script>

<template>
  <PanelSection :header="header" class="savingsBreakdown">
    <CostBreakdownModal
      id="gcp-cost-breakdown"
      :billing-account-id="billingAccountId"
      :month="savings.dashboard.data_through_date"
      :summary="savings.dashboard.savings_summary"
      :is-finalized="savings.dashboard.is_finalized"
      :has-contract-pricing="savings.dashboard.has_contract_pricing"
      :is-demo="demo"
    />
    <template v-slot:utility>
      <NormalizedVsActualToggle :show-normalized="showNormalized" @change="e => (showNormalized = e)" />
    </template>
    <!-- mb-0 is needed to prevent the vertical scrollbar from appearing on the parent panel -->
    <table class="table breakdown mb-0">
      <thead>
        <tr class="tableHeader">
          <th></th>
          <th v-for="heading in headings" :key="heading" class="text-uppercase text-muted font-weight-normal">
            {{ heading }}
          </th>
        </tr>
      </thead>
      <tbody class="tableBody">
        <tr v-for="row in rows" :key="row.key" :class="row.class">
          <th>
            <div>{{ row.label }}</div>
            <small class="d-none d-xl-block description text-muted">{{ row.desc }}</small>
            <div v-if="row.key == 'compute_engine_spend'">
              <a v-b-modal.gcp-cost-breakdown href="#" @click.prevent
                ><small>How does Google Cloud Console break this down?</small></a
              >
            </div>
          </th>
          <td v-for="period in savingsBreakdownPeriods" :key="row.key + period.period_title">
            <div v-if="!row.show">-</div>
            <div v-else-if="!getValue(period, row.key)">
              {{ tdEmptyState(row.key, period) }}
            </div>
            <div v-else>
              <Currency
                :id="tdTrendValueId(row.key, period)"
                class="trendValue"
                :class="currencyClasses(row.key, period)"
                :value="getValue(period, row.key)"
              />
              <Tooltip
                v-if="row.key === 'total_cud_savings'"
                :target="tdTrendValueId(row.key, period)"
                placement="bottom"
              >
                <div v-if="isNonZeroNumber(period, 'base_savings')" class="tooltipBaseFlex">
                  <div>Base Savings:</div>
                  <div>
                    <Currency :value="getValue(period, 'base_savings')" />
                    {{ savingsPercentage(period, 'base_savings', 'gross_savings') }}
                  </div>
                </div>
                <div v-if="isNonZeroNumber(period, 'smart_savings')" class="tooltipBaseFlex">
                  <div>Smart Savings:</div>
                  <div>
                    <Currency :value="getValue(period, 'smart_savings')" />
                    {{ savingsPercentage(period, 'smart_savings', 'gross_savings') }}
                  </div>
                </div>
                <div v-if="isNonZeroNumber(period, 'inherited_savings')" class="tooltipBaseFlex">
                  <div>Inherited Savings:</div>
                  <div>
                    <Currency :value="getValue(period, 'inherited_savings')" />
                    {{ savingsPercentage(period, 'inherited_savings', 'gross_savings') }}
                  </div>
                </div>
                <div v-if="isNonZeroNumber(period, 'unbilled_savings')" class="tooltipBaseFlex">
                  <div>Unbilled CUD Savings:</div>
                  <div>
                    <Currency :value="getValue(period, 'unbilled_savings')" />
                    {{ savingsPercentage(period, 'unbilled_savings', 'gross_savings') }}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                v-if="row.key === 'total_other_savings'"
                :target="tdTrendValueId(row.key, period)"
                placement="bottom"
              >
                <div v-if="isNonZeroNumber(period, 'sud_savings')" class="tooltipBaseFlex">
                  <div>SUD Savings:</div>
                  <div>
                    <Currency :value="getValue(period, 'sud_savings')" />
                    {{ savingsPercentage(period, 'sud_savings', 'gross_savings') }}
                  </div>
                </div>
                <div v-if="isNonZeroNumber(period, 'on_demand_savings')" class="tooltipBaseFlex">
                  <div>On-Demand Contract Savings:</div>
                  <div>
                    <Currency :value="getValue(period, 'on_demand_savings')" />
                    {{ savingsPercentage(period, 'on_demand_savings', 'gross_savings') }}
                  </div>
                </div>
                <div v-if="isNonZeroNumber(period, 'free_tier_savings')" class="tooltipBaseFlex">
                  <div>Free Tier Savings:</div>
                  <div>
                    <Currency :value="getValue(period, 'free_tier_savings')" />
                    {{ savingsPercentage(period, 'free_tier_savings', 'gross_savings') }}
                  </div>
                </div>
                <div v-if="isNonZeroNumber(period, 'other_savings')" class="tooltipBaseFlex">
                  <div>Other Savings:</div>
                  <div>
                    <Currency :value="getValue(period, 'other_savings')" />
                    {{ savingsPercentage(period, 'other_savings', 'gross_savings') }}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                v-if="tdHasIncrementalSavingsTooltip(row.key, period)"
                :target="tdTrendValueId(row.key, period)"
                placement="bottom"
              >
                <div>
                  Of this amount,
                  <Currency :value="getValue(period, 'incremental_savings')" class="tooltipIncrementalSavings" />
                  is the incremental savings generated by ProsperOps, above and beyond what you would have otherwise
                  saved (assuming you continued achieving your baseline Effective Savings Rate).
                </div>
              </Tooltip>
              <div v-if="showDelta(period[row.key + '_delta'])">
                <div class="delta" :class="deltaClasses(period[row.key + '_delta'])">
                  <div>
                    <BaseIcon v-if="period[row.key + '_delta'] !== 0" :name="deltaIcon(period[row.key + '_delta'])" />
                  </div>
                  <div>
                    <div v-if="period[row.key + '_delta'] === 0" class="pr-1">
                      <small>
                        -
                        <br />
                        -
                      </small>
                    </div>
                    <div v-else>
                      <small>
                        {{ formatDelta(period[row.key + '_delta']) }}
                        <br />
                        {{ formatSingleDecimal(period[row.key + '_delta_percentage']) }}%
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </PanelSection>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

table.breakdown {
  font-size: 12px;
  th,
  td {
    padding: 6px;
  }

  @include media-breakpoint-up(md) {
    font-size: inherit;
    th,
    td {
      padding: 12px;
    }
  }
}

.tableHeader th {
  text-align: right;
  background-color: $table-head-background;
  border-top: 0;
  border-bottom: 0;
}

.tableBody > tr > th {
  width: 60%;
}

.tableBody > tr > td,
.tableBody > tr > th {
  font-weight: 400;
}

.tableBody > tr:first-child > td,
.tableBody > tr:first-child > th {
  border-top: 0;
}

.tableBody td {
  text-align: right;
}

.tableBody > tr.indent th {
  padding-left: 1.8rem;
}

.tableBody > tr.indent th,
.tableBody > tr.indent td {
  font-size: 0.85rem;
  background-color: $gray-100;
  border-top: 0;
  border-bottom: 1px solid $gray-200;
}

.tableBody td > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.tableBody tr:last-child td,
.tableBody tr:last-child th {
  background-color: lighten(map-get($theme-colors, 'secondary'), 15%);
  border-top: 0;
  border-bottom: 0;
}

.tableBody tr:last-child th > div,
.tableBody tr:last-child td .trendValue {
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 1.1rem;
  }
}

.tableBody > tr > td:last-child .trendValue {
  font-weight: 500;
}

.delta {
  display: flex;
}

.delta small {
  font-weight: 500;
}

.delta.positive {
  color: map-get($theme-colors, 'success');
}

.delta.negative {
  color: map-get($theme-colors, 'danger');
}

.delta > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
}

.description {
  max-width: 600px;
}

.tooltipBaseFlex {
  font-size: 12px;

  > div {
    display: inline-block;
  }

  > div:first-child {
    padding-right: 0.25rem;
  }

  > div > span {
    font-weight: bold;
  }
}

.tooltipIncrementalSavings {
  font-weight: bold;
}
</style>
