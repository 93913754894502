<script setup lang="ts">
import type { ColDef } from '@ag-grid-community/core';
import type {
  EventLogItem,
  SchedulerLogEventData,
  SchedulerLogPowerActionData,
  SchedulerData,
} from '@console/services/aws/arm/api.models';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridVue } from '@ag-grid-community/vue3';
import { useHead } from '@unhead/vue';
import { computed, h, onMounted, ref, render } from 'vue';

import { getEventLogs } from '@aws/services/awsArmService';
import { useVuexStore } from '@console/state/vuex/store';

import EventLogStatusToolTip from '@aws/components/arm/event-logs/EventLogStatusToolTip.vue';
import PageLayout from '@console/PageLayout.vue';
import BaseIcon from '@shared/design/icons/BaseIcon.vue';

useHead({
  title: 'Event Log',
});

function calculateStatus(params: { value: SchedulerLogEventData | SchedulerLogPowerActionData }): string {
  const message = params.value;

  if (message.event_type === 'ScheduleUpdated') {
    const error = (message as SchedulerLogEventData).error_severity;
    if (error === 'Error') {
      return 'Failed';
    } else {
      if (error === 'Warning' && message.activity_type !== 'ScheduleRemoved') {
        const renderFn = () =>
          h('div', null, [
            'Succeeded',
            h('span', { style: 'margin-left: 5px;' }, [
              h(BaseIcon, { name: 'exclamation-triangle', class: 'text-warning' }),
            ]),
          ]);
        // Create a DOM element to mount the result
        const container = document.createElement('div');

        // Render the VNode into the container
        render(renderFn(), container);

        // Extract the rendered string
        return container.innerHTML;
      }
      return 'Succeeded';
    }
  }

  if (message.event_type === 'PowerAction') {
    return (message as SchedulerLogPowerActionData).action_outcome;
  }

  return 'Unknown';
}

function calculateEventType(params: { value: SchedulerLogEventData | SchedulerLogPowerActionData }): string {
  switch (params.value.activity_type) {
    case 'ScheduleAdded':
      return 'Schedule Added';
    case 'ScheduleRemoved':
      return 'Schedule Removed';
    case 'ScheduleUpdated':
      return 'Schedule Updated';
    case 'PowerActionOn':
      return 'Power On';
    case 'PowerActionOff':
      return 'Power Off';
    case 'Other':
      return 'Other';
  }
}

const getTooltipData = (params: { value?: SchedulerData }) => {
  const message = params.value;
  if (!message) return null;

  const eventType = message.event_type;
  if (eventType === 'ScheduleUpdated') {
    const error = message.error_severity;
    if (error === 'Error' || error === 'Warning') {
      if (message.activity_type === 'ScheduleRemoved') {
        // Do not return tool tip when a schedule was removed.
        return null;
      } else return message;
    }
  }

  if (eventType === 'PowerAction') {
    return message.action_outcome === 'Failed' ? message : null;
  }
  return null;
};

const store = useVuexStore();

const rowData = ref<EventLogItem[]>([]);
const colDefs = ref<Array<ColDef<EventLogItem>>>([
  { headerName: 'Time', valueGetter: i => i.data?.created_date, filter: true, sort: 'asc', sortIndex: 0 },
  { headerName: 'Instance ID', valueGetter: i => i.data?.instance_id, filter: true, sort: 'asc', sortIndex: 0 },
  { headerName: 'Name', valueGetter: i => i.data?.display_name, filter: true },
  { headerName: 'Account', valueGetter: i => `${i.data?.aws_account_number} (${i.data?.friendly_name})`, filter: true },
  { headerName: 'Region', valueGetter: i => i.data?.region, filter: true },
  { headerName: 'Event Type', valueGetter: i => i.data?.message || '{}', cellRenderer: calculateEventType },
  {
    headerName: 'Status',
    valueGetter: i => i.data?.message,
    cellRenderer: calculateStatus,
    tooltipComponent: EventLogStatusToolTip,
    filter: true,
    tooltipValueGetter: params => getTooltipData(params),
    cellClass: params => (getTooltipData(params) ? 'tooltipTarget' : ''),
  },
]);

const isLoading = ref(true);
const awsOrgId = computed(() => store.getters['aws/selectedOrganizationId']);

onMounted(async () => {
  isLoading.value = true;
  rowData.value = await getEventLogs(awsOrgId.value);
  isLoading.value = false;
});
</script>

<template>
  <PageLayout :loading="isLoading">
    <template #default>
      <AgGridVue
        :row-data="rowData"
        :column-defs="colDefs"
        suppress-movable-columns
        :pagination="true"
        :pagination-page-size="10"
        :pagination-page-size-selector="false"
        :auto-size-strategy="{ type: 'fitGridWidth' }"
        :modules="[ClientSideRowModelModule]"
        :tooltip-show-delay="0"
        style="width: 100%; height: 90%"
        class="ag-theme-quartz"
      />
    </template>
  </PageLayout>
</template>

<style lang="scss">
.tooltipTarget {
  text-decoration: underline #ced4da 1px;
  text-decoration-style: dashed;
  text-underline-offset: 5px;
}
</style>
