<script setup lang="ts">
import type { CreateServicePrincipalResponse } from '@azure/services/servicePrincipal';

import { computed, onMounted, ref } from 'vue';

import {
  AgreementType,
  generateExportResourceGroupName,
  generateExportStorageAccountName,
} from '@azure/services/billingScope';
import * as servicePrincipal from '@azure/services/servicePrincipal';
import { validateAccess, AccessValidationStatus, PermissionSet } from '@azure/services/servicePrincipal';
import { useAzureStore } from '@azure/state';
import * as managementScript from '@azure/utilities/managementScript';
import { useVuexStore } from '@console/state/vuex/store';

import ValidationStepperItem from '@gcp/components/onboarding/ValidationStepperItem.vue';
import AccordionList from '@shared/design/AccordionList.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import CodePreview from '@shared/design/CodePreview.vue';
import Panel from '@shared/design/panels/Panel.vue';
import Tabs from '@shared/design/Tabs.vue';

type OnboardingMethod = 'portal' | 'shell';

type OnboardingMethodTab = {
  key: OnboardingMethod;
  label: string;
};

type ValidationStep = 'billing-account-access' | 'reservations-access' | 'savings-plan-access';

const props = defineProps<{
  next: () => void;
}>();

const azureState = useAzureStore();
const vuexStore = useVuexStore();
const activeAccordionKey = ref<ValidationStep>('billing-account-access');
const cliValidationError = ref<string>();
const loading = ref<boolean>(false);
const spn = ref<CreateServicePrincipalResponse>();
const enterpriseAgreementCodeSnippet = ref<string>();
const onboardingScript = ref<string>();

var selectedOnboardingMethod = ref<OnboardingMethod>('shell');
var onboardingMethodTabs: OnboardingMethodTab[] = [
  { key: 'shell', label: 'Cloud Shell' },
  { key: 'portal', label: 'Azure Portal' },
];

const billingScope = computed(() => {
  const context = vuexStore.getters['nav/context'];
  return azureState.getBillingScopeById(context.id)!;
});

onMounted(async () => {
  spn.value = await servicePrincipal.get(billingScope.value.service_principal_id);
  onboardingScript.value = managementScript.generate(billingScope.value, spn.value);
  enterpriseAgreementCodeSnippet.value = managementScript.generateBillingRoleAssignmentScript(
    billingScope.value.billing_account_id,
    spn.value.application_id,
    spn.value.tenant_id
  );
});

const validateManagementAccess = async () => {
  return await validateAccess(billingScope.value.service_principal_id, {
    agreement_type: billingScope.value.agreement_type,
    billing_account_id: billingScope.value.billing_account_id,
    billing_profile_id: billingScope.value.billing_profile_id,
    storage_account_subscription_id: billingScope.value.storage_account_subscription_id,
    storage_account_resource_group_name: generateExportResourceGroupName(),
    storage_account_name: generateExportStorageAccountName(billingScope.value.storage_account_subscription_id),
    permission_set: PermissionSet.Management,
  });
};

const validateBillingScopeAccess = async () => {
  const result = await validateManagementAccess();
  if (result.billing_scope_access.status !== AccessValidationStatus.Validated) {
    throw new Error(result.billing_scope_access.message);
  }

  activeAccordionKey.value = 'reservations-access';
};

const validateReservationsAccess = async () => {
  const result = await validateManagementAccess();
  if (result.reservations_access.status !== AccessValidationStatus.Validated) {
    throw new Error(result.reservations_access.message);
  }

  activeAccordionKey.value = 'savings-plan-access';
};

const validateSavingsPlanAccess = async () => {
  const result = await validateManagementAccess();
  if (result.savings_plan_access.status !== AccessValidationStatus.Validated) {
    throw new Error(result.savings_plan_access.message);
  }

  await azureState.activateBillingScope(billingScope.value.id);
  props.next();
};

const validateAllAccess = async () => {
  loading.value = true;

  try {
    const result = await validateManagementAccess();

    if (result.billing_scope_access.status !== AccessValidationStatus.Validated) {
      cliValidationError.value = result.billing_scope_access.message;
    } else if (result.reservations_access.status !== AccessValidationStatus.Validated) {
      cliValidationError.value = result.reservations_access.message;
    } else if (result.savings_plan_access.status !== AccessValidationStatus.Validated) {
      cliValidationError.value = result.savings_plan_access.message;
    } else {
      await azureState.activateBillingScope(billingScope.value.id);
      props.next();
    }
  } catch {
    cliValidationError.value = `We're sorry, but an unexpected error has occurred. Our team has been notified and we are working to fix the problem.`;
  }

  loading.value = false;
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col">
        <h2>Additional Azure Access</h2>
        <div class="pt-2">
          <BoxMessage type="info">
            ProsperOps needs additional access to actively manage your commitments. Follow the steps below to configure
            access using the Azure Portal. If you need additional help, see
            <a href="#" target="_blank">this article</a> for detailed step-by-step instructions.
          </BoxMessage>
        </div>
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col">
        <Tabs
          :items="onboardingMethodTabs"
          :active-key="selectedOnboardingMethod"
          @changed="(key: OnboardingMethod) => selectedOnboardingMethod = key"
        />
      </div>
    </div>
    <div v-if="selectedOnboardingMethod === 'portal'" class="row">
      <div class="col">
        <AccordionList class="bg-white">
          <ValidationStepperItem
            title="Upgrade Billing Account Access"
            :open="activeAccordionKey === 'billing-account-access'"
            :validate="validateBillingScopeAccess"
          >
            <template #default>
              <ul v-if="billingScope.agreement_type === AgreementType.MicrosoftCustomerAgreement">
                <li>
                  Open
                  <a
                    href="https://portal.azure.com/#view/Microsoft_Azure_CostManagement/Menu/~/changescope/openedBy/AzurePortal"
                    target="_blank"
                  >
                    Change scope
                  </a>
                  in the Azure portal
                </li>
                <li>Select the <b>Billing profile</b> corresponding to the ID previously provided</li>
                <li>Click <b>Access control</b> on the left blade, then click <b>Add</b></li>
                <li>Select the <b>Billing profile contributor</b> role</li>
                <li>Add the <b>ProsperOps</b> service principal, then click <b>Add</b></li>
              </ul>
              <div v-else>
                Execute the following command from Azure Shell:
                <CodePreview :code-as-text="enterpriseAgreementCodeSnippet" class="onboardingCodeSnippet mt-2" />
              </div>
            </template>
            <template #error="{ error }">
              <span v-if="error?.message">{{ error.message }}</span>
              <span v-else>
                Hmm, we weren't able to validate access. Please ensure the service principal has been granted the
                <b>Billing Profile Contributor</b> role on the specified billing profile. If the issue persists, please
                chat with us for help.
              </span>
            </template>
          </ValidationStepperItem>
          <ValidationStepperItem
            title="Grant Reservations Access"
            :open="activeAccordionKey === 'reservations-access'"
            :validate="validateReservationsAccess"
          >
            <template #default>
              <ul>
                <li>
                  Open
                  <a
                    href="https://portal.azure.com/#view/Microsoft_Azure_Resources/ManagementGroupBrowseBlade/~/MGBrowse_overview"
                    target="_blank"
                    >Management groups</a
                  >
                  in the Azure portal
                </li>
                <li>Select the root management group (default name: <b>Tenant Root Group</b>)</li>
                <li>
                  Select <b>Access control (IAM)</b> on the left blade, then click <b>Add > Add role assignment</b>
                </li>
                <li>Select the <b>Reservation Purchaser</b> role, then click <b>Next</b></li>
                <li>Add the <b>ProsperOps</b> service principal as a member</li>
                <li>Click <b>Review + assign</b></li>
              </ul>
            </template>
            <template #error="{ error }">
              <span v-if="error.message">{{ error.message }}</span>
              <span v-else>
                Hmm, we weren't able to validate access. Please ensure the service principal has been granted the
                <b>Reservation Purchaser</b> role. If the issue persists, please chat with us for help.
              </span>
            </template>
          </ValidationStepperItem>
          <ValidationStepperItem
            title="Grant Savings Plan Access"
            :open="activeAccordionKey === 'savings-plan-access'"
            :validate="validateSavingsPlanAccess"
          >
            <template #default>
              <ul>
                <li>
                  Open
                  <a
                    href="https://portal.azure.com/#view/Microsoft_Azure_Resources/ManagementGroupBrowseBlade/~/MGBrowse_overview"
                    target="_blank"
                    >Management groups</a
                  >
                  in the Azure portal
                </li>
                <li>Select the root management group (default name: <b>Tenant Root Group</b>)</li>
                <li>
                  Select <b>Access control (IAM)</b> on the left blade, then click <b>Add > Add role assignment</b>
                </li>
                <li>Select the <b>Savings plan Purchaser</b> role, then click <b>Next</b></li>
                <li>Add the <b>ProsperOps</b> service principal as a member</li>
                <li>Click <b>Review + assign</b></li>
              </ul>
            </template>
            <template #error="{ error }">
              <span v-if="error.message">{{ error.message }}</span>
              <span v-else>
                Hmm, we weren't able to validate access. Please ensure the service principal has been granted the
                <b>Savings plan Purchaser</b> role. If the issue persists, please chat with us for help.
              </span>
            </template>
          </ValidationStepperItem>
        </AccordionList>
      </div>
    </div>
    <div v-if="cliValidationError" class="row">
      <div class="col-lg-12">
        <BoxMessage type="error" class="mb-4">{{ cliValidationError }}</BoxMessage>
      </div>
    </div>
    <div v-if="selectedOnboardingMethod === 'shell'" class="row">
      <div class="col-lg-4">
        <Panel>
          <ul class="mb-0 pl-3">
            <li class="mb-1">
              Open <a href="https://portal.azure.com/#cloudshell/" target="_blank">Cloud Shell</a> in the Azure portal
            </li>
            <li class="mb-1">Click <b>Switch to Bash</b> if your shell is in PowerShell</li>
            <li class="mb-1">Copy, paste, and execute the following script in the Azure Cloud Shell</li>
            <li class="mb-1">Once the script completes, click <b>Validate</b></li>
          </ul>
        </Panel>
      </div>
      <div class="col-lg-8">
        <CodePreview :code-as-text="onboardingScript" header-text="ProsperOps Management Access" />
      </div>
    </div>
    <div v-if="selectedOnboardingMethod === 'shell'" class="row sectional">
      <div class="col-sm-8 pt-2"></div>
      <div class="col pt-2">
        <div class="d-flex flex-row-reverse">
          <form-submit-button
            variant="primary"
            type="submit"
            :loading="loading"
            class="rounded-sm text-nowrap"
            @click="validateAllAccess"
          >
            Validate
            <template v-slot:loading> Validating... </template>
          </form-submit-button>
        </div>
      </div>
    </div>
  </div>
</template>
