<script setup lang="ts">
import type { SchedulerLogEventData } from '@console/services/aws/arm/api.models';

import { computed } from 'vue';
const props = defineProps<{ eventLog: SchedulerLogEventData }>();

const toolTipForScheduleUpdated = computed(() => {
  const error = props.eventLog.error_severity;
  if (error !== 'Error' && error !== 'Warning') return [];

  const results = Object.entries(props.eventLog.details ?? {}).flatMap(([key, detail]) =>
    (detail.errors ?? []).map(() => key)
  );

  return results.length > 0 ? results : ['An unspecified error occurred'];
});
</script>

<template>
  <div class="scheduleUpdateToolTip">
    <p><strong>Invalid tag values:</strong></p>
    <ul>
      <li v-for="(item, index) in toolTipForScheduleUpdated" :key="index">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.scheduleUpdateToolTip {
  word-break: break-all;
}
</style>
