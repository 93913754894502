<script lang="ts" setup>
import type { ButtonVariant } from './BaseButton.vue';

import { computed } from 'vue';

interface Props {
  disabled?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  variant: ButtonVariant;
}

const props = defineProps<Props>();
const emits = defineEmits<{
  (e: 'click', event: MouseEvent): void;
}>();

const buttonIsDisabled = computed(() => props.disabled || props.loading);
</script>

<template>
  <BaseButton :type="type" :variant="variant" :disabled="buttonIsDisabled" @click="event => emits('click', event)">
    <slot v-if="loading" name="loading">
      <!-- If a loading slot isn't provided, render the original text + "..."-->
      <slot /><span>...</span>
    </slot>
    <slot v-else />
  </BaseButton>
</template>

<style scoped>
.disabled {
  pointer-events: all !important;
  cursor: default;
}
</style>
