// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import assert from 'assert';

import storage from '@shared/lib/local_storage';

const clouds = {
  AWS: 'aws',
  AZURE: 'azure',
  GCP: 'gcp',
};

const state = {
  context: storage.get('nav.context'),
};

const mutations = {
  SET_CONTEXT(state, payload) {
    state.context = payload;
    storage.set('nav.context', payload);
  },
};

const getters = {
  context: state => {
    return state.context;
  },
  isAwsContext: state => {
    return state.context?.cloud === clouds.AWS;
  },
  isAzureContext: state => {
    return state.context?.cloud === clouds.AZURE;
  },
  isGcpContext: state => {
    return state.context?.cloud === clouds.GCP;
  },
  isOnboarding: state => {
    return !!state.context.onboarding;
  },
};

const actions = {
  async initialize(context) {
    const contextFromStorage = storage.get('nav.context');
    if (contextFromStorage) {
      context.commit('SET_CONTEXT', storage.get('nav.context'));
      await context.dispatch('loadAwsContext');
      return;
    }

    const awsOrganizationIdFromLegacyStorage = storage.get('selectedOrganizationId');
    if (awsOrganizationIdFromLegacyStorage) {
      storage.remove('selectedOrganizationId');
    }

    context.commit('SET_CONTEXT', undefined);
    await context.dispatch('aws/initializeAwsOrganization', null, { root: true });
  },
  async loadAwsContext(context) {
    const navContext = context.getters.context;
    if (navContext && navContext.cloud === clouds.AWS) {
      await context.dispatch('aws/initializeAwsOrganization', null, { root: true });
    }
  },
  onboard({ commit }, cloud) {
    if (cloud === clouds.AWS) {
      commit('SET_CONTEXT', { cloud, id: 'NEW_ORGANIZATION_PLACEHOLDER', onboarding: true });
    } else if (cloud === clouds.GCP) {
      commit('SET_CONTEXT', { cloud, id: 'NEW_BILLING_ACCOUNT_PLACEHOLDER', onboarding: true });
    } else {
      commit('SET_CONTEXT', { cloud, id: 'NEW_BILLING_SCOPE_PLACEHOLDER', onboarding: true });
    }
  },
  switch({ commit }, { cloud, id }) {
    assert(cloud, 'Nav context must contain value for `cloud`');
    assert(id, 'Nav context must contain value for `id`');

    if (
      id === 'NEW_ORGANIZATION_PLACEHOLDER' ||
      id === 'NEW_BILLING_ACCOUNT_PLACEHOLDER' ||
      id === 'NEW_BILLING_SCOPE_PLACEHOLDER'
    ) {
      commit('SET_CONTEXT', { cloud, id, onboarding: true });
      return;
    }

    commit('SET_CONTEXT', { cloud, id, onboarding: false });
  },
  reset({ commit }) {
    commit('SET_CONTEXT', undefined);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  clouds,
};
