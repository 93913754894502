import type { ComputeSavingsDashboardResponse } from './savings.models';

import { azure } from '@shared/services/api';
import env from '@shared/utilities/env';

/* eslint-disable camelcase */
export enum AgreementType {
  EnterpriseAgreement = 'EnterpriseAgreement',
  MicrosoftCustomerAgreement = 'MicrosoftCustomerAgreement',
}

export enum BillingScopeStatus {
  Provisioning = 'Provisioning',
  Active = 'Active',
}

export type CreateBillingScopeRequest = {
  agreement_type?: AgreementType;
  billing_account_id?: string;
  billing_profile_id?: string;
  service_principal_id?: string;
  storage_account_subscription_id?: string;
  storage_account_resource_group_name?: string;
  storage_account_name?: string;
};

export type BillingScopeResponse = {
  id: string;
  agreement_type: AgreementType;
  billing_account_id: string;
  billing_profile_id?: string | undefined;
  service_principal_id: string;
  storage_account_subscription_id: string;
  storage_account_resource_group_name: string;
  storage_account_name: string;
  status: BillingScopeStatus;
  display_name: string;
};

export type BillingScopeGlobalSettings = {
  savings_analysis_completed: boolean;
  managing_commitment: boolean;
};
/* eslint-enable camelcase */

export type BillingScopeExistsResponse = {
  exists: boolean;
};

export async function getAll() {
  const res = await azure.get<BillingScopeResponse[]>(`/v1/billing_scopes`);
  return res.data;
}

export async function getGlobalSettings(billingScopeId: string) {
  const res = await azure.get<BillingScopeGlobalSettings>(`/v1/billing_scopes/${billingScopeId}/settings/global`);
  return res.data;
}

export async function create(request: CreateBillingScopeRequest) {
  const res = await azure.post<BillingScopeResponse>(`/v1/billing_scopes`, request);
  return res.data;
}

export async function activate(billingAccountId: string) {
  const res = await azure.post<BillingScopeResponse>(`/v1/billing_scopes/${billingAccountId}/activate`);
  return res.data;
}

export async function exists(billingAccountId?: string, billingProfileId?: string) {
  const res = await azure.get<BillingScopeExistsResponse>(`/v1/billing_scopes/exists`, {
    params: {
      billingAccountId,
      billingProfileId,
    },
  });
  return res.data;
}

export async function savingsDashboard(billingScopeId: string, timeframe?: string, generate: boolean = false) {
  const res = await azure.get<ComputeSavingsDashboardResponse>(
    `/v1/billing_scopes/${billingScopeId}/savings_dashboard`,
    {
      params: Object.assign({}, generate ? { generate } : null, timeframe ? { timeframe } : null),
    }
  );
  return res.data;
}

export function generateExportResourceGroupName(): string {
  if (env.isProduction()) {
    return 'prosperops';
  }

  return `prosperops-dev`;
}

export function generateExportStorageAccountName(subscriptionId?: string): string {
  return `prosperops${subscriptionId?.substring(0, 8)}`;
}
