<script setup lang="ts">
import type { ColDef } from '@ag-grid-community/core';
import type { Ec2InventoryItem, Ec2InventoryMetrics } from '@console/services/aws/arm/api.models';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridVue } from '@ag-grid-community/vue3';
import { useHead } from '@unhead/vue';
import { computed, onMounted, ref } from 'vue';

import { getEc2Inventory, getEc2InventoryMetrics } from '@aws/services/awsArmService';
import { useVuexStore } from '@console/state/vuex/store';

import ResourceMetrics from '@aws/components/arm/resources/ResourceMetrics.vue';
import PageLayout from '@console/PageLayout.vue';

useHead({
  title: 'Resources',
});

const store = useVuexStore();

const rowData = ref<Ec2InventoryItem[]>([]);
const metricData = ref<Ec2InventoryMetrics>({
  total_resources: 0,
  scheduled_resources: 0,
  running_resources: 0,
  stopped_resources: 0,
});

const colDefs = ref<Array<ColDef<Ec2InventoryItem>>>([
  {
    headerName: 'Instance ID',
    valueGetter: i => i.data?.instance_id,
    filter: true,
  },
  {
    headerName: 'Name',
    valueGetter: i => i.data?.display_name,
    sort: 'asc',
    sortIndex: 0,
    filter: true,
  },
  {
    headerName: 'Account',
    valueGetter: i => `${i.data?.aws_account_number} (${i.data?.friendly_name})`,
    filter: true,
  },
  {
    headerName: 'State',
    valueGetter: i => {
      const state = i.data?.last_state_name;
      return state ? state.charAt(0).toUpperCase() + state?.slice(1) : '';
    },
    filter: true,
  },
  { headerName: 'Power On Schedule', valueGetter: i => i.data?.power_on_schedule, filter: true },
  {
    headerName: 'Power Off Schedule',
    valueGetter: i => i.data?.power_off_schedule,
    filter: true,
  },
  { headerName: 'Timezone', valueGetter: i => i.data?.timezone, filter: true },
  {
    headerName: 'Notification Receiver',
    valueGetter: i => i.data?.notification_email,
    filter: true,
  },
]);

const isLoading = ref(true);
const awsOrgId = computed(() => store.getters['aws/selectedOrganizationId']);

onMounted(async () => {
  isLoading.value = true;
  rowData.value = await getEc2Inventory(awsOrgId.value);
  metricData.value = await getEc2InventoryMetrics(awsOrgId.value);
  isLoading.value = false;
});
</script>

<template>
  <PageLayout :loading="isLoading">
    <template #default>
      <ResourceMetrics :metric-data="metricData" class="metricsRow" />
      <AgGridVue
        :row-data="rowData"
        :column-defs="colDefs"
        suppress-movable-columns
        :pagination="true"
        :pagination-page-size="10"
        :pagination-page-size-selector="false"
        :auto-size-strategy="{ type: 'fitGridWidth' }"
        :modules="[ClientSideRowModelModule]"
        style="width: 100%; height: 85%"
        class="ag-theme-quartz"
      />
    </template>
  </PageLayout>
</template>

<style lang="scss" scoped>
.metricsRow {
  padding-bottom: 15px;
}
</style>
