<script lang="ts" setup>
import { useSlots } from 'vue';

import LoadingState from '@shared/components/LoadingState.vue';

defineProps<{
  loading?: boolean;
}>();

const slots = useSlots();
</script>

<template>
  <div class="d-flex flex-column flex-grow-1">
    <LoadingState v-if="loading" />
    <template v-else>
      <main class="layoutMain">
        <slot />
      </main>
      <footer v-if="slots.footer" class="layoutFooter">
        <slot name="footer" />
      </footer>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.layoutMain {
  flex-grow: 1;
}

.layoutFooter {
  margin-top: 2rem;
}
</style>
