<script setup lang="ts">
type Props = {
  loading: boolean;
  disabled?: boolean;
};

withDefaults(defineProps<Props>(), {
  disabled: false,
});

const emit = defineEmits<{
  (e: 'click'): void;
}>();
</script>

<template>
  <BaseButton
    type="button"
    variant="primary"
    class="rounded-sm btn"
    :disabled="loading || disabled"
    @click.prevent="emit('click')"
  >
    <div class="iconContainer">
      <BaseIcon v-if="loading" name="spinner" class="mr-1" :spin="true" />
    </div>
    <div class="buttonText" :class="{ loading }">
      <slot />
    </div>
  </BaseButton>
</template>

<style lang="scss" scoped>
.btn {
  position: relative;

  .buttonText.loading {
    // Hide the text but keep the space while we show the loading spinner
    opacity: 0;
  }
}

.iconContainer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
