import type { AwsAdmNonComputeOfferingCode } from '@console/services/api.models';
import type { RouteRecordRaw } from 'vue-router';

import {
  hasSingleOrgSelected,
  isNonComputeEnabled,
  notNewOrganization,
} from '../requirements';
import {
  requires,
  hasRoles,
  selectedCompany,
  atLeastRole,
  activeSubscription,
} from '@console/router/requirements';
import { PermissionLevel } from '@shared/state/auth.store';

import NonComputeCommitments from '@aws/views/adm/non-compute/Commitments.vue';
import RequestSavingsAnalysis from '@aws/views/adm/non-compute/RequestSavingsAnalysis.vue';
import NonComputeSavings from '@aws/views/adm/non-compute/Savings.vue';
import NonComputeSettings from '@aws/views/adm/non-compute/settings/Settings.vue';

const nonComputeOfferings: { offering: AwsAdmNonComputeOfferingCode; path: string }[] = [
  { offering: 'elasti_cache', path: 'elasticache' },
  { offering: 'memory_db', path: 'memorydb' },
  { offering: 'open_search', path: 'opensearch' },
  { offering: 'rds', path: 'rds' },
  { offering: 'redshift', path: 'redshift' },
];

const baseRequirements = [
  hasRoles,
  selectedCompany,
  activeSubscription,
  atLeastRole(PermissionLevel.Viewer),
  notNewOrganization,
  hasSingleOrgSelected,
];

const routes: RouteRecordRaw[] = [
  // Add in the pages that are common across all of the non-compute services
  ...nonComputeOfferings.flatMap(
    o =>
      [
        {
          path: `/aws/${o.path}/savings/:timeframe?`,
          name: `aws_${o.offering}_savings_dashboard`,
          beforeEnter: requires(...baseRequirements, isNonComputeEnabled(o.offering)),
          component: NonComputeSavings,
          props: route => ({ service: o.offering, ...route.params }),
          meta: {
            initModules: true,
          },
        },
        {
          path: `/aws/${o.path}/commitments`,
          name: `aws_${o.offering}_commitments_dashboard`,
          beforeEnter: requires(...baseRequirements, isNonComputeEnabled(o.offering)),
          component: NonComputeCommitments,
          props: route => ({ service: o.offering, ...route.params }),
          meta: {
            initModules: true,
          },
        },
        {
          path: `/aws/${o.path}/settings`,
          name: `aws_${o.offering}_settings`,
          beforeEnter: requires(...baseRequirements, isNonComputeEnabled(o.offering)),
          component: NonComputeSettings,
          props: route => ({ service: o.offering, ...route.params }),
          meta: {
            initModules: true,
          },
        },
        {
          path: `/aws/${o.path}`,
          name: `aws_${o.offering}_request_savings_analysis`,
          beforeEnter: requires(...baseRequirements),
          component: RequestSavingsAnalysis,
          props: route => ({ service: o.offering, ...route.params }),
          meta: {
            initModules: true,
          },
        },

        // Temporarily keep this redirect, in case a user has this URL or opens in a new tab
        {
          path: `/aws/${o.path}/express_interest`,
          redirect: { name: `aws_${o.offering}_request_savings_analysis` },
        },
        {
          path: `/aws/${o.path}/request_savings_analysis`,
          redirect: { name: `aws_${o.offering}_request_savings_analysis` },
        },
      ] as RouteRecordRaw[]
  ),
];

export default routes;
