import type { RouteRecordRaw } from 'vue-router';

import { notNewOrganization, hasSingleOrgSelected } from '../requirements';
import {
  activeSubscription,
  atLeastRole,
  hasRoles,
  isFeatureEnabled,
  notInDemo,
  requires,
  selectedCompany,
} from '@console/router/requirements';
import { PermissionLevel } from '@shared/state/auth.store';

import CostsAvoided from '@aws/views/arm/scheduler/CostsAvoided.vue';
import EventLogs from '@aws/views/arm/scheduler/EventLogs.vue';
import Resources from '@aws/views/arm/scheduler/Resources.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/aws/arm/scheduler',
    beforeEnter: requires(
      isFeatureEnabled('armScheduler'),
      notInDemo,
      hasRoles,
      selectedCompany,
      activeSubscription,
      atLeastRole(PermissionLevel.Viewer),
      notNewOrganization,
      hasSingleOrgSelected,
    ),
    meta: {
      initModules: true,
      useNewLayout: true,
    },
    children: [
      {
        path: 'costs_avoided',
        name: 'aws_arm_scheduler_costs_avoided',
        component: CostsAvoided,
      },
      {
        path: 'resources',
        name: 'aws_arm_scheduler_resources',
        component: Resources,
      },
      {
        path: 'event_logs',
        name: 'aws_arm_scheduler_event_logs',
        component: EventLogs,
      },
    ],
  },
];

export default routes;
