<script>
import env from '@shared/utilities/env';

var options = {
  fonts: [
    {
      src: 'url(https://console.prosperops.com/fonts/NeurialGrotesk-Regular.woff2)',
      family: 'Neurial Grotesk',
      style: 'normal',
    },
  ],
};

var style = {
  base: {
    fontSize: '16px',
    fontFamily:
      '"Neurial Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    backgroundColor: '#fff',
  },
};

export default {
  props: {
    cardholderName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      stripe: null,
      card: null,
      cardError: null,
    };
  },
  async mounted() {
    this.stripe = window.Stripe(env.get('VITE_STRIPE_PK'));
    const elements = this.stripe.elements(options);
    this.card = elements.create('card', { style });
    this.card.mount(this.$refs.card);
    this.card.addEventListener('change', event => {
      if (event.error) {
        this.$emit('onError', event.error.message);
        this.cardError = event.error.message;
      } else {
        this.$emit('onError', null);
        this.cardError = null;
      }

      this.$emit('onChange');
    });
  },
  methods: {
    async submit() {
      return this.stripe.createToken(this.card, {
        name: this.cardholderName,
      });
    },
  },
};
</script>

<template>
  <form>
    <label class="required text-muted"> Credit or Debit Card </label>
    <div id="payment" ref="card" class="form-control card"></div>
    <div v-if="cardError" id="cardErrors">{{ cardError }}</div>
  </form>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.card {
  display: flex;
  justify-content: center;
  height: 38px;
  background-color: #fff;
  border-radius: 0;
}

label.required::before {
  display: inline-block;
  font-weight: 800;
  color: map-get($theme-colors, 'danger');
  content: '*';
}

#cardErrors {
  color: map-get($theme-colors, 'danger');
}
</style>
