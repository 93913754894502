<script setup>
import BaseIcon from '@shared/design/icons/BaseIcon.vue';

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>

<template>
  <a :href="props.url" class="btn btn-primary" :class="{ disabled }" target="_blank"
    >Show in Google Cloud Console <BaseIcon name="external-link-alt" class="ml-2"
  /></a>
</template>
